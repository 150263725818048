import {
  AnchorButton,
  Button,
  ButtonLayout,
  NotificationType,
} from '@frontend/ui';
import { ImportStatus } from 'app/apollo/graphql/types';
import { employeesImportMessages } from 'app/messages/employees';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';
import { MessageDescriptor } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router';

import { MatchParams } from '../..';

type StatusMessageType = ImportStatus.COMPLETE | ImportStatus.FAILURE;

interface StatusData {
  description: MessageDescriptor;
  primaryAction: {
    title: MessageDescriptor;
    url: (companyId: string) => string;
  };
  secondaryAction: { title: MessageDescriptor };
  type: NotificationType;
}

const CONTENT: Record<StatusMessageType, StatusData> = {
  COMPLETE: {
    type: 'success',
    description: employeesImportMessages.updateSuccessDescription,
    primaryAction: {
      title: employeesImportMessages.toOverView,
      url: companyId => `/companies/${companyId}/employees/imports`,
    },
    secondaryAction: { title: employeesImportMessages.downloadFile },
  },
  FAILURE: {
    type: 'error',
    description: employeesImportMessages.updateErrorDescription,
    primaryAction: {
      title: employeesImportMessages.importFile,
      url: companyId => `/companies/${companyId}/employees/import`,
    },
    secondaryAction: { title: employeesImportMessages.downloadFile },
  },
};

export const hasStatusMessage = (
  status?: ImportStatus,
): status is StatusMessageType => !!status && !!CONTENT[status];

export const StatusMessage: React.FC<{
  fileUrl?: string | null;
  status?: ImportStatus;
}> = ({ status, fileUrl }) => {
  const { push } = useHistory();
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();

  if (hasStatusMessage(status)) {
    return (
      <NotificationCard
        title={<FormattedMessage {...CONTENT[status].description} />}
        type={CONTENT[status].type}
        actions={
          <ButtonLayout>
            <Button
              type="button"
              onClick={() => push(CONTENT[status].primaryAction.url(companyId))}
            >
              <FormattedMessage {...CONTENT[status].primaryAction.title} />
            </Button>
            {!!fileUrl && (
              <AnchorButton href={fileUrl} download text>
                <FormattedMessage {...CONTENT[status].secondaryAction.title} />
              </AnchorButton>
            )}
          </ButtonLayout>
        }
      />
    );
  }

  return null;
};

import {
  discountsOverviewQuery,
  discountsOverviewQueryVariables,
} from 'app/apollo/graphql/types';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '..';
import { DISCOUNTS_OVERVIEW_QUERY } from './graphql/queries';
import { DiscountsTable } from './Table';
import { updateQuery } from './utils/update-query';

export const Discounts: React.FC = () => {
  const [perPage, setPerPage] = useState(DEFAULT_RESULT_PER_PAGE.toString());
  const {
    params: { companyId },
  } = useRouteMatch<MatchParams>();
  const { locale } = useIntlContext();

  const {
    data: _data,
    loading,
    error,
    fetchMore,
    previousData,
  } = useQuery<discountsOverviewQuery, discountsOverviewQueryVariables>(
    DISCOUNTS_OVERVIEW_QUERY,
    {
      variables: {
        companyId,
        onlyGetDiscounts: true,
        first: Number(perPage),
        locale,
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
    },
  );

  const data = _data ?? previousData;

  if (!data?.company?.benefits?.edges.length) {
    if (loading) {
      return <TopLoading />;
    }
    return <EmptyState error={error} />;
  }

  const { edges, pageInfo } = data.company.benefits;
  const benefits = edges.map(e => e.node);

  const navigation = {
    perPage,
    setPerPage,
    pageInfo,
    onNextPage: () =>
      fetchMore({
        variables: { after: pageInfo.endCursor },
        updateQuery,
      }),
    onPreviousPage: () =>
      fetchMore({
        variables: {
          before: pageInfo.startCursor,
          first: undefined,
          last: Number(perPage),
        },
        updateQuery,
      }),
  };

  return (
    <>
      {loading && <TopLoading />}
      {error && <GraphQlError error={error} />}
      <DiscountsTable benefits={benefits} navigation={navigation} />
    </>
  );
};

import gql from 'graphql-tag';

export const CREATE_BENEFIT_CATEGORY_MUTATION = gql`
  mutation createBenefitCategoryMutation(
    $input: FlexCreateBenefitCategoryInput!
  ) {
    createBenefitCategory(input: $input) {
      categoryId
    }
  }
`;

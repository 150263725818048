import { Form, Formik } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { formOrgUnitsOptions } from '@frontend/utils';
import {
  organizationalUnitsQuery,
  organizationalUnitsQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import {
  FormValues as OrganizationalUnitFormValues,
  OrganizationalUnitFormFields,
  validationSchema,
} from '../components/OrganizationalUnitFormFields';
import { ORGANIZATIONAL_UNITS_QUERY } from '../graphql/queries';
import { orgUnitMessages } from '../messages';
import { useSubmit } from './utils/use-submit';

export interface FormValues extends OrganizationalUnitFormValues {
  id: string;
}

export const EditOrgUnitModal: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const { edit: id } = qs.parse(search);
  const intl = useIntl();

  const onRequestClose = () => push(pathname);

  const { submit, error: submitError } = useSubmit({
    onRequestClose,
  });

  const { data, loading, error } = useQuery<
    organizationalUnitsQuery,
    organizationalUnitsQueryVariables
  >(ORGANIZATIONAL_UNITS_QUERY, {
    errorPolicy: 'all',
    skip: !id,
    variables: { companyId },
  });

  if (loading) {
    return <TopLoading />;
  }

  const orgUnits =
    data?.company?.organizationalUnits?.edges.map(e => e.node) ?? [];
  const orgUnitSelectOptions = formOrgUnitsOptions(
    orgUnits.filter(n => !id || !n.path.includes(id)),
  );
  const orgUnit = orgUnits.find(e => e.id === id);

  if (!orgUnit) {
    return null;
  }

  return (
    <Formik<FormValues>
      initialValues={{
        name: orgUnit.name,
        unitOf: orgUnit.unitOf ? orgUnit.unitOf.id : undefined,
        slug: orgUnit.slug,
        id: orgUnit.id,
      }}
      enableReinitialize
      validationSchema={validationSchema(intl)}
      onSubmit={submit}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Modal isOpen onRequestClose={onRequestClose}>
          <Form onSubmit={handleSubmit}>
            <ModalHeader>
              <FormattedMessage {...orgUnitMessages.editOrgUnit} />
            </ModalHeader>
            <ModalBody>
              <OrganizationalUnitFormFields
                orgUnitSelectOptions={orgUnitSelectOptions}
              />
              {(error || submitError) && (
                <GraphQlError inModal error={error ?? submitError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...orgUnitMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  filled
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  <FormattedMessage {...orgUnitMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

import { Subsection } from '@frontend/ui';
import { discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_attachments_FlexFile as Attachment } from 'app/apollo/graphql/types';
import { Attachments } from 'components/Attachments';
import { RichTextEditor } from 'components/formik/RichTextEditor';
import { EmbeddedComponentType } from 'features/rich-text-editor/embedded-components/types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Components } from 'react-markdown/lib/ast-to-react';

import { CampaignCodeCard } from '../../embedded/CampaignCodeCard';
import { MovieTicketForm } from '../../embedded/MovieTicketForm';
import { NannyNuForm } from '../../embedded/NannyNuForm';
import { Attachment as FormAttachment } from '../../form';
import { embeddedMarkdownPlugin } from '../../utils/embedded-plugin';
import { BenefitPresentationContext, FullBleed } from '../Presentation';

interface Props {
  body: string;
  context: BenefitPresentationContext;
  attachments?: ReadonlyArray<Attachment | FormAttachment>;
  // eslint-disable-next-line
  config?: any;
}

export const PresentationBody: React.FC<Props> = props => {
  const { context, body, config, attachments } = props;

  if (context !== 'edit') {
    return (
      <ReactMarkdown
        plugins={embeddedMarkdownPlugin}
        components={
          {
            // eslint-disable-next-line
            embedded: ({ children: [componentType] }) => {
              /* XXX: componentType is a string, representing embedded component type.
                    In order to support additional component types (on format ##embedded[EmbeddedComponentType]),
                    add rendering conditions here!
                  */
              switch (componentType) {
                case EmbeddedComponentType.ATTACHMENTS:
                  return (
                    <Subsection>
                      <Attachments attachments={[...(attachments ?? [])]} />
                    </Subsection>
                  );
                case EmbeddedComponentType.CAMPAIGN_CODE:
                  return (
                    <CampaignCodeCard
                      campaignCode={config?.data?.campaignCode ?? ''}
                    />
                  );
                case EmbeddedComponentType.MOVIE_TICKETS:
                  return <MovieTicketForm />;
                case EmbeddedComponentType.NANNY_NU:
                  return <NannyNuForm context={context} />;
                default:
                  return null;
              }
            },
          } as Components & {
            embedded: (args: { children }) => React.ReactNode | null;
          }
        }
      >
        {body}
      </ReactMarkdown>
    );
  }

  const embeddedComponentOptions = [
    ...(config?.data?.campaignCode
      ? [EmbeddedComponentType.CAMPAIGN_CODE]
      : []),
    EmbeddedComponentType.ATTACHMENTS,
  ];

  return (
    <FullBleed>
      <RichTextEditor
        wysiwyg
        name="content.body"
        embeddedComponentOptions={embeddedComponentOptions}
      />
    </FullBleed>
  );
};

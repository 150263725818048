import { Fab as _Fab, Icon, media } from '@frontend/ui';
import { _delete } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { useIntl } from 'components/formats';
import {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import { EditAttachments } from 'features/companies/company/discounts/discount/presentation/components/EditAttachments';
import { CampaignCodeCard } from 'features/companies/company/discounts/discount/presentation/embedded/CampaignCodeCard';
import { campaignCodeMessages } from 'features/companies/company/discounts/discount/presentation/embedded/CampaignCodeCard/messages';
import React from 'react';
import styled from 'styled-components';

import { EmbeddedComponentType } from './types';

const Fab = styled(_Fab)`
  position: absolute;
  top: -1.25rem;
  right: -1.25rem;

  ${media.lessThan('tablet')`
    right: -0.5rem;
  `}
`;

interface DeleteFabProps {
  // eslint-disable-next-line
  blockProps: { [key: string]: any };
  contentState?: ContentState;
  onDelete?: () => void;
}

const DeleteFab: React.FC<DeleteFabProps> = ({
  blockProps,
  contentState,
  onDelete,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Fab
      mini
      icon={<Icon color="onError" icon={_delete} size="medium" decorative />}
      color="error"
      label={formatMessage(commonMessages.remove)}
      onClick={() => {
        onDelete?.();
        blockProps.remove(contentState);
      }}
    />
  );
};

interface ComponentProps {
  block?: ContentBlock;
  contentState?: ContentState;
  // eslint-disable-next-line
  blockProps?: { [key: string]: any };
}

const EmbeddedComponent: React.FC<ComponentProps> = ({
  block,
  contentState,
  blockProps,
}) => {
  const { formatMessage } = useIntl();

  if (!block || !contentState || !blockProps) {
    return null;
  }

  const data = contentState.getEntity(block.getEntityAt(0)).getData();

  if (data?.componentType === EmbeddedComponentType.CAMPAIGN_CODE) {
    return (
      <div style={{ position: 'relative' }}>
        <CampaignCodeCard
          campaignCode={formatMessage(campaignCodeMessages.campaignCode)}
        />
        <DeleteFab contentState={contentState} blockProps={blockProps} />
      </div>
    );
  }
  if (data?.componentType === EmbeddedComponentType.ATTACHMENTS) {
    return (
      <div style={{ position: 'relative' }}>
        <EditAttachments
          // eslint-disable-next-line
          deleteAction={action => (
            <DeleteFab
              contentState={contentState}
              blockProps={blockProps}
              onDelete={action}
            />
          )}
        />
      </div>
    );
  }
  return null;
};

export const embeddedComponentsRenderer = (
  block: ContentBlock,
  _editorState: EditorState,
  handleChange: (state: EditorState) => void,
) => {
  if (block.getType() !== 'atomic') {
    return null;
  }
  return {
    component: EmbeddedComponent,
    editable: false,
    props: {
      remove: (contentState: ContentState) => {
        const blockKey = block.getKey();
        const afterKey = contentState.getKeyAfter(blockKey);
        const afterBlockType = contentState.getBlockForKey(afterKey).getType();
        const targetRange = new SelectionState({
          anchorKey: blockKey,
          anchorOffset: 0,
          focusKey: afterKey,
          focusOffset: 0,
        });
        const _contentState = Modifier.removeRange(
          Modifier.setBlockType(contentState, targetRange, afterBlockType),
          targetRange,
          'backward',
        );
        handleChange(
          EditorState.push(_editorState, _contentState, 'remove-range'),
        );
      },
    },
  };
};

/*  Entity type name for embedded components in the editor.
    Entities represent meta data, which allows for rich content beyond styled text.
    Read more about entities here:
    https://draftjs.org/docs/advanced-topics-entities/
*/
export const EmbeddedComponentEntity = 'EMBEDDED_COMPONENT_ENTITY';

import { PremiumMatrix } from '@advinans/premium-matrix';
import { BaseAmountType } from '@advinans/premium-matrix/build/premium-matrix';
import { ProposalBaseAmountType } from 'app/apollo/graphql/types';

import { formatPremiumMatrixFormValues } from '../../../utils';
import { ProposalPremiumMatrixFormValues } from '../../../utils/types';

export const itpPremiumMatrixInitialValues: ProposalPremiumMatrixFormValues =
  formatPremiumMatrixFormValues({
    ...PremiumMatrix.ITP_LIKE,
    baseAmountType:
      PremiumMatrix.ITP_LIKE.baseAmountType === BaseAmountType.IBB
        ? ProposalBaseAmountType.IBB
        : ProposalBaseAmountType.PBB,
  });

import {
  bodyMediumCSS,
  Chip,
  ContentContainer,
  Icon,
  IconButton,
} from '@frontend/ui';
import { addCircle, cancel, check } from '@frontend/ui/icons';
import { comparableString, onPressEnter } from '@frontend/utils';
import {
  benefitCategoriesQuery,
  benefitCategoriesQueryVariables,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/companies/company/discounts/edit';
import { useQuery } from 'app/utils/use-query';
import { ChipSet } from 'components/FeaturePageLayout';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import {
  BACKWARDS_NAV_ID,
  FORWARDS_NAV_ID,
  ScrollableSet,
} from 'components/scrollable/Set';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { discountMessages } from 'features/companies/company/discounts/messages';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { Category } from '../../form';
import { BenefitPresentationContext } from '../Presentation';
import { CategoryModal } from './CategoryModal';
import { BENEFITS_CATEGORY_QUERY } from './graphql/queries';

interface InputProps {
  searchLength: number;
}

const SelectableChipWrapper = styled.div<{ display: boolean }>`
  display: block;
  opacity: ${p => (p.display ? 1 : 0)};
  transform: ${p => (p.display ? 'scaleY(1)' : 'scaleY(0)')};
  transition:
    transform 0.1s,
    margin 0.1s,
    opacity 0.5s 0.0125s;
  transform-origin: left;
  margin-bottom: ${p => (p.display ? '0.75rem' : 0)};
`;

const Input = styled.input<InputProps>`
  ${bodyMediumCSS}
  border: none;
  outline: none;
  height: 2rem;
  min-width: 5rem;
  margin-bottom: 0.75rem;
  margin-right: 0.5rem;
  color: ${p => p.theme.primaryGrayDark};
  ${p => `width: ${p.searchLength}ch`}
`;

const isSameCategoryName = (name1: string, name2: string) =>
  comparableString(name1) === comparableString(name2);

interface CommonProps {
  selectedCategories: readonly Category[];
}

interface PreviewProps extends CommonProps {
  context: Exclude<BenefitPresentationContext, 'edit'>;
  setCategories?: never;
}

interface EditProps extends CommonProps {
  context: 'edit';
  setCategories: (categories: Category[]) => void;
}

type Props = PreviewProps | EditProps;

export const Categories: React.FC<Props> = ({
  selectedCategories,
  setCategories: _setCategories,
  context,
}) => {
  const match = useRouteMatch<MatchParams>();

  const { companyId } = match.params;
  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  const searchRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const setCategories = useMemo(
    () => (categories: Category[]) => {
      if (_setCategories) {
        _setCategories(categories);
      }
    },
    [_setCategories],
  );

  const { data, loading, error } = useQuery<
    benefitCategoriesQuery,
    benefitCategoriesQueryVariables
  >(BENEFITS_CATEGORY_QUERY, {
    errorPolicy: 'all',
    variables: { companyId, locale },
    skip: !companyId || context !== 'edit',
  });

  const [search, setSearch] = useState<string | undefined>('');
  const [focus, setFocus] = useState<boolean>(false);

  useEffect(() => {
    const container = containerRef.current;

    const handleFocusLost = (e: FocusEvent) => {
      const targetId = (e.target as HTMLElement)?.id ?? '';

      if (
        !container?.contains(e.relatedTarget as HTMLElement) &&
        targetId !== BACKWARDS_NAV_ID &&
        targetId !== FORWARDS_NAV_ID
      ) {
        setFocus(false);
      }
    };

    container?.addEventListener('focusout', handleFocusLost);

    return () => {
      if (container) {
        container.removeEventListener('focusout', handleFocusLost);
      }
    };
  }, []);

  if (!companyId && context === 'edit') {
    return (
      <ContentContainer>
        <GraphQlError />
      </ContentContainer>
    );
  }

  const selectableCategories = data?.benefitCategories?.filter(sc => {
    const name = comparableString(sc.name);
    const _search = comparableString(search ?? '');
    const isNotSelected = !selectedCategories.some(s =>
      isSameCategoryName(s.name, sc.name),
    );

    if (_search.length === 0 && focus) {
      return isNotSelected;
    }

    return isNotSelected && _search.length > 0 && name.includes(_search);
  });

  if (!selectedCategories?.length && context !== 'edit') {
    return null;
  }

  const selectCategory = id => {
    const category = data?.benefitCategories?.find(cat => cat.id === id);
    if (!category) {
      return;
    }

    setCategories([...selectedCategories, category]);
  };

  return (
    <>
      {loading && <TopLoading />}
      <ContentContainer ref={containerRef} tabIndex={0}>
        {error && <GraphQlError error={error} />}
        <>
          <ChipSet>
            {context === 'edit' && (
              <Chip
                clickable
                onClick={() => setIsModalOpen(_isModalOpen => !_isModalOpen)}
                leadingIcon={
                  <Icon color="onSurface" icon={addCircle} decorative />
                }
                text={<FormattedMessage {...discountMessages.newCategory} />}
              />
            )}
            {selectedCategories.map((selected, index) => {
              if (context !== 'edit') {
                return (
                  <Chip
                    text={selected.name}
                    key={selected.id}
                    clickable={context === 'admin-preview'}
                  />
                );
              }
              const isDefaultCategory = !selected.companyId && !!selected.id;
              const onClear = () =>
                setCategories(
                  selectedCategories.filter(
                    sc => !isSameCategoryName(sc.name, selected.name),
                  ),
                );
              return (
                <Chip
                  leadingIcon={<Icon icon={check} decorative />}
                  trailingIcon={
                    !isDefaultCategory && (
                      <IconButton
                        label={formatMessage(commonMessages.remove)}
                        onClick={onClear}
                        icon={cancel}
                        color="error"
                        size="xsmall"
                        slim
                      />
                    )
                  }
                  text={selected.name}
                  key={`selected-${index}`}
                  onKeyUp={onPressEnter(onClear)}
                />
              );
            })}

            {context === 'edit' && (
              <Input
                autoComplete="off"
                ref={searchRef}
                value={search}
                placeholder={formatMessage(
                  commonBenefitMessages.searchCategory,
                )}
                onFocus={() => setFocus(true)}
                onChange={e => setSearch(e.target.value)}
                searchLength={search?.length ?? 0}
                onKeyDown={onPressEnter()}
                onKeyUp={onPressEnter(() => {
                  if (!search) {
                    return;
                  }

                  const existingCategory = selectableCategories?.find(s =>
                    isSameCategoryName(s.name, search),
                  );

                  if (existingCategory) {
                    setCategories([...selectedCategories, existingCategory]);
                    setSearch('');
                  }
                })}
              />
            )}
          </ChipSet>

          {context === 'edit' && (
            <SelectableChipWrapper display={!!selectableCategories?.length}>
              <ScrollableSet type="chip">
                {selectableCategories?.map((selectable, index) => {
                  // If there is no companyId, we assume it's a
                  // default category, therefore it should not be selectable.
                  if (!selectable.companyId) {
                    return null;
                  }
                  const onSelect = () => {
                    setCategories([...selectedCategories, selectable]);
                    searchRef?.current?.focus();
                    setSearch('');
                  };

                  return (
                    <Chip
                      key={`selectable-${index}`}
                      leadingIcon={<Icon icon={addCircle} decorative />}
                      text={selectable.name}
                      clickable
                      onKeyUp={onPressEnter(onSelect)}
                      onClick={onSelect}
                    />
                  );
                })}
              </ScrollableSet>
            </SelectableChipWrapper>
          )}
        </>
      </ContentContainer>
      {context === 'edit' && (
        <CategoryModal
          isOpen={isModalOpen}
          onCreated={selectCategory}
          onRequestClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

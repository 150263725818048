import { Formik } from '@frontend/formik';
import { formatNaturalPersonIdentifier } from '@frontend/utils';
import {
  LegalEntityType,
  PaymentMethod,
  smeEditCompanyQuery,
  smeEditCompanyQueryVariables,
} from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/sme/company';
import { useQuery } from 'app/utils/use-query';
import { IntlShape, useIntl } from 'components/formats';
import { ClearingFieldType } from 'features/companies/company/select-clearing';
import { HasCompanyGroupType } from 'features/sme/create';
import React from 'react';
import { useParams } from 'react-router';
import {
  isEmail,
  isValidEmailDomain,
  isValidPhoneNumber,
  VALID_EMAIL_DOMAINS,
  validateNaturalPersonIdentifier,
} from 'validations';
import * as Yup from 'yup';

import { EditCompanyForm } from './form';
import { SME_EDIT_COMPANY_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    salesRep: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    adminFirstName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    adminLastName: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    adminNaturalPersonIdentifier: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid personal identity number',
        intl.formatMessage(validationMessages.isValidFirstNid),
        value => !value || validateNaturalPersonIdentifier(value),
      ),
    adminEmail: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid email',
        intl.formatMessage(validationMessages.isValidEmail),
        value => isEmail(value),
      )
      .test(
        'valid email domain',
        intl.formatMessage(validationMessages.invalidEmailDomain, {
          domains: VALID_EMAIL_DOMAINS.join(', '),
        }),
        value => isValidEmailDomain(value),
      ),
    adminPhone: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid phone number',
        intl.formatMessage(validationMessages.invalidPhoneNumber),
        value => !value || isValidPhoneNumber(value),
      ),

    contactFirstName: Yup.string().when('separateContactAndAdmin', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    contactLastName: Yup.string().when('separateContactAndAdmin', {
      is: true,
      then: schema =>
        schema.required(intl.formatMessage(validationMessages.mandatoryField)),
    }),
    contactNaturalPersonIdentifier: Yup.string().when(
      'separateContactAndAdmin',
      {
        is: true,
        then: schema =>
          schema.test(
            'valid personal identity number',
            intl.formatMessage(validationMessages.isValidFirstNid),
            value => !value || validateNaturalPersonIdentifier(value),
          ),
      },
    ),
    contactEmail: Yup.string().when('separateContactAndAdmin', {
      is: true,
      then: schema =>
        schema
          .required(intl.formatMessage(validationMessages.mandatoryField))
          .test(
            'valid email',
            intl.formatMessage(validationMessages.isValidEmail),
            value => isEmail(value),
          )
          .test(
            'valid email domain',
            intl.formatMessage(validationMessages.invalidEmailDomain, {
              domains: VALID_EMAIL_DOMAINS.join(', '),
            }),
            value => isValidEmailDomain(value),
          ),
    }),
    contactPhone: Yup.string().when('separateContactAndAdmin', {
      is: true,
      then: schema =>
        schema
          .required(intl.formatMessage(validationMessages.mandatoryField))
          .test(
            'valid phone number',
            intl.formatMessage(validationMessages.invalidPhoneNumber),
            value => isValidPhoneNumber(value),
          ),
    }),
  });

export interface FormValues {
  accountClosureMonth: number | null;
  accountNumber: string;
  address: string;
  adminEmail: string;
  adminFirstName: string;
  adminLastName: string;
  adminNaturalPersonIdentifier: string;
  adminPhone: string;
  city: string;
  clearingSalesOffice: ClearingFieldType;
  companyName: string;
  contactEmail: string;
  contactFirstName: string;
  contactLastName: string;
  contactNaturalPersonIdentifier: string;
  contactPhone: string;
  hasCompanyGroup: HasCompanyGroupType;
  legalEntityType: LegalEntityType;
  paymentMethod: PaymentMethod | '';
  postalCode: string;
  registrationNumber: string;
  salaryReviewMonth: number | null;
  salesRep: string | null;
  separateContactAndAdmin: boolean;
}

export const Edit: React.FC = () => {
  const { companyId } = useParams<MatchParams>();
  const { submit, submissionError } = useSubmit({ companyId });
  const intl = useIntl();

  const { data } = useQuery<smeEditCompanyQuery, smeEditCompanyQueryVariables>(
    SME_EDIT_COMPANY_QUERY,
    {
      variables: {
        id: companyId,
      },
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true,
      suspend: true,
    },
  );

  if (!data?.company) {
    return null;
  }

  const { company, nlpSalesReps, proposalContactPerson, proposalHeadAdmin } =
    data;

  const initialValues: FormValues = {
    accountClosureMonth: company.accountClosureMonth,
    accountNumber: company.accountNumber ?? '',
    address: company.address.line1 ?? '',
    adminEmail: proposalHeadAdmin?.email ?? '',
    adminFirstName: proposalHeadAdmin?.firstName ?? '',
    adminLastName: proposalHeadAdmin?.lastName ?? '',
    adminNaturalPersonIdentifier: proposalHeadAdmin?.personalIdentityNumber
      ? formatNaturalPersonIdentifier(proposalHeadAdmin.personalIdentityNumber)
      : '',
    adminPhone: proposalHeadAdmin?.phoneNumber ?? '',
    city: company.address.city,
    clearingSalesOffice: company.clearingCode
      ? {
          label: company.clearingCode.displayName,
          value: company.clearingCode.code,
        }
      : null,
    companyName: company.displayName,
    contactEmail: proposalContactPerson?.email ?? '',
    contactFirstName: proposalContactPerson?.firstName ?? '',
    contactLastName: proposalContactPerson?.lastName ?? '',
    contactNaturalPersonIdentifier:
      proposalContactPerson?.personalIdentityNumber
        ? formatNaturalPersonIdentifier(
            proposalContactPerson.personalIdentityNumber,
          )
        : '',
    contactPhone: proposalContactPerson?.phoneNumber ?? '',
    legalEntityType: company.legalEntityType,
    paymentMethod: company.paymentMethod ?? '',
    postalCode: company.address.postalCode,
    registrationNumber: company.registrationNumber ?? '',
    salaryReviewMonth: company.salaryReviewMonth,
    salesRep: company.nlpSalesRep?.id ?? '',
    hasCompanyGroup: company.hasCompanyGroup
      ? HasCompanyGroupType.YES
      : HasCompanyGroupType.NO,
    separateContactAndAdmin:
      proposalContactPerson?.personalIdentityNumber !==
      proposalHeadAdmin?.personalIdentityNumber,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submit}
      validateOnMount
      validationSchema={validationSchema(intl)}
    >
      {() => (
        <EditCompanyForm
          salesReps={nlpSalesReps}
          submissionError={submissionError}
        />
      )}
    </Formik>
  );
};

import { ApolloError } from '@apollo/client';
import { Form, useFormikContext } from '@frontend/formik';
import {
  Button,
  ContentContainer,
  WizardContainer,
  WizardFooter,
} from '@frontend/ui';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { formMessages, formSubmitMessages } from 'app/messages/form';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { LanguageSelector } from 'components/LanguageSelector';
// eslint-disable-next-line no-restricted-imports
import { WizardHeader } from 'components/WizardHeader';
import { useConfirm } from 'contexts/confirmation';
import { EffectiveLocale, useIntlContext } from 'contexts/IntlProviderWrapper';
import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { BenefitPresentation } from '../components/Presentation';

const FullScreenWizardContainer = styled(WizardContainer)`
  & > div {
    padding-left: 0;
    padding-right: 0;
    max-width: none;
    padding-top: 6.5rem;
  }
`;

export interface FormFile {
  url: string;
  file?: File;
  name?: string;
  path?: string;
}

export interface Attachment extends FormFile {
  name: string;
}

export type UploadableImage = FormFile;

export interface CallToAction {
  link: string;
  title: string;
  type: string;
}

export interface Content {
  body: string;
  locale: string;
  short: string;
  title: string;
  attachments?: Attachment[];
  callToAction?: CallToAction[];
  heroImage?: UploadableImage;
  heroImageAlignment?: string;
}

export interface Category {
  id: string;
  locale: string;
  name: string;
  companyId?: string | null;
}

export interface Supplier {
  companyId: string | null;
  id: string;
  name: string;
  websiteUrl: string | null;
  logo?: UploadableImage;
}

export interface BenefitFormValues {
  content: Content;
  categories?: Category[];
  // eslint-disable-next-line
  configuration?: Record<string, any> | null;
  id?: string;
  supplier?: Supplier;
}

interface Props {
  dirty: boolean;
  isSubmitting: boolean;
  parentLink: string;
  title: React.ReactNode;
  values: BenefitFormValues;
  queryError?: ApolloError;
  submissionError?: ApolloError;
}

export const BenefitForm: React.FC<Props> = ({
  parentLink,
  isSubmitting,
  values,
  title,
  dirty,
  queryError,
  submissionError,
}) => {
  const { formatMessage } = useIntl();
  const errorRef = useRef<HTMLDivElement | null>(null);
  const { locale, setLocale } = useIntlContext();
  const { confirm } = useConfirm();

  const { push } = useHistory();

  const formikContext = useFormikContext<BenefitFormValues>();

  const setCategories = useCallback(
    (categories: Category[]) => {
      formikContext.setFieldValue('categories', categories);
    },
    [formikContext],
  );

  useEffect(() => {
    if (!errorRef.current || !submissionError) {
      return;
    }
    errorRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [submissionError]);

  const handleLocaleChange = async (
    targetLocale: EffectiveLocale,
  ): Promise<void> => {
    if (targetLocale === locale) {
      return;
    }

    if (dirty) {
      const confirmed = await confirm({
        title: formatMessage(commonBenefitMessages.confirmLanguageSwitchTitle),
        description: formatMessage(commonBenefitMessages.confirmLanguageSwitch),
      });
      if (confirmed) {
        setLocale(targetLocale);
      }
    } else {
      setLocale(targetLocale);
    }
  };

  return (
    <Form style={{ height: '100%' }}>
      <WizardHeader title={title} parentLink={parentLink} />
      <FullScreenWizardContainer>
        <BenefitPresentation
          setCategories={setCategories}
          benefit={values}
          error={queryError}
          context="edit"
        />
        {submissionError && (
          <ContentContainer ref={errorRef}>
            <GraphQlError error={submissionError} />
          </ContentContainer>
        )}
      </FullScreenWizardContainer>
      <WizardFooter
        noOffsetLeft
        menu={
          <LanguageSelector setLocale={handleLocaleChange} value={locale} />
        }
        actions={
          <>
            <Button
              text
              onClick={async () => {
                if (!dirty) {
                  push(parentLink);
                  return;
                }

                const confirmed = await confirm({
                  description: formatMessage(
                    formSubmitMessages.confirmExitWizard,
                  ),
                });

                if (confirmed) {
                  push(parentLink);
                }
              }}
            >
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
            <Button
              loading={isSubmitting}
              disabled={!dirty}
              type="submit"
              filled
            >
              <FormattedMessage {...formMessages.save} />
            </Button>
          </>
        }
      />
    </Form>
  );
};

import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const SINGLE_PREMIUM_DEPOSIT_HISTORY_TABLE_QUERY = gql`
  query singlePremiumDepositHistoryTableQuery(
    $userAccountId: ID!
    $companyId: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      singlePremiums(
        first: $first
        last: $last
        before: $before
        after: $after
      ) {
        edges {
          node {
            effectiveDate
            premium
            authorizedBy # Used by authorizedByMembership in stitcher
            authorizedByMembership(companyId: $companyId) {
              givenName
              lastName
            }
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
    }
  }
  ${pageInfoDetails}
`;

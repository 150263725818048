import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const DISCOUNTS_OVERVIEW_QUERY = gql`
  query discountsOverviewQuery(
    $companyId: ID!
    $locale: String
    $first: Int
    $before: String
    $after: String
    $last: Int
    $onlyGetDiscounts: Boolean
  ) {
    company(id: $companyId) {
      id
      benefits: flexBenefits(
        onlyGetDiscounts: $onlyGetDiscounts
        locale: $locale
        first: $first
        last: $last
        before: $before
        after: $after
      ) {
        edges {
          node {
            id
            type
            content {
              id
              title
              locale
            }
            categories {
              id
              name
            }
            supplier {
              id
              name
            }
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
    }
  }
  ${pageInfoDetails}
`;

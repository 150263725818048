import { discountsOverviewQuery as Query } from 'app/apollo/graphql/types';

interface FetchMore {
  fetchMoreResult?: Query;
}

export const updateQuery = (
  previousResult: Query,
  { fetchMoreResult }: FetchMore,
): Query => {
  if (
    !previousResult?.company?.benefits ||
    !fetchMoreResult?.company?.benefits
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.company.benefits;

  return {
    company: {
      ...previousResult.company,
      benefits: {
        ...previousResult.company.benefits,
        pageInfo,
        edges: [...edges],
      },
    },
  };
};

import { defineMessages } from 'react-intl';

export const billingMessages = defineMessages({
  addBillingEmail: {
    id: 'billingMessages.addBillingEmail',
    description: 'Add billing email',
    defaultMessage: 'Lägg till',
  },
  editBillingEmail: {
    id: 'billingMessages.editBillingEmail',
    description: 'Edit billing email',
    defaultMessage: 'Ändra mottagare',
  },
  deleteBillingEmail: {
    id: 'billingMessages.deleteBillingEmail',
    description: 'Delete billing email',
    defaultMessage: 'Ta bort',
  },
  attachments: {
    id: 'billingMessages.attachments',
    description: 'Billing email attachments',
    defaultMessage: 'Format',
  },
  noBillingEmailsTitle: {
    id: 'billingMessages.noBillingEmailsTitle',
    description: 'Title saying there are no billing emails',
    defaultMessage: 'Inga e-postadresser för fakturering',
  },
  confirmDeleteBillingEmail: {
    id: 'billingMessages.confirmDeleteBillingEmail',
    description: 'Confirm message before deleting a billing email',
    defaultMessage: 'Ta bort mottagaren?',
  },
  confirmDeleteBillingEmailDescription: {
    id: 'billingMessages.confirmDeleteBillingEmailDescription',
    defaultMessage: 'Mottagaren kommer inte längre att få några fakturor.',
  },
  addBillingEmailSuccess: {
    id: 'billingMessages.createBillingEmailSuccess',
    description: 'Success message shown when a billing email is added',
    defaultMessage: 'E-postadressen tillagd',
  },
  updateBillingEmailSuccess: {
    id: 'billingMessages.updateBillingEmailSuccess',
    description: 'Success message shown when a billing email is updated',
    defaultMessage: 'E-postadressen har uppdaterats',
  },
  deleteBillingEmailSuccess: {
    id: 'billingMessages.deleteBillingEmailSuccess',
    description: 'Success message shown when a billing email is deleted',
    defaultMessage: 'E-postadressen har tagits bort',
  },
  billingEmailNote: {
    id: 'billingMessages.note',
    description: 'Note that explains what the recipient dialogue is about',
    defaultMessage: `Ange mottagare och vilka format som ska bifogas.
      Fakturorna finns alltid tillgängliga i portalen. PDF-sammanfattning
      innebär att endast en sammanfattande PDF bifogas.`,
  },
  formats: {
    id: 'billingMessages.formats',
    defaultMessage: `{format, select,
      CSV {CSV}
      PDF {PDF}
      XLSX {Excel}
      SIE4 {SIE 4}
      PDF_SUMMARY {PDF-sammanfattning}
      other {{format}}}`,
  },
  attachmentsRequired: {
    id: 'billingMessages.attachmentsRequired',
    defaultMessage: 'Format (Obligatoriskt)',
  },
});

import { useFormikObserver } from '@frontend/formik';
import {
  // eslint-disable-next-line
  WizardHeader as UiWizardHeader,
  WizardHeaderProps,
} from '@frontend/ui';
import { a11yMessages } from 'app/messages/a11y';
import { formSubmitMessages } from 'app/messages/form';
import { useIntl } from 'components/formats';
import { useModalAriaContext } from 'components/Modal';
import { useAppBarNavigationStack } from 'components/NavigationDrawer/lib/use-app-bar-navigation-stack';
import { useConfirm } from 'contexts/confirmation';
import React from 'react';
import { useHistory, useLocation } from 'react-router';

interface Props
  extends Omit<
    WizardHeaderProps,
    'closeButtonLabel' | 'onRequestClose' | 'progressLabel'
  > {
  parentLink: string;
}

/**
 *
 * Enhances the `WizardHeader` component from `@frontend/ui`.
 *
 * - Provides default a11y label for the close button.
 *
 * - Retrieve the `labelId` from ModalAriaContext and pass it in a <span> that wraps `title`
 *  to ensure proper association with the `aria-labelledby` attribute of the modal.
 *
 * - Manages `onRequestClose` to automatically navigate
 * to the previous route according to the internal navigation stack.
 *
 * For more information about the internal navigation stack, see: `useAppBarNavigationStack`.
 */
export const WizardHeader: React.FC<Props> = props => {
  const { hasDirtyFields } = useFormikObserver();
  const { confirm } = useConfirm();
  const { state } = useLocation();
  const { push } = useHistory();
  const { formatMessage } = useIntl();
  const { peek } = useAppBarNavigationStack();
  const { labelId } = useModalAriaContext();

  const onConfirm = hasDirtyFields
    ? async () =>
        confirm({
          title: formatMessage(formSubmitMessages.discardChanges),
          description: formatMessage(formSubmitMessages.confirmExitWizard),
        })
    : undefined;

  const onRequestClose = async () => {
    const close = () => {
      const path = peek() ?? props.parentLink;
      push(path, state);
    };

    if (!onConfirm || (await onConfirm())) {
      close();
    }
  };

  return (
    <UiWizardHeader
      closeButtonLabel={formatMessage(a11yMessages.exit)}
      onRequestClose={onRequestClose}
      {...props}
      title={<span id={labelId}>{props.title}</span>}
      progressLabel={formatMessage(a11yMessages.progress)}
    />
  );
};

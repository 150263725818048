import gql from 'graphql-tag';

export const SME_COMPANY_BENEFIT_PACKAGES_QUERY = gql`
  query smeCompanyBenefitPackagesQuery($id: ID!) {
    proposal(id: $id) {
      id
      benefitPackages {
        id
        benefits {
          id
          type
          configuration {
            ... on ProposalAccidentBenefitConfig {
              accidentLevel
            }
            ... on ProposalHealthcareBenefitConfig {
              healthcareLevel
              waitingPeriod
            }
            ... on ProposalLifeBenefitConfig {
              lifeLevel
            }
            ... on ProposalSicknessBenefitConfig {
              sicknessLevel
            }
            ... on ProposalOccupationalPensionBenefitConfig {
              premiumCalculationMethod
              premiumDeductions
              extraFixedPremium
              premiumWaiver
            }
            ... on ProposalSalaryExchangeBenefitConfig {
              pensionToSalaryMax
            }
          }
        }
        name
      }
    }
  }
`;

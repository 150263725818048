import { Button, ButtonLayout, ChipsField, SelectField } from '@frontend/ui';
import { advisorMessages } from 'app/messages/advisor';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import {
  SideSheet,
  SideSheetSection,
  SideSheetSubtitle,
} from 'components/SideSheet';
import React from 'react';

import { FilterParams } from '../../../utils/use-filter-params';

type FilterSideSheetProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  setFilterParam: (params: Partial<FilterParams>) => void;
  clearFilter: () => void;
  filterParams: FilterParams;
  companies: Array<{ label: string; value: string }>;
  benefitPackages: Array<{ label: string; value: string }>;
};

export const PersonalAdviceEmployeeFilterSideSheet: React.FC<
  FilterSideSheetProps
> = ({
  isOpen,
  setOpen,
  setFilterParam,
  clearFilter,
  filterParams,
  companies,
  benefitPackages,
}) => (
  <SideSheet
    footer={
      <ButtonLayout align="right">
        <Button onClick={clearFilter}>
          <FormattedMessage {...commonMessages.clear} />
        </Button>
        <Button filled onClick={() => setOpen(false)}>
          <FormattedMessage {...commonMessages.done} />
        </Button>
      </ButtonLayout>
    }
    open={isOpen}
    setOpen={setOpen}
    title={<FormattedMessage {...advisorMessages.filters} />}
  >
    <SideSheetSection>
      <SideSheetSubtitle>
        <FormattedMessage {...advisorMessages.chooseCompany} />
      </SideSheetSubtitle>
      <SelectField
        dense
        label={<FormattedMessage {...commonMessages.company} />}
        options={companies}
        value={filterParams.companyId}
        onChange={event => {
          setFilterParam({ companyId: event.detail.value });
          // Reset benefitPackageIds to not filter on other companies' benefit packages
          setFilterParam({ benefitPackageIds: [] });
        }}
      />
    </SideSheetSection>
    <SideSheetSection>
      <SideSheetSubtitle>
        <FormattedMessage {...advisorMessages.chooseBenefitPackage} />
      </SideSheetSubtitle>
      <ChipsField
        dense
        label={<FormattedMessage {...commonMessages.benefitPackage} />}
        options={benefitPackages}
        value={benefitPackages
          .filter(({ value }) => filterParams.benefitPackageIds.includes(value))
          .reduce((acc, { label, value }) => ({ ...acc, [value]: label }), {})}
        onChange={selections => {
          setFilterParam({ benefitPackageIds: Object.keys(selections) });
        }}
        disabled={!filterParams.companyId}
      />
    </SideSheetSection>
  </SideSheet>
);

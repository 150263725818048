import { ApolloError } from '@apollo/client';
import { Form, TextField } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { formMessages } from 'app/messages/form';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import React from 'react';

import { costReportingMessages } from '../../messages';

interface Props {
  code: string;
  name: string;
  onClose: () => void;
  title: React.ReactNode;
  codeFieldDisabled?: boolean;
  error?: ApolloError;
  isValid?: boolean;
}

export const CostReportingModal: React.FC<Props> = ({
  error,
  title,
  name,
  code,
  onClose,
  codeFieldDisabled,
  isValid,
}) => (
  <Modal size="medium" isOpen onRequestClose={onClose}>
    <Form>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <TextField
          name={code}
          dense
          label={<FormattedMessage {...costReportingMessages.codeFieldLabel} />}
          disabled={codeFieldDisabled}
        />
        <TextField
          name={name}
          dense
          label={<FormattedMessage {...costReportingMessages.nameFieldLabel} />}
        />
        {error && <GraphQlError inModal error={error} />}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onClose}>
            <FormattedMessage {...formMessages.cancel} />
          </Button>
          <Button text type="submit" disabled={!isValid}>
            <FormattedMessage {...formMessages.save} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Form>
  </Modal>
);

import { useMutation } from '@apollo/client';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import {
  deleteEmploymentMutation,
  deleteEmploymentMutationVariables,
  employmentDetailsQuery_employment_Employment as employmentDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employmentMessages } from 'app/messages/employees';
import { formSubmitMessages } from 'app/messages/form';
import { EmploymentRouteMatchParams } from 'app/pages/companies/company/employees/employee/employments/employment';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { useNotification } from 'features/notifications';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { MEMBERSHIP_EMPLOYMENTS_QUERY } from '../../../graphql/queries';
import { DELETE_EMPLOYMENT_MUTATION } from './graphql/mutations';

interface Props {
  employment: employmentDetails;
  isOpen: boolean;
  onRequestClose: () => void;
}

export const DeleteEmploymentModal: React.FC<Props> = ({
  employment,
  isOpen,
  onRequestClose,
}) => {
  const { send } = useNotification();
  const intl = useIntl();
  const { push } = useHistory();
  const {
    params: { companyId },
  } = useRouteMatch<EmploymentRouteMatchParams>();

  const [deleteEmployment, { loading, error }] = useMutation<
    deleteEmploymentMutation,
    deleteEmploymentMutationVariables
  >(DELETE_EMPLOYMENT_MUTATION, {
    variables: {
      input: {
        id: employment.id,
      },
    },
    onCompleted: () => {
      send({
        message: intl.formatMessage(formSubmitMessages.deleteEmploymentSuccess),
        type: 'success',
      });
      onRequestClose();
      push(
        `/companies/${companyId}/employees/${employment.membership.userAccountId}/employments`,
      );
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: MEMBERSHIP_EMPLOYMENTS_QUERY,
        variables: {
          userAccountId: employment.membership.userAccountId,
          companyId,
        },
      },
    ],
  });

  const employeeName = `${employment.membership.givenName} ${employment.membership.lastName}`;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage
          {...employmentMessages.deleteEmploymentForName}
          values={{ employeeName }}
        />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage {...employmentMessages.deleteEmploymentDesc} />
        {error && <GraphQlError error={error} inModal />}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onRequestClose}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
          <Button filled loading={loading} onClick={() => deleteEmployment()}>
            <FormattedMessage {...employmentMessages.deleteEmployment} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};

import { useFormikContext } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Card,
  CardContent,
  ellipsisCSS,
  Fab,
  Grid,
  GridCell as _GridCell,
  Icon,
  IconButton,
  media,
  ModalFooter,
  Text,
} from '@frontend/ui';
import { _delete, addPhotoAlternative, edit } from '@frontend/ui/icons';
import { a11yMessages } from 'app/messages/a11y';
import { commonMessages, fileMessages } from 'app/messages/common';
import { Dropzone } from 'components/Dropzone';
import { FormattedMessage, useIntl } from 'components/formats';
import { Preview } from 'components/ImageUpload';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { discountMessages } from 'features/companies/company/discounts/messages';
import { useRichTextEditorContext } from 'features/rich-text-editor/context';
import React, { useState } from 'react';
import styled from 'styled-components';

import { BenefitFormValues } from '../../form';

const EditFab = styled(Fab)`
  position: absolute;
  top: -1.25rem;
  right: 2.25rem;

  ${media.lessThan('tablet')`
    right: 2.5rem;
  `}
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 2rem;
`;

const FileNameWrapper = styled.span`
  ${ellipsisCSS}
`;

const FilePlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FilePreview = styled(Preview)`
  margin-bottom: 0;
`;

const GridCell = styled(_GridCell)`
  max-height: 11.25rem;
  overflow-y: auto;
`;

export const AttachmentsModal: React.FC = () => {
  const { formatMessage } = useIntl();
  const [isModalOpen, _set] = useState(false);
  const formikContext = useFormikContext<BenefitFormValues>();
  const { setIsReadOnly } = useRichTextEditorContext();

  const currentFiles =
    formikContext && formikContext.values.content.attachments;

  const setIsModalOpen = (isOpen: boolean) => {
    _set(isOpen);
    setIsReadOnly(isOpen);
  };

  const handleDropAccepted = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    formikContext.setFieldValue('content.attachments', [
      ...(formikContext.values.content.attachments ?? []),
      {
        name: file.name,
        file,
      },
    ]);
  };

  const handleRemoveFile = index => {
    const updatedAttachments = formikContext.values.content.attachments?.filter(
      (_, id) => index !== id,
    );
    formikContext.setFieldValue('content.attachments', updatedAttachments);
  };

  const handleSubmit = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <EditFab
        mini
        label={formatMessage(discountMessages.editTitle)}
        onClick={() => setIsModalOpen(true)}
        icon={<Icon icon={edit} decorative />}
      />
      <Modal
        size="small"
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ModalHeader>
          <FormattedMessage {...discountMessages.attachFile} />
        </ModalHeader>
        <ModalBody>
          <Grid>
            <GridCell span={12}>
              <Dropzone
                multiple={false}
                label={<FormattedMessage {...fileMessages.uploadFile} />}
                onDropAccepted={handleDropAccepted}
              >
                <FilePreview>
                  <FilePlaceholder>
                    <Icon
                      color="primary"
                      size="large"
                      icon={addPhotoAlternative}
                      decorative
                    />
                    <Text use="bodyMedium" style={{ marginTop: '1.375rem' }}>
                      <FormattedMessage {...fileMessages.fileUploadTitle} />
                    </Text>
                  </FilePlaceholder>
                </FilePreview>
              </Dropzone>
            </GridCell>

            {!!currentFiles?.length && (
              <GridCell span={12}>
                {currentFiles?.map((file, index) => (
                  <Card style={{ marginBottom: '0.625rem' }}>
                    <CardContent
                      style={{ padding: '0.5rem', paddingLeft: '1rem' }}
                    >
                      <FileContainer>
                        <FileNameWrapper>{file.name}</FileNameWrapper>
                        <IconButton
                          style={{ flexShrink: 0 }}
                          size="small"
                          icon={_delete}
                          label={formatMessage(commonMessages.remove)}
                          onClick={() => handleRemoveFile(index)}
                        />
                      </FileContainer>
                    </CardContent>
                  </Card>
                ))}
              </GridCell>
            )}
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button onClick={handleSubmit} text>
              <FormattedMessage {...a11yMessages.close} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
    </>
  );
};

import { pageInfoDetails } from 'app/fragments';
import { gql } from 'graphql-tag';

export const COMPANY_EMPLOYEES_OVERVIEW_QUERY = gql`
  query companyEmployeesOverviewQuery(
    $id: ID!
    $first: Int
    $last: Int
    $before: String
    $after: String
    $search: String
    $includeTerminated: Boolean
  ) {
    company(id: $id) {
      id
      memberships(
        first: $first
        last: $last
        before: $before
        after: $after
        search: $search
        includeTerminated: $includeTerminated
        showInternalAdmins: false
      ) {
        edges {
          node {
            id
            employment {
              from
              status {
                from
                to
                type {
                  id
                }
              }
              to
            }
            membershipBenefitPackage {
              benefitPackage {
                id
                name
              }
            }
            monthlySalary
            userAccountId
            givenName
            lastName
            naturalPersonIdentifiers
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
      membershipExportUrl(format: XLSX, includeInactive: $includeTerminated)
    }
  }
  ${pageInfoDetails}
`;

import { useFormikContext } from '@frontend/formik';
import { Button, Icon } from '@frontend/ui';
import {
  verticalAlignBottom,
  verticalAlignCenter,
  verticalAlignTop,
} from '@frontend/ui/icons';
import { useIntl } from 'components/formats';
import { discountMessages } from 'features/companies/company/discounts/messages';
import React from 'react';
import styled from 'styled-components';

import { BenefitFormValues } from '../../form';

const AlignmentButton = styled(Button)`
  background-color: ${p => p.theme.surface};
  border-radius: 0;
  border-color: ${p => p.theme.surfaceVariant} !important;
  width: 2.25rem;
  min-width: 0;
  line-height: 50%;

  &:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: none;
  }

  &:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
    border-left: none;
  }
`;

export enum HeroImageAlignment {
  BOTTOM = 'BOTTOM',
  CENTER = 'CENTER',
  TOP = 'TOP',
}

export const ImageAlignment: React.FC = () => {
  const context = useFormikContext<BenefitFormValues>();
  const { formatMessage } = useIntl();

  const handleClick = (alignment: HeroImageAlignment) => {
    context.setFieldValue('content.heroImageAlignment', alignment);
  };

  return (
    <div>
      <AlignmentButton onClick={() => handleClick(HeroImageAlignment.TOP)}>
        <Icon
          color="onSurface"
          icon={verticalAlignTop}
          label={formatMessage(discountMessages.alignCoverImageTop)}
        />
      </AlignmentButton>
      <AlignmentButton onClick={() => handleClick(HeroImageAlignment.CENTER)}>
        <Icon
          color="onSurface"
          icon={verticalAlignCenter}
          label={formatMessage(discountMessages.alignCoverImageCenter)}
        />
      </AlignmentButton>
      <AlignmentButton onClick={() => handleClick(HeroImageAlignment.BOTTOM)}>
        <Icon
          color="onSurface"
          icon={verticalAlignBottom}
          label={formatMessage(discountMessages.alignCoverImageBottom)}
        />
      </AlignmentButton>
    </div>
  );
};

import {
  companyNotificationsQuery_notifications_NotificationConnection_edges_NotificationEdge_node_Notification as Notification,
  NotificationType,
} from 'app/apollo/graphql/types';

export const getLink = (notification: Notification): string | null => {
  switch (notification.type) {
    case NotificationType.MISSING_EMAIL:
      return `/companies/${notification.companyId}/employees/${notification.userAccountId}/edit`;
    case NotificationType.MISSING_NAME:
      return `/companies/${notification.companyId}/employees/${notification.userAccountId}/edit`;
    case NotificationType.MISSING_FCFW_ASSERTION:
      return `/companies/${notification.companyId}/employees/assert-work-capability`;
    case NotificationType.MISSING_PENSION_PREMIUM:
      return `/companies/${notification.companyId}/employees/${notification.userAccountId}/occupational`;
    case NotificationType.MISSING_BENEFIT_PACKAGE:
    case NotificationType.MISSING_SALARY:
      return `/companies/${notification.companyId}/employees/${notification.userAccountId}`;
    case NotificationType.SX_VIOLATED:
      return `/companies/${notification.companyId}/salary-exchanges?upcoming-violated=true&violated=true`;
    case NotificationType.SX_REQUESTED:
      return `/companies/${notification.companyId}/salary-exchanges/requests`;
    default:
      return null;
  }
};

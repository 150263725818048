import { ApolloError, useMutation } from '@apollo/client';
import {
  createBenefitsMutation,
  createBenefitsMutationVariables,
  FlexCreateBenefitInput,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/discounts/edit';
import { useIntl } from 'components/formats';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { useNotification } from 'features/notifications';
import { useHistory, useRouteMatch } from 'react-router';
import { CUSTOM_BENEFIT_TYPE_NAME } from 'utils/constants';

import { CREATE_BENEFITS_MUTATION } from '../../../benefits/create/graphql/mutations';
import { BenefitFormValues } from '../../discount/presentation/form';
import {
  getFilePath,
  getFilePaths,
} from '../../discount/presentation/utils/get-file-paths';
import { useUpdateSupplier } from '../../discount/presentation/utils/use-update-supplier';
import { discountMessages } from '../../messages';

interface Props {
  initialValues?: BenefitFormValues;
}

interface Submit {
  submit: (values: BenefitFormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (props: Props): Submit => {
  const match = useRouteMatch<MatchParams>();
  const { push } = useHistory();
  const { companyId } = match.params;
  const { locale } = useIntlContext();
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const { initialValues } = props;

  const { updateSupplier, updateSupplierError } = useUpdateSupplier({
    companyId,
  });

  const [createBenefit, { error: createError }] = useMutation<
    createBenefitsMutation,
    createBenefitsMutationVariables
  >(CREATE_BENEFITS_MUTATION, {
    errorPolicy: 'all',
    update: cache => {
      cache.evict({ id: `Company:${companyId}`, fieldName: 'benefits' });
      cache.gc();
    },
  });

  const submit = async (values: BenefitFormValues) => {
    try {
      const { supplierId } = await updateSupplier({
        initialSupplier: initialValues?.supplier,
        supplier: values.supplier,
      });

      const heroImagePath =
        values.content.heroImage &&
        (await getFilePath(companyId, values.content.heroImage));

      const attachmentPaths = await getFilePaths(
        companyId,
        values.content.attachments,
      );

      const categoryIds = values.categories?.map(({ id }) => id);

      const input: FlexCreateBenefitInput = {
        benefitTypeName: CUSTOM_BENEFIT_TYPE_NAME,
        companyId,
        content: {
          title: values.content.title,
          short: values.content.short,
          body: values.content.body,
          callToAction: values.content.callToAction,
          locale: locale ?? 'sv-SE',
          heroImagePath,
          heroImageAlignment: values.content.heroImageAlignment,
          cardImagePath: heroImagePath,
          attachmentPaths,
        },
        supplierId,
        categoryIds,
      };

      const res = await createBenefit({
        variables: {
          input: {
            benefits: [input],
          },
        },
      });

      const benefitId = res.data?.createBenefits?.benefits[0]?.id;

      send({
        type: 'success',
        message: formatMessage(discountMessages.successfulBenefitSave, {
          name: values.content.title,
        }),
      });
      push(
        `/companies/${companyId}/benefit-packages/discounts/${benefitId}/presentation`,
      );
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    error: createError ?? updateSupplierError,
  };
};

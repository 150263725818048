import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import React from 'react';

import { benefitFormMessages } from '../../messages';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const ConversionModal: React.FC<Props> = ({ isOpen, setIsOpen }) => (
  <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
    <ModalHeader>
      <FormattedMessage {...benefitFormMessages.conversion} />
    </ModalHeader>
    <ModalBody>
      <FormattedMessage {...benefitFormMessages.conversionExplanation} />
    </ModalBody>
    <ModalFooter>
      <ButtonLayout align="right">
        <Button text onClick={() => setIsOpen(false)}>
          <FormattedMessage {...commonMessages.cancel} />
        </Button>
      </ButtonLayout>
    </ModalFooter>
  </Modal>
);

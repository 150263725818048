import { ProposalsSection } from 'features/sme/companies/company/proposals';
import { DuplicateProposalModal } from 'features/sme/companies/company/proposals/duplicate';
import { EditProposalNameModal } from 'features/sme/companies/company/proposals/edit/name';
import { ExecuteProposalModal } from 'features/sme/companies/company/proposals/execute';
import React from 'react';

export const SmeCompanyProposalsRoute: React.FC = () => (
  <>
    <ProposalsSection />
    <EditProposalNameModal />
    <ExecuteProposalModal />
    <DuplicateProposalModal />
  </>
);

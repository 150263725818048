import { useMutation } from '@apollo/client';
import { Form, Formik } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import {
  getSubmitErrors,
  toDecimalFraction,
  toEffectiveThrough,
  toEffectiveUntil,
  toPercent,
} from '@frontend/utils';
import {
  employmentDetailsQuery_employment_Employment as employmentDetails,
  updateEmploymentMutation,
  updateEmploymentMutationVariables,
} from 'app/apollo/graphql/types';
import { employmentMessages } from 'app/messages/employees';
import { formMessages, formSubmitMessages } from 'app/messages/form';
import { FormattedMessage, useIntl } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { useNotification } from 'features/notifications';
import React, { useState } from 'react';

import { EmploymentFormFields } from '../../../EmploymentFormFields';
import { employmentValidationSchema } from '../../../utils/employment-validation-schema';
import { EMPLOYMENT_DETAILS_QUERY } from '../graphql/queries';
import { UPDATE_EMPLOYMENT_MUTATION } from './graphql/mutations';

interface FormValues {
  effectiveDate: string;
  rate: number;
  effectiveThrough?: string;
}

interface Props {
  employment: employmentDetails;
  isDeletableEmployment: boolean;
  isOpen: boolean;
  onRequestClose: () => void;
}

export const EditEmploymentModal: React.FC<Props> = ({
  employment,
  isDeletableEmployment,
  isOpen,
  onRequestClose,
}) => {
  const { send } = useNotification();
  const intl = useIntl();

  const [formError, setFormError] = useState<string | undefined>();

  const [updateEmployment] = useMutation<
    updateEmploymentMutation,
    updateEmploymentMutationVariables
  >(UPDATE_EMPLOYMENT_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: EMPLOYMENT_DETAILS_QUERY,
        variables: { id: employment.id },
      },
    ],
  });

  const employeeName = `${employment.membership.givenName} ${employment.membership.lastName}`;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        initialValues={{
          effectiveDate: employment.period[0],
          effectiveThrough: employment.period[1]
            ? toEffectiveThrough(employment.period[1])
            : undefined,
          rate: toPercent(employment.rate),
        }}
        validationSchema={employmentValidationSchema(intl)}
        onSubmit={async ({ effectiveThrough, effectiveDate, rate }) => {
          try {
            await updateEmployment({
              variables: {
                input: {
                  id: employment.id,
                  effectiveDate,
                  effectiveUntil: effectiveThrough
                    ? toEffectiveUntil(effectiveThrough)
                    : null,
                  rate: rate ? toDecimalFraction(rate) : undefined,
                },
              },
            });
            send({
              message: intl.formatMessage(
                formSubmitMessages.updateEmploymentSuccess,
              ),
              type: 'success',
            });
            onRequestClose();
          } catch (error) {
            setFormError(getSubmitErrors({ intl, error }));
          }
        }}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          values: { effectiveThrough, effectiveDate },
        }) => (
          <Form onSubmit={handleSubmit}>
            <ModalHeader>
              <FormattedMessage
                {...employmentMessages.editEmploymentForName}
                values={{ employeeName }}
              />
            </ModalHeader>
            <ModalBody>
              <EmploymentFormFields />
              {effectiveThrough && effectiveThrough === effectiveDate && (
                <NotificationCard inModal type="warning">
                  {isDeletableEmployment ? (
                    <FormattedMessage
                      {...employmentMessages.deleteEmploymentWarning}
                      values={{
                        deleteEmploymentMsg: (
                          <FormattedMessage
                            {...employmentMessages.deleteEmployment}
                          />
                        ),
                      }}
                    />
                  ) : (
                    <FormattedMessage
                      {...employmentMessages.contactToDeleteEmployment}
                    />
                  )}
                </NotificationCard>
              )}
              {formError && (
                <NotificationCard inModal type="error">
                  {formError}
                </NotificationCard>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  filled
                  disabled={!isValid || !dirty}
                  loading={isSubmitting}
                >
                  <FormattedMessage {...formMessages.approve} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

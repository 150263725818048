import {
  bodyLargeCSS,
  Button,
  ButtonLayout,
  ContentContainer,
  headlineSmallCSS,
  LinearProgress as LinearProgressUI,
  media,
  WizardGrid,
} from '@frontend/ui';
import { a11yMessages } from 'app/messages/a11y';
import { FormattedMessage, useIntl } from 'components/formats';
import { Modal, ModalAriaContext } from 'components/Modal';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ILLUSTRATION_MAP } from '../utils/constants';
import { OnComplete, Transition } from '../utils/types';

const FADE_IN_DURATION = 500;
const FADE_OUT_DURATION = 600;

const fadeInCss = css`
  animation: fade-in ${FADE_IN_DURATION}ms ease-in;
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Title = styled.h1`
  ${headlineSmallCSS};
  margin-bottom: 0.5rem;
  text-align: center;
`;

const Description = styled.p`
  ${bodyLargeCSS}
  margin-bottom: 2rem;
`;

const LinearProgress = styled(LinearProgressUI)`
  text-align: start;
`;

const Img = styled.img`
  width: 100%;
`;

const IllustrationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  width: 18.5rem;
  ${media.lessThan('tablet')`
      width: 15.625rem;
    `};
`;

const Wrapper = styled(ContentContainer)<{ fadeOut: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  ${fadeInCss}
  ${p =>
    p.fadeOut &&
    `
      animation: fade-out ${FADE_OUT_DURATION}ms forwards;
      @keyframes fade-out {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
    `}
  
    & > * {
    flex: auto;
  }

  ${media.lessThan('tablet')`
      padding: 0 0.5rem;
    `};
`;

interface Props {
  clearTransition: () => void;
  pathname: string;
  transition: Transition;
}

export const TransitionOverlay: React.FC<Props> = ({
  clearTransition,
  pathname,
  transition: {
    cancelAction,
    description,
    illustration,
    modal,
    opaqueFadeIn,
    persistOnPrimary,
    primaryAction,
    progress,
    secondaryAction,
    title,
  },
}) => {
  const [fadeOut, setFadeOut] = useState(false);
  const isInitRender = useRef(true);
  const { formatMessage } = useIntl();

  const onComplete: OnComplete = args => {
    setFadeOut(true);
    setTimeout(() => {
      clearTransition();
      const { action } = args;
      action();
      // + 300ms so that the animation looks good
    }, FADE_OUT_DURATION + 300);
  };

  useEffect(() => {
    if (isInitRender.current) {
      isInitRender.current = false;
      return;
    }
    clearTransition();
  }, [pathname]);

  return (
    <Modal size="full-window" withAnimation={!opaqueFadeIn} zIndex={8}>
      <ModalAriaContext.Consumer>
        {({ labelId }) => (
          <Wrapper fadeOut={fadeOut}>
            <WizardGrid>
              <IllustrationWrapper>
                <Img
                  src={ILLUSTRATION_MAP[illustration]}
                  alt=""
                  role="presentation"
                />
              </IllustrationWrapper>
              <Title id={labelId}>
                {typeof title !== 'string' ? (
                  <FormattedMessage {...title} />
                ) : (
                  title
                )}
              </Title>
              {description && <Description>{description}</Description>}
              {progress !== undefined && (
                <div style={{ padding: '0 1rem' }}>
                  <LinearProgress
                    progress={
                      progress !== 'indeterminate' ? progress : undefined
                    }
                    label={formatMessage(a11yMessages.progress)}
                  />
                </div>
              )}
              {modal?.(onComplete)}
              <ButtonLayout align="center">
                {!!cancelAction && (
                  <Button
                    text
                    onClick={() => onComplete({ action: cancelAction.action })}
                  >
                    {cancelAction.label}
                  </Button>
                )}
                {!!primaryAction && (
                  <Button
                    filled
                    onClick={() => {
                      if (persistOnPrimary) {
                        primaryAction.action();
                        return;
                      }

                      onComplete({
                        action: primaryAction.action,
                      });
                    }}
                  >
                    {primaryAction.label}
                  </Button>
                )}
                {!!secondaryAction && (
                  <Button
                    onClick={() =>
                      onComplete({ action: secondaryAction.action })
                    }
                  >
                    {secondaryAction.label}
                  </Button>
                )}
              </ButtonLayout>
            </WizardGrid>
          </Wrapper>
        )}
      </ModalAriaContext.Consumer>
    </Modal>
  );
};

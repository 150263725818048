import { DurationUnit } from '@frontend/utils';
import { defineMessages } from 'react-intl';

import { formMessages } from './form';

export const commonMessages = defineMessages({
  age: {
    id: 'commonMessages.age',
    defaultMessage: 'Ålder',
  },
  back: {
    id: 'commonMessages.back',
    defaultMessage: 'Tillbaka',
  },
  nYears: {
    id: 'commonMessages.nYears',
    defaultMessage: '{count} år',
  },
  userId: {
    id: 'common.userId',
    defaultMessage: 'Användar-ID',
  },
  nFiltersSelected: {
    id: 'common.nFiltersSelected',
    defaultMessage: `{count, plural,
      =0 {Filtrera}
      =1 {# filter valt}
      other {# filter valda}
    }`,
  },
  nSelected: {
    id: 'common.nSelected',
    defaultMessage: '{selected, plural, one {# vald} other {# valda}}',
  },
  resetFilters: {
    id: 'common.resetFilters',
    defaultMessage: 'Återställ filter',
  },
  companies: {
    id: 'common.companies',
    defaultMessage: 'Företag',
  },
  date: {
    id: 'common.date',
    defaultMessage: 'Datum',
  },
  fileName: {
    id: 'common.fileName',
    defaultMessage: 'Filnamn',
  },
  yearMonth: {
    id: 'common.yearMonth',
    defaultMessage: '{value, date, ::yyyy-MM}',
  },
  confirm: {
    id: 'common.confirm',
    defaultMessage: 'Bekräfta',
  },
  missing: {
    id: 'common.missing',
    defaultMessage: 'Saknas',
  },
  approved: {
    id: 'common.approved',
    defaultMessage: 'Godkänd',
  },
  continue: {
    id: 'common.continue',
    defaultMessage: 'Gå vidare',
  },
  confirmed: {
    id: 'common.confirmed',
    defaultMessage: 'Bekräftat',
  },
  value: {
    id: 'common.value',
    defaultMessage: 'Värde',
  },
  clearFilter: {
    id: 'common.clearFilter',
    defaultMessage: 'Rensa',
  },
  filter: {
    id: 'common.filter',
    defaultMessage: 'Filter',
  },
  done: {
    id: 'common.done',
    defaultMessage: 'Klar',
  },
  add: {
    id: 'common.add',
    defaultMessage: 'Lägg till',
  },
  active: {
    id: 'common.active',
    defaultMessage: 'Aktiv',
  },
  compensation: {
    id: 'common.compensation',
    defaultMessage: 'Ersättning',
  },
  premium: {
    id: 'common.premium',
    defaultMessage: 'Premie',
  },
  informationDetails: {
    id: 'common.informationDetails',
    defaultMessage: 'Uppgifter',
  },
  updatePremiumValue: {
    id: 'common.updatePremiumValue',
    defaultMessage: 'Ändra premie',
  },
  management: {
    id: 'common.management',
    defaultMessage: 'Förvaltning',
  },
  benefitPackageLabel: {
    id: 'common.benefitPackageLabel',
    defaultMessage: 'Förmånsgrupp',
  },
  postalCode: {
    id: 'common.postalCode',
    defaultMessage: 'Postnummer',
  },
  personalIdentityNumber: {
    id: 'common.personalIdentityNumber',
    defaultMessage: 'Personnummer',
  },
  status: {
    id: 'common.status',
    defaultMessage: 'Status',
  },
  monthlySalary: {
    id: 'common.monthlySalary',
    defaultMessage: 'Månadslön',
  },
  naturalPersonIdentifier: {
    id: 'common.naturalPersonIdentifier',
    defaultMessage: 'Personnummer eller samordningsnummer',
  },
  benefitPackageOptOut: {
    id: 'common.benefitPackageOptOut',
    defaultMessage: 'Tilldela inte några förmåner till den anställda',
  },
  benefitPackageDescription: {
    id: 'common.benefitPackageDescription',
    defaultMessage:
      'Förmånsgrupp styr vilka förmåner den anställda får tillgång till. Förmånsgrupp är obligatoriskt för att aktivera förmåner.',
  },
  premiumPerMonth: {
    id: 'common.premiumPerMonth',
    defaultMessage: '{value, number, ::currency/SEK .} per månad',
  },
  updatingPremiumValue: {
    id: 'common.updatingPremiumValue',
    description: 'Premium is updating message from current {value}',
    defaultMessage:
      'Premien uppdateras (från nuvarande premie {value, number, ::currency/SEK .} per månad)',
  },
  premiumWithUpcomingPremium: {
    id: 'common.premiumWithUpcomingPremium',
    defaultMessage:
      '{premium, number, ::currency/SEK .} per månad (premien kommer att uppdateras till {newPremium, number, ::currency/SEK .} per månad fr.o.m. {newPremiumEffectiveDate})',
  },
  requiredField: {
    id: 'common.requiredField',
    defaultMessage: 'Obligatoriskt fält',
  },
  effectiveFrom: {
    id: 'common.effectiveFrom',
    defaultMessage: 'Tecknad fr.o.m.',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Avbryt',
  },
  amount: {
    id: 'common.amount',
    defaultMessage: 'Belopp',
  },
  taxable: {
    id: 'common.taxable',
    defaultMessage: 'Skattepliktig ersättning',
  },
  level: {
    id: 'common.level',
    defaultMessage: 'Ersättningsnivå',
  },
  yes: { id: 'common.yes', description: 'Ja', defaultMessage: 'Ja' },
  no: { id: 'common.no', description: 'Nej', defaultMessage: 'Nej' },
  notApplicable: { id: 'common.notApplicable', defaultMessage: 'Ej relevant' },
  remuneration: {
    id: 'common.remuneration',
    defaultMessage: 'Ersättning',
  },
  refersToDate: {
    id: 'common.refersToDate',
    defaultMessage: 'Avser datum',
  },
  insuranceNumber: {
    id: 'common.insuranceNumber',
    defaultMessage: 'Försäkringsnummer',
  },
  of: {
    id: 'common.of',
    defaultMessage: 'Av',
  },
  insuranceProvider: {
    id: 'common.insuranceProvider',
    defaultMessage: 'Försäkringsbolag',
  },
  vacation: {
    id: 'common.vacation',
    defaultMessage: 'Semester',
  },
  company: {
    id: 'common.company',
    defaultMessage: 'Företag',
  },
  employment: {
    id: 'common.employment',
    defaultMessage: 'Anställning',
  },
  employments: {
    id: 'common.employments',
    defaultMessage: 'Anställningar',
  },
  givenName: {
    id: 'common.givenName',
    defaultMessage: 'Förnamn',
  },
  lastName: {
    id: 'common.lastName',
    defaultMessage: 'Efternamn',
  },
  startDate: {
    id: 'common.startDate',
    description: 'Start date',
    defaultMessage: 'Startdatum',
  },
  nidLong: {
    id: 'common.nidLong',
    defaultMessage: 'Identifierare',
  },
  remove: {
    id: 'common.remove',
    description: 'Remove',
    defaultMessage: 'Ta bort',
  },
  edit: {
    id: 'common.edit',
    defaultMessage: 'Ändra',
  },
  endDate: {
    id: 'common.endDate',
    defaultMessage: 'Slutdatum',
  },
  organization: {
    id: 'common.organization',
    defaultMessage: 'Organisation',
  },
  organizationalUnit: {
    id: 'common.organizationalUnit',
    defaultMessage: 'Avdelning',
  },
  benefitPackage: {
    id: 'common.benefitPackage',
    defaultMessage: 'Förmånsgrupp',
  },
  email: {
    id: 'common.email',
    defaultMessage: 'E-post',
  },
  document: {
    id: 'common.document',
    description: 'Dokument',
    defaultMessage: 'Dokument',
  },
  registered: {
    id: 'common.registered',
    description: 'Registrerad',
    defaultMessage: 'Inlagd',
  },
  phone: {
    id: 'common.phone',
    defaultMessage: 'Telefonnummer',
  },
  name: {
    id: 'common.name',
    defaultMessage: 'Namn',
  },
  collectiveAgreement: {
    id: 'common.collectiveAgreement',
    defaultMessage: 'Kollektivavtal',
  },
  riskInsurance: {
    id: 'common.riskInsurance',
    description: 'Riskförsäkringar',
    defaultMessage: 'Riskförsäkringar',
  },
  nid: {
    id: 'common.nid',
    defaultMessage: 'ID',
  },
  id: {
    id: 'common.id',
    defaultMessage: 'ID',
  },
  internalId: {
    id: 'common.internalId',
    defaultMessage: 'Internt ID',
  },
  perPageResult: {
    id: 'common.perPageResult',
    defaultMessage: 'Visa',
  },
  forward: {
    id: 'common.forward',
    defaultMessage: 'Framåt',
  },
  backward: {
    id: 'common.backward',
    defaultMessage: 'Tillbaka',
  },
  effectiveDate: {
    id: 'common.effectiveDate',
    defaultMessage: 'Gäller fr.o.m.',
  },
  detail: {
    id: 'common.detail',
    defaultMessage: 'Detaljer',
  },
  noData: {
    id: 'common.noData',
    defaultMessage: 'Data saknas',
  },
  month: {
    id: 'common.month',
    defaultMessage: 'Månad',
  },
  year: {
    id: 'common.year',
    defaultMessage: 'År',
  },
  nordeaNode: {
    id: 'common.advinans',
    defaultMessage: 'Nordea Node',
  },
  startingAt: {
    id: 'common.startingAt',
    description: 'Starting at',
    defaultMessage: 'Fr.o.m.',
  },
  endingOn: {
    id: 'common.endingOn',
    description: 'Ending on',
    defaultMessage: 'T.o.m.',
  },
  extent: {
    id: 'common.extent',
    description: 'extent',
    defaultMessage: 'Omfattning',
  },
  updateInformationDetails: {
    id: 'common.updateInformationDetails',
    defaultMessage: 'Ändra uppgifter',
  },
  userAccountId: {
    id: 'common.userAccountId',
    defaultMessage: 'User account ID',
  },
  companyId: {
    id: 'common.companyId',
    defaultMessage: 'Company ID',
  },
  identityNumber: {
    id: 'common.identityNumber',
    defaultMessage: 'ID-nummer',
  },
  registrationNumber: {
    id: 'common.registrationNumber',
    defaultMessage: 'Person-/Organisationsnummer',
  },
  from: {
    id: 'common.from',
    defaultMessage: 'Från',
  },
  to: {
    id: 'common.to',
    defaultMessage: 'Till',
  },
  clear: {
    id: 'common.clear',
    defaultMessage: 'Rensa',
  },
  send: {
    id: 'common.send',
    defaultMessage: 'Skicka',
  },
  nRowsSelected: {
    id: 'common.nRowsSelected',
    defaultMessage:
      '{count, plural, one {# rad markerad} other {# rader markerade}}',
  },
  address: {
    id: 'common.address',
    defaultMessage: 'Adress',
  },
  activated: {
    id: 'common.activated',
    defaultMessage: 'Aktiverad',
  },
  deactivated: {
    id: 'common.deactivated',
    defaultMessage: 'Inaktiverad',
  },
  inactive: {
    id: 'common.inactive',
    defaultMessage: 'Inaktiv',
  },
  activate: {
    id: 'common.activate',
    defaultMessage: 'Aktivera',
  },
  deactivate: {
    id: 'common.deactivate',
    defaultMessage: 'Inaktivera',
  },
  city: {
    id: 'common.city',
    defaultMessage: 'Postort',
  },
  co: { id: 'common.co', defaultMessage: 'C/o' },
  amountLabel: {
    id: 'common.amountLabel',
    description: 'Amount (kr)',
    defaultMessage: 'Belopp',
  },
  menu: {
    id: 'common.menu',
    defaultMessage: 'Meny',
  },
  create: {
    id: 'common.create',
    description: 'Skapa',
    defaultMessage: 'Skapa',
  },
  benefit: {
    id: 'common.benefit',
    defaultMessage: 'Förmån',
  },
  benefits: {
    id: 'common.benefits',
    defaultMessage: 'Förmåner',
  },
  category: {
    id: 'common.category',
    defaultMessage: 'Kategori',
  },
  all: {
    id: 'common.all',
    defaultMessage: 'Alla',
  },
  supplier: {
    id: 'common.supplier',
    description: 'Supplier',
    defaultMessage: 'Leverantör',
  },
  search: {
    id: 'common.search',
    description: 'Search',
    defaultMessage: 'Sök',
  },
  update: {
    id: 'common.update',
    defaultMessage: 'Uppdatera',
  },
  nordea: {
    id: 'common.nordea',
    defaultMessage: 'Nordea',
  },
  euroAccident: {
    id: 'common.euroAccident',
    defaultMessage: 'Euro Accident',
  },
  total: {
    id: 'common.total',
    defaultMessage: 'Totalt',
  },
  effectiveUntil: {
    id: 'common.effectiveUntil',
    defaultMessage: 'Gäller t.o.m.',
  },
  available: {
    id: 'common.available',
    defaultMessage: 'Tillgängligt',
  },
  perYear: {
    id: 'common.perYear',
    defaultMessage: 'Per år',
  },
  review: {
    id: 'common.review',
    defaultMessage: 'Granska',
  },
  activeCount: {
    id: 'common.activeCount',
    defaultMessage: '{count, plural, =1 {Aktiv} other {Aktiva}}',
  },
  requests: {
    id: 'common.requests',
    description: 'Requests',
    defaultMessage: 'Ansökningar',
  },
  toHandle: {
    id: 'common.toHandle',
    description: 'To handle',
    defaultMessage: 'Att hantera',
  },
  via: { id: 'common.via', description: 'Via', defaultMessage: 'Via' },
  stop: {
    id: 'common.stop',
    defaultMessage: 'Avsluta',
  },
  calculate: {
    id: 'common.calculate',
    defaultMessage: 'Gör beräkning',
  },
  capacity: {
    id: 'common.capacity',
    defaultMessage: 'Utrymme',
  },
  requested: {
    id: 'common.requested',
    defaultMessage: 'Ansökt',
  },
  report: {
    id: 'common.report',
    defaultMessage: 'Rapport',
  },
  refers: {
    id: 'common.refers',
    defaultMessage: 'Avser',
  },
  updatedAt: {
    id: 'common.updatedAt',
    defaultMessage: 'Uppdaterad',
  },
  embed: {
    id: 'common.embed',
    defaultMessage: 'Bädda in',
  },
  attachedFiles: {
    id: 'common.attachedFiles',
    description: 'Attached files',
    defaultMessage: 'Bifogade filer',
  },
  expandName: {
    id: 'common.expandName',
    defaultMessage: 'Expandera {name}',
  },
  insurance: {
    id: 'common.insurance',
    defaultMessage: 'Försäkring',
  },
  change: {
    id: 'common.change',
    description: 'Ändra',
    defaultMessage: 'Ändra',
  },
  selectAllRows: {
    id: 'common.selectAllRows',
    defaultMessage: 'Välj alla rader',
  },
  offers: {
    id: 'common.offers',
    defaultMessage: 'Erbjudanden',
  },
  description: {
    id: 'common.description',
    defaultMessage: 'Beskrivning',
  },
  quantity: {
    id: 'common.quantity',
    defaultMessage: 'Antal',
  },
  exit: {
    id: 'common.exit',
    defaultMessage: 'Avsluta',
  },
  validityPeriod: {
    id: 'common.validityPeriod',
    defaultMessage: 'Giltighetsperiod',
  },
  visibility: {
    id: 'common.visibility',
    defaultMessage: 'Synlighet',
  },
  benefitPackages: {
    id: 'common.benefitPackages',
    defaultMessage: 'Förmånsgrupper',
  },
  openMenu: {
    id: 'common.openMenu',
    defaultMessage: 'Öppna meny',
  },
  language: {
    id: 'common.language',
    defaultMessage: 'Språk',
  },
  goToPreviousPage: {
    id: 'common.goToPreviousPage',
    defaultMessage: 'Föregående sida',
  },
  goToNextPage: {
    id: 'common.goToNextPage',
    defaultMessage: 'Nästa sida',
  },
  perPage: {
    id: 'common.perPage',
    defaultMessage: 'Antal träffar per sida',
  },
  chooseDate: {
    id: 'common.chooseDate',
    defaultMessage: 'Välj datum',
  },
  created: {
    id: 'common.created',
    defaultMessage: 'Skapad',
  },
  personAdded: {
    id: 'common.personAdded',
    defaultMessage: 'Anställd tillagd',
  },
  noValue: {
    id: 'common.noValue',
    defaultMessage: 'Ingen uppgift',
  },
});

export const suffixMessages = defineMessages({
  perMonth: {
    id: 'suffixMessages.perMonth',
    defaultMessage: '/mån',
  },
  perYear: {
    id: 'suffixMessages.perYear',
    defaultMessage: '/år',
  },
  krPerMonth: {
    id: 'suffixMessages.krPerMonth',
    defaultMessage: 'kr/mån',
  },
  krPerYear: {
    id: 'suffixMessages.krPerYear',
    defaultMessage: 'kr/år',
  },
  kr: {
    id: 'suffixMessages.kr',
    defaultMessage: 'kr',
  },
  percentage: {
    id: 'suffixMessages.percentage',
    defaultMessage: '%',
  },
  years: {
    id: 'suffixMessages.years',
    defaultMessage: 'år',
  },
  age: {
    id: 'suffixMessages.age',
    defaultMessage: 'år',
  },
  days: {
    id: 'suffixMessages.days',
    defaultMessage: 'dagar',
  },
  pieces: {
    id: 'suffixMessages.pieces',
    defaultMessage: 'st',
  },
  percentPerYear: {
    id: 'suffixMessages.percentPerYear',
    defaultMessage: '%/år',
  },
  monthly: {
    id: 'suffixMessages.monthly',
    defaultMessage: 'mån',
  },
  annually: {
    id: 'suffixMessages.annually',
    defaultMessage: 'år',
  },
});

export const validationMessages = defineMessages({
  agreementStart: {
    id: 'validationMessages.agreementStart',
    defaultMessage: 'Avtal kan inte starta tidigare än {date}',
  },
  minOneOptionRequired: {
    id: 'validationMessages.minOneOptionRequired',
    defaultMessage: 'Du måste välja minst ett alternativ',
  },
  invalidPercent: {
    id: 'validationMessages.invalidPercent',
    defaultMessage: 'Invalid percent, 0 - 1',
  },
  mandatoryField: {
    id: 'validationMessages.mandatoryField',
    defaultMessage: 'Obligatoriskt fält',
  },
  dateMaxThreeMonthsOld: {
    id: 'validationMessages.dateMaxThreeMonthsOld',
    defaultMessage: 'Ange ett datum max tre månader bakåt',
  },
  invalidPin: {
    id: 'validationMessages.invalidPin',
    defaultMessage: 'Ogiltigt personnummer',
  },
  invalidPinOrCoordinationNumber: {
    id: 'validationMessages.invalidPinOrCoordinationNumber',
    defaultMessage: 'Ogiltigt person- eller samordningsnummer',
  },
  isMaxTwoFiles: {
    id: 'validationMessages.isMaxTwoFiles',
    defaultMessage: 'Högst två filer är tillåtna.',
  },
  isPositiveNumber: {
    id: 'validationMessages.isPositiveNumber',
    defaultMessage: 'Ange ett värde större än 0',
  },
  isValidEpassiCustomerId: {
    id: 'validationMessages.isValidEpassiCustomerId',
    defaultMessage:
      'Ogiltigt format. Kund-ID måste vara antingen 4 eller 5 tecken',
  },
  isValidNid: {
    id: 'validationMessages.isValidNid',
    defaultMessage: 'Ogiltigt personnummer, samordningsnummer eller A-nummer',
  },
  isValidFirstNid: {
    id: 'validationMessages.isValidFirstNid',
    defaultMessage: 'Ogiltigt person- eller samordningsnummer',
  },
  isBeforeStartDate: {
    id: 'validationMessages.isBeforeStartDate',
    defaultMessage: 'Ange ett slutdatum som inte är innan startdatumet',
  },
  invalidEmploymentRate: {
    id: 'validationMessages.invalidEmploymentRate',
    description:
      'Validation message thrown when employment rate is not between 1 - 100',
    defaultMessage: 'Ange sysselsättningsgrad mellan 1 och 100 procent',
  },
  invalidDate: {
    id: 'validationMessages.invalidDate',
    description:
      'Validation message shown for invalid date (it must match format YYYY-MM-DD)',
    defaultMessage: 'Ange ett giltigt datum (ÅÅÅÅ-MM-DD)',
  },
  invalidMonth: {
    id: 'validationMessages.invalidMonth',
    description:
      'Validation message shown for invalid month (it must match format YYYY-MM)',
    defaultMessage: 'Ange en giltig månad (ÅÅÅÅ-MM)',
  },
  invalidPhoneNumber: {
    id: 'validationMessages.invalidPhoneNumber',
    defaultMessage: 'Ogiltigt telefonnummer',
  },
  accountingItemEffectiveDateError: {
    id: 'validationMessages.accountingItemEffectiveDateError',
    defaultMessage: 'Ny kostnadsallokering kan inte starta före föregående',
  },
  isValidAddress: {
    id: 'validationMessages.isValidAddress',
    defaultMessage:
      'Adressen måste innehålla en adressrad, stad och postnummer',
  },
  isValidEmail: {
    id: 'validationMessages.isValidEmail',
    description: 'Validation message thrown on invalid email input',
    defaultMessage: 'Ogiltig e-postadress',
  },
  invalidUuid: {
    id: 'validationMessages.invalidUuid',
    description: 'Validation message thrown on invalid UUID',
    defaultMessage:
      'Ogiltigt ID (behöver följa formatet xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)',
  },
  invalidRegistrationNumber: {
    id: 'validationMessages.invalidRegistrationNumber',
    description: 'Validation message thrown on invalid registration number',
    defaultMessage: 'Ogiltigt person- eller organisationsnummer',
  },
  isAbove20: {
    id: 'validationMessages.isAbove20',
    description: 'Validation message thrown when field is below 20',
    defaultMessage: 'Dimensionsnummer under 20 är reserverade',
  },
  max: {
    id: 'validationMessages.max',
    defaultMessage: 'Max {max}',
  },
  isValidPhone: {
    id: 'validationMessages.isValidPhone',
    defaultMessage: 'Felaktigt format',
  },
  uniqueField: {
    id: 'validationMessages.uniqueField',
    defaultMessage: 'Varje fält kan bara förekomma en gång',
  },
  earliestPossibleMonth: {
    id: 'validationMessages.earliestPossibleMonth',
    defaultMessage:
      'Startdatum kan inte vara tidigare än {date, date, ::yyyy-MM-dd}',
  },
  chooseLaterMonth: {
    id: 'validationMessages.chooseLaterMonth',
    defaultMessage:
      'Brytpunkt för att välja innevarande månad har passerats. Startdatum kan inte vara tidigare än {date, date, ::yyyy-MM-dd}',
  },
  invalidEmailDomain: {
    id: 'validationMessages.invalidEmailDomain',
    defaultMessage:
      'E-postadressens domän är inte tillåten. Endast följande domäner är tillåtna: {domains}',
  },
  pensionBenefitNotAvailable: {
    id: 'validationMessages.pensionBenefitNotAvailable',
    defaultMessage:
      'Du kan inte skapa en tjänstepensionsförmån eftersom du inte har gett råd på sparförsäkringar',
  },
  pensionBenefitNotAvailableForBackage: {
    id: 'validationMessages.pensionBenefitNotAvailableForBackage',
    defaultMessage:
      'Du försöker skapa ett standardpaket som innehåller tjänstepension. Du kan inte skapa en tjänstepensionsförmån eftersom du inte har gett råd på sparförsäkringar',
  },
});

export const frequencySuffixMessages = defineMessages<DurationUnit>({
  years: {
    id: 'frequencySuffixMessages.years',
    defaultMessage: `{count, plural,
        =0 {}
        one {/år}
        two { varannat år}
        other { var #e år}
      }`,
  },
  months: {
    id: 'frequencySuffixMessages.months',
    defaultMessage: `{count, plural,
        =0 {}
        one {/mån}
        two { varannan månad}
        other { var #e månad}
      }`,
  },
  days: {
    id: 'frequencySuffixMessages.days',
    defaultMessage: `{count, plural,
        =0 {}
        one {/dag}
        two { varannan dag}
        other { var #e dag}
      }`,
  },
});

export const fileMessages = defineMessages({
  fileName: {
    id: 'fileMessages.fileName',
    defaultMessage: 'Filnamn',
  },
  addMoreFiles: {
    id: 'fileMessages.addMoreFiles',
    defaultMessage: 'Lägg till fler filer',
  },
  uploadFile: {
    id: 'fileMessages.uploadFile',
    defaultMessage: 'Ladda upp',
  },
  fileUploadTitle: {
    id: 'fileMessages.fileUploadTitle',
    defaultMessage: 'Dra och släpp fil här',
  },
});

export const fieldRichTextMessages = defineMessages({
  add: commonMessages.add,
  cancel: formMessages.cancel,
  undo: {
    id: 'fieldRichTextMessages.undo',
    description: 'Undo',
    defaultMessage: 'Ångra',
  },
  redo: {
    id: 'fieldRichTextMessages.redo',
    description: 'Redo',
    defaultMessage: 'Gör om',
  },
  removeFormatting: {
    id: 'fieldRichTextMessages.removeFormatting',
    description: 'Remove formatting',
    defaultMessage: 'Rensa formattering',
  },
  h1: {
    id: 'fieldRichTextMessages.h1',
    description: 'H1',
    defaultMessage: 'Rubrik 1',
  },
  h2: {
    id: 'fieldRichTextMessages.h2',
    description: 'H2',
    defaultMessage: 'Rubrik 2',
  },
  h3: {
    id: 'fieldRichTextMessages.h3',
    description: 'H3',
    defaultMessage: 'Rubrik 3',
  },
  h5: {
    id: 'fieldRichTextMessages.h5',
    description: 'H5',
    defaultMessage: 'Ingress',
  },
  blockquote: {
    id: 'fieldRichTextMessages.blockquote',
    description: 'Blockquote',
    defaultMessage: 'Blockcitat',
  },
  code: {
    id: 'fieldRichTextMessages.code',
    description: 'Code',
    defaultMessage: 'Kod',
  },
  blocktype: {
    id: 'fieldRichTextMessages.blocktype',
    description: 'Block Type',
    defaultMessage: 'Block',
  },
  normal: {
    id: 'fieldRichTextMessages.normal',
    description: 'Normal',
    defaultMessage: 'Normal',
  },
  bold: {
    id: 'fieldRichTextMessages.bold',
    description: 'Bold',
    defaultMessage: 'Fetstil',
  },
  italic: {
    id: 'fieldRichTextMessages.italic',
    description: 'Italic',
    defaultMessage: 'Kursiv',
  },
  underline: {
    id: 'fieldRichTextMessages.underline',
    description: 'Underline',
    defaultMessage: 'Understrykning',
  },
  superscript: {
    id: 'fieldRichTextMessages.superscript',
    description: 'Superscript',
    defaultMessage: 'Upphöjt',
  },
  subscript: {
    id: 'fieldRichTextMessages.subscript',
    description: 'Subscript',
    defaultMessage: 'Nedsänkt',
  },
  linkTitle: {
    id: 'fieldRichTextMessages.linkTitle',
    description: 'Link Title',
    defaultMessage: 'Rubrik',
  },
  linkTarget: {
    id: 'fieldRichTextMessages.linkTarget',
    description: 'Link Target',
    defaultMessage: 'Adress',
  },
  linkTargetOption: {
    id: 'fieldRichTextMessages.linkTargetOption',
    description: 'Open link in new window',
    defaultMessage: 'Öppna i nytt fönster',
  },
  link: {
    id: 'fieldRichTextMessages.link',
    description: 'link',
    defaultMessage: 'Länk',
  },
  unlink: {
    id: 'fieldRichTextMessages.unlink',
    description: 'Unlink',
    defaultMessage: 'Ta bort länk',
  },
  unordered: {
    id: 'fieldRichTextMessages.unordered',
    description: 'Unordered',
    defaultMessage: 'Osorterad lista',
  },
  ordered: {
    id: 'fieldRichTextMessages.ordered',
    description: 'Ordered',
    defaultMessage: 'Sorterad lista',
  },
  embedIframe: {
    id: 'fieldRichTextMessages.embedIframe',
    description: 'Embed iframe',
    defaultMessage: 'Bädda in innehåll',
  },
  embedHeight: {
    id: 'fieldRichTextMessages.embedHeight',
    defaultMessage: 'Höjd',
  },
  embedWidth: {
    id: 'fieldRichTextMessages.embedWidth',
    defaultMessage: 'Bredd',
  },
});

import {
  APP_BAR_HEIGHT,
  Icon,
  IconButton,
  media,
  Menu,
  MENU_ITEM_HEIGHT,
  MenuSection,
  MenuSurfaceAnchor,
  NAVIGATION_BAR_HEIGHT,
} from '@frontend/ui';
import { logout, manageAccountsFilled } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { MenuItem } from 'components/MenuItem';
import React, { useId, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { messages } from '../messages';

export const FEATURE_PREVIEW_SETTINGS_PARENT_ID =
  'feature-preview-settings-parent-id';

const StyledMenu = styled(Menu)`
  margin-top: 3.5rem;
  width: 17.5rem;

  // The max height of the menu mustn't span beyond the height
  // of the viewport. We subtract margins for the height of the
  // App bar and one default menu item.
  max-height: calc(
    100vh - (${APP_BAR_HEIGHT}px + ${MENU_ITEM_HEIGHT.default}rem)
  ) !important;

  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${media.lessThan('tablet')`
  // For tablet devices we ensure that the user menu
  // doesn't overlap the navigation bar.
  max-height: calc(
    100vh - (${APP_BAR_HEIGHT}px + ${NAVIGATION_BAR_HEIGHT}rem + 1rem)
  ) !important;
`}
`;

export const UserMenu: React.FC = () => {
  const { pathname } = useLocation();
  const [isOpen, toggleIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const id = useId();

  return (
    <MenuSurfaceAnchor>
      <StyledMenu
        open={isOpen}
        onClose={() => {
          toggleIsOpen(false);
        }}
        label={formatMessage(messages.userMenu)}
        id={id}
      >
        <MenuSection>
          <MenuItem
            icon={<Icon icon={logout} size="medium" decorative />}
            link={{
              pathname: '/logout',
              state: {
                from: pathname,
                message: 'goodbye',
              },
            }}
            title={<FormattedMessage {...messages.logout} />}
          />
        </MenuSection>
      </StyledMenu>
      <IconButton
        id={FEATURE_PREVIEW_SETTINGS_PARENT_ID}
        icon={manageAccountsFilled}
        label={formatMessage(commonMessages.openMenu)}
        onClick={() => {
          toggleIsOpen(_isOpen => !_isOpen);
        }}
        aria-haspopup="menu"
        aria-controls={id}
        aria-expanded={isOpen}
      />
    </MenuSurfaceAnchor>
  );
};

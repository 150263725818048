import { Icon, NotificationCardLayout } from '@frontend/ui';
import { clear, restartAlt } from '@frontend/ui/icons';
import { FormattedMessage, useIntl } from 'components/formats';
import { KebabMenu } from 'components/KebabMenu';
import { MenuItem } from 'components/MenuItem';
import React from 'react';

import { OpenStatus } from '..';
import { notificationFooterMessages, notificationMessages } from '../messages';
import { Notification } from './NotificationsList';

interface Props {
  notification: Notification;
}

export const NotificationCard: React.FC<Props> = ({
  notification: {
    title,
    status,
    statusUpdatedAt,
    author,
    createdAt,
    onClick,
    description,
  },
}) => {
  const { formatMessage } = useIntl();

  return (
    <NotificationCardLayout
      title={title}
      footer={formatMessage({
        select: status,
        messages: notificationFooterMessages,
        values: {
          statusUpdatedAt,
          author,
          createdAt,
        },
      })}
      overflowMenu={
        <KebabMenu inClickableArea fixed>
          <MenuItem
            onClick={e => {
              /**
               * As the notification card is a link we need to call event.preventDefault()
               * to prevent the user agent from navigating away from the page when the
               * dismiss/reopen menu item is clicked.
               */
              e.preventDefault();
              onClick();
            }}
            title={
              <FormattedMessage
                {...(status === OpenStatus.DISMISSED
                  ? notificationMessages.reopen
                  : notificationMessages.dismiss)}
              />
            }
            icon={
              status === OpenStatus.DISMISSED ? (
                <Icon decorative icon={restartAlt} />
              ) : (
                <Icon decorative icon={clear} />
              )
            }
          />
        </KebabMenu>
      }
    >
      {description}
    </NotificationCardLayout>
  );
};

import { Icon, Text } from '@frontend/ui';
import { addPhotoAlternative } from '@frontend/ui/icons';
import { commonBenefitMessages } from 'app/messages/benefits';
import { fileMessages } from 'app/messages/common';
import { Dropzone } from 'components/Dropzone';
import { FormattedMessage } from 'components/formats';
import { Preview } from 'components/ImageUpload';
import React from 'react';
import styled from 'styled-components';

const ImagePlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ImagePreview = styled(Preview)`
  height: 8.25rem;
`;

interface Props {
  onDropAccepted: (files?: File[]) => void;
  disabled?: boolean;
  previewUrl?: string;
}

export const ImageUpload: React.FC<Props> = ({
  onDropAccepted,
  previewUrl,
  disabled,
}) => (
  <Dropzone
    multiple={false}
    label={<FormattedMessage {...fileMessages.uploadFile} />}
    accept={{
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/svg+xml': ['.svg'],
    }}
    onDropAccepted={onDropAccepted}
    disabled={disabled}
  >
    <ImagePreview imageUrl={previewUrl} disabled={disabled}>
      {!previewUrl && (
        <ImagePlaceholder>
          <Icon
            color="primary30"
            size="large"
            icon={addPhotoAlternative}
            decorative
          />
          <Text use="bodyMedium" style={{ marginTop: '1.375rem' }}>
            <FormattedMessage
              {...commonBenefitMessages.coverImagePlaceholder}
            />
          </Text>
        </ImagePlaceholder>
      )}
    </ImagePreview>
  </Dropzone>
);

import { formatExternalUrl } from '@frontend/utils';
import { discountPresentationQuery_benefit_FlexBenefit as BenefitPresentation } from 'app/apollo/graphql/types';
import { validationMessages } from 'app/messages/common';
import { FormattedMessage, IntlShape } from 'components/formats';
import { discountMessages } from 'features/companies/company/discounts/messages';
import React from 'react';

import { BenefitFormValues } from '../../form';
import { ModalFieldProps } from './';

type BenefitType = BenefitPresentation | BenefitFormValues;

export const getTitleModalFields = (
  intl: IntlShape,
  benefit: BenefitType,
): ModalFieldProps => ({
  onConfirm: (fields, context) => {
    fields.forEach(field => {
      context.setFieldValue(`content.${field.name}`, field.value);
    });
  },
  title: <FormattedMessage {...discountMessages.editTitle} />,
  fields: [
    {
      name: 'title',
      label: discountMessages.title,
      value: benefit.content.title,
      validate: value =>
        value
          ? undefined
          : intl.formatMessage(validationMessages.mandatoryField),
    },
    {
      name: 'short',
      label: discountMessages.short,
      value: benefit.content.short,
      validate: value =>
        value
          ? undefined
          : intl.formatMessage(validationMessages.mandatoryField),
    },
  ],
});

const primaryCtaTitle = 'primaryCtaTitle';
const primaryCtaLink = 'primaryCtaLink';
const secondaryCtaTitle = 'secondaryCtaTitle';
const secondaryCtaLink = 'secondaryCtaLink';

export const getCtaModalFields = (benefit: BenefitType): ModalFieldProps => {
  const primaryCta = benefit.content.callToAction?.find(
    cta => cta.type === 'primary',
  );
  const secondaryCta = benefit.content.callToAction?.find(
    cta => cta.type === 'secondary',
  );

  return {
    onConfirm: (fields, context) => {
      const primary = {
        title: fields.find(({ name }) => name === primaryCtaTitle)?.value,
        link: fields.find(({ name }) => name === primaryCtaLink)?.value,
        type: 'primary',
      };

      const secondary = {
        title: fields.find(({ name }) => name === secondaryCtaTitle)?.value,
        link: fields.find(({ name }) => name === secondaryCtaLink)?.value,
        type: 'secondary',
      };

      const isNullOrEmptyString = (
        str?: string | null,
      ): str is null | undefined | '' => str == null || str.length === 0;

      const callToAction = [primary, secondary].reduce((acc, cta) => {
        const title = cta.title?.trim();
        const link = cta.link?.trim();
        return !isNullOrEmptyString(title) && !isNullOrEmptyString(link)
          ? [...acc, { ...cta, title, link: formatExternalUrl(link) }]
          : acc;
      }, []);

      context.setFieldValue('content.callToAction', callToAction);
    },
    fields: [
      {
        name: primaryCtaTitle,
        label: discountMessages.primaryCta,
        value: primaryCta?.title,
      },
      {
        name: primaryCtaLink,
        label: discountMessages.linkAddress,
        value: primaryCta?.link,
      },
      {
        name: secondaryCtaTitle,
        label: discountMessages.secondaryCta,
        value: secondaryCta?.title,
      },
      {
        name: secondaryCtaLink,
        label: discountMessages.linkAddress,
        value: secondaryCta?.link,
      },
    ],
    title: <FormattedMessage {...discountMessages.editLinks} />,
  };
};

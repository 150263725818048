import { JobOfferStatus } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { totalCompensationMessages } from 'app/messages/total-compensation';
import { ValidAgreementTypeNames } from 'app/utils/constants';
import { defineMessages } from 'react-intl';

export const jobOfferMessages = defineMessages({
  job: {
    id: 'jobOfferMessages.job',
    defaultMessage: 'Jobb',
  },
  jobOffer: {
    id: 'jobOfferMessages.jobOffer',
    defaultMessage: 'Jobberbjudande',
  },
  jobOfferTitle: {
    id: 'jobOfferMessages.jobOfferTitle',
    defaultMessage: 'Jobberbjudanden',
  },
  jobOfferDescr: {
    id: 'jobOfferMessages.jobOfferDescr',
    defaultMessage: `Med jobberbjudanden är det enkelt att kommunicera total ersättning och förmåner till potentiella kandidater. Fyll i detaljerna och dela.`,
  },
  newJobOffer: {
    id: 'jobOfferMessages.newJobOffer',
    defaultMessage: 'Nytt jobberbjudande',
  },
  createJobOffer: {
    id: 'jobOfferMessages.createJobOffer',
    defaultMessage: 'Skapa jobberbjudande',
  },
  updateJobOffer: {
    id: 'jobOfferMessages.updateJobOffer',
    defaultMessage: 'Uppdatera jobberbjudande',
  },
  monthlySalary: {
    id: 'jobOfferMessages.monthlySalary',
    defaultMessage: 'Månadslön (kr/mån)',
  },
  wellnessBenefit: {
    id: 'jobOfferMessages.wellnessBenefit',
    defaultMessage: 'Friskvårdsbidrag (kr/år)',
  },
  yearlyVacationDays: {
    id: 'jobOfferMessages.yearlyVacationDays',
    defaultMessage: 'Semester (dagar/år)',
  },
  optionalBenefits: {
    id: 'jobOfferMessages.optionalBenefits',
    defaultMessage: 'Andra förmåner',
  },
  lunchAllowance: {
    id: 'jobOfferMessages.lunchAllowance',
    defaultMessage: 'Lunchförmån (kr/mån)',
  },
  companyCar: {
    id: 'jobOfferMessages.companyCar',
    defaultMessage: 'Företagsbil (kr/mån)',
  },
  discounts: {
    id: 'jobOfferMessages.discounts',
    defaultMessage: 'Erbjudanden',
  },
  writeProspectDetails: {
    id: 'jobOfferMessages.writeProspectDetails',
    defaultMessage: 'Beskrivning av jobberbjudandet',
  },
  backToOffer: {
    id: 'jobOfferMessages.backToOffer',
    defaultMessage: 'Tillbaka till erbjudandet',
  },
  noJobOffer: {
    id: 'jobOfferMessages.noJobOffer',
    defaultMessage: 'Det finns inga jobberbjudanden',
  },
  publicNotification: {
    id: 'jobOfferMessages.publicNotification',
    defaultMessage:
      'Erbjudandet är tillgängligt för delning. För att dela erbjudandet, skicka länken till din kandidat. {link}',
  },
  privateNotification: {
    id: 'jobOfferMessages.privateNotification',
    defaultMessage:
      'Erbjudandet är endast synligt för dig tills du väljer att publicera det. Du kan se det på denna adress: {link}',
  },
  expiredNotification: {
    id: 'jobOfferMessages.expiredNotification',
    defaultMessage:
      'Erbjudandets giltighetstid har passerat. För att göra erbjudandet synligt igen, sätt ett nytt datum.',
  },
  hideOffer: {
    id: 'jobOfferMessages.hideOffer',
    defaultMessage: 'Dölj erbjudandet',
  },
  copyLink: {
    id: 'jobOfferMessages.copyLink',
    defaultMessage: 'Kopiera länk',
  },
  copied: {
    id: 'jobOfferMessages.copied',
    defaultMessage: 'Kopierad!',
  },
  showOffer: {
    id: 'jobOfferMessages.showOffer',
    defaultMessage: 'Publicera erbjudandet',
  },
  deleteSubmissionError: {
    id: 'jobOfferMessages.deleteSubmissionError',
    defaultMessage: 'Det gick inte att  ta bort jobberbjudandet. Försök igen.',
  },
  created: {
    id: 'jobOfferMessages.created',
    defaultMessage: 'Jobberbjudandet har skapats',
  },
  updated: {
    id: 'jobOfferMessages.updated',
    defaultMessage: 'Jobberbjudandet har uppdaterats',
  },
  deleted: {
    id: 'jobOfferMessages.deleted',
    defaultMessage: 'Jobberbjudandet har tagits bort',
  },
  setNewValidDate: {
    id: 'jobOfferMessages.setNewValidDate',
    defaultMessage: 'Sätt ny giltighetstid',
  },
  totalCompensationAtCompany: {
    id: 'jobOfferMessages.totalCompensationAtCompany',
    defaultMessage: 'Din totala ersättning hos {companyName}',
  },
  confirmRemoveJobOffer: {
    id: 'jobOfferMessages.confirmRemoveJobOffer',
    defaultMessage: 'Ta bort jobberbjudandet?',
  },
  confirmRemoveJobOfferDescription: {
    id: 'jobOfferMessages.confirmRemoveJobOfferDescription',
    defaultMessage:
      'Jobberbjudandet kommer att tas bort och eventuella länkar till det kommer sluta fungera.',
  },
  yourBenefitsAtCompanyName: {
    id: 'jobOfferMessages.yourBenefitsAtCompanyName',
    defaultMessage: 'Dina förmåner hos {companyName}',
  },
  greeting: {
    id: 'jobOfferMessages.greeting',
    defaultMessage: 'Hej {name}!',
  },
  incentiveProgram: {
    id: 'jobOfferMessages.incentiveProgram',
    defaultMessage: 'Incitamentsprogram',
  },
  aboutTheRoleAtCompanyName: {
    id: 'jobOfferMessages.aboutTheRoleAtCompanyName',
    defaultMessage: 'Om rollen hos {companyName}',
  },
});

export const jobOfferStatusMessages = defineMessages<JobOfferStatus>({
  PRIVATE: {
    id: 'jobOfferStatusMessages.private',
    defaultMessage: 'Ej aktivt',
  },
  PUBLIC: {
    id: 'jobOfferStatusMessages.public',
    defaultMessage: 'Aktivt',
  },
  EXPIRED: {
    id: 'jobOfferStatusMessages.expired',
    defaultMessage: 'Ej aktivt',
  },
});

export const jobOfferFormMessages = defineMessages({
  info: {
    id: 'jobOfferFormMessages.info',
    defaultMessage: 'Information',
  },
  prospect: {
    id: 'jobOfferFormMessages.prospect',
    defaultMessage: 'Kandidatens namn',
  },
  position: {
    id: 'jobOfferFormMessages.position',
    defaultMessage: 'Jobbtitel',
  },
  city: {
    id: 'jobOfferFormMessages.city',
    defaultMessage: 'Primär arbetsort',
  },
  cityHelper: {
    id: 'jobOfferFormMessages.cityHelper',
    defaultMessage: 'T.ex. Stockholm, Malmö eller Göteborg.',
  },
  location: {
    id: 'jobOfferFormMessages.location',
    defaultMessage: 'Platsbeskrivning',
  },
  locationHelper: {
    id: 'jobOfferFormMessages.locationHelper',
    defaultMessage: 'På plats, Hybrid eller Distans.',
  },
  body: {
    id: 'jobOfferFormMessages.body',
    defaultMessage: 'Jobbeskrivning',
  },
  birthDate: {
    id: 'jobOfferFormMessages.birthDate',
    defaultMessage: 'Födelsedatum',
  },
  addAvatar: {
    id: 'jobOfferFormMessages.addAvatar',
    defaultMessage: 'Välj profilbild',
  },
  editAvatar: {
    id: 'jobOfferFormMessages.editAvatar',
    defaultMessage: 'Ändra profilbild',
  },
  addCover: {
    id: 'jobOfferFormMessages.addCover',
    defaultMessage: 'Välj omslagsbild',
  },
  editCover: {
    id: 'jobOfferFormMessages.editCover',
    defaultMessage: 'Ändra omslagsbild',
  },
  monthlySalary: commonMessages.monthlySalary,
  remunerationAdd: {
    id: 'jobOfferFormMessages.remunerationAdd',
    defaultMessage: 'Ange fler lönearter',
  },
  wellness: totalCompensationMessages.fitnessContribution,
  vacation: totalCompensationMessages.vacation,
  benefitPackage: commonMessages.benefitPackage,
  collectiveAgreement: commonMessages.collectiveAgreement,
  lunch: totalCompensationMessages.lunchAllowance,
  companyCar: totalCompensationMessages.companyCar,
  showDiscounts: {
    id: 'jobOfferFormMessages.showDiscounts',
    defaultMessage: 'Visa erbjudanden och rabatter',
  },
  showDiscountsHelper: {
    id: 'jobOfferFormMessages.showDiscountsHelper',
    defaultMessage:
      'Om kandidaten omfattas av Nordea Nodes förmånsportal med erbjudanden och rabatter kan du välja att visa även dessa i jobberbjudandet.',
  },
  incentive: {
    id: 'jobOfferFormMessages.incentive',
    defaultMessage: 'Omfattas av incitamentsprogram',
  },
  incentiveHelper: {
    id: 'jobOfferFormMessages.incentiveHelper',
    defaultMessage:
      'Här kan du ange om kandidaten omfattas av ett incitamentsprogram. Om rutan inte är markerad visas inte sektionen för incitamentprogram i erbjudandet. Du kan beskriva incitamentsprogrammet i textfältet nedan och bifoga filer innehållande mer information.',
  },
  validity: {
    id: 'jobOfferFormMessages.validity',
    defaultMessage: 'Välj giltighetstid',
  },
  untilFurtherNotice: {
    id: 'jobOfferFormMessages.untilFurtherNotice',
    defaultMessage: 'Tills vidare',
  },
  until: {
    id: 'jobOfferFormMessages.until',
    defaultMessage: 'Till och med',
  },
  untilHelperText: {
    id: 'jobOfferFormMessages.untilHelperText',
    defaultMessage: 'Giltig t.o.m.',
  },
  visible: {
    id: 'jobOfferFormMessages.visible',
    defaultMessage: 'Synlighet',
  },
  visibleHelperText: {
    id: 'jobOfferFormMessages.visibleHelperText',
    defaultMessage:
      'Om erbjudandet är synligt går det att komma åt med en länk utan att vara inloggad.',
  },
  attachFiles: {
    id: 'jobOfferFormMessages.attachFiles',
    defaultMessage: 'Bifoga filer',
  },
  selectFiles: {
    id: 'jobOfferFormMessages.selectFiles',
    defaultMessage: 'Välj filer',
  },
  compensation: commonMessages.compensation,
  benefits: commonMessages.benefits,
  description: {
    id: 'jobOfferFormMessages.description',
    defaultMessage: 'Beskrivning',
  },
  settings: {
    id: 'jobOfferFormMessages.settings',
    defaultMessage: 'Inställningar',
  },
  validityPeriod: {
    id: 'jobOfferFormMessages.validityPeriod',
    defaultMessage: 'Giltighetsperiod',
  },
});

export const collectiveAgreementMessages = defineMessages<
  ValidAgreementTypeNames | 'null'
>({
  null: {
    id: 'collectiveAgreementMessages.null',
    defaultMessage: `Saknar kollektivavtal`,
  },
  ITP1: {
    id: 'collectiveAgreementMessages.ITP1',
    defaultMessage: `Privatanställd tjänsteman (ITP 1)`,
  },
  ITP: {
    id: 'collectiveAgreementMessages.ITP',
    defaultMessage: `Privatanställd tjänsteman (ITP 2)`,
  },
  SAFLO: {
    id: 'collectiveAgreementMessages.SAFLO',
    defaultMessage: `Privatanställd arbetare (SAF-LO)`,
  },
  AKAPKL: {
    id: 'collectiveAgreementMessages.AKAPKL',
    defaultMessage: `Kommunal- & regionanställd född 1986 eller senare (AKAP-KL)`,
  },
  AKAPKR: {
    id: 'collectiveAgreementMessages.AKAPKR',
    defaultMessage: `Kommunal- & regionanställd (AKAP-KR)`,
  },
  KAPKL: {
    id: 'collectiveAgreementMessages.KAPKL',
    defaultMessage: `Kommunal- & regionanställd född 1985 eller tidigare (KAP-KL)`,
  },
  PA161: {
    id: 'collectiveAgreementMessages.PA161',
    defaultMessage: `Statligt anställd född 1988 eller senare (PA 16 avdelning 1)`,
  },
  PA162: {
    id: 'collectiveAgreementMessages.PA162',
    defaultMessage: `Statligt anställd född 1987 eller tidigare (PA 16 avdelning 2)`,
  },
  PAKFS09: {
    id: 'collectiveAgreementMessages.PAKFS09',
    defaultMessage: `Anställd i kommunalt bolag (PA-KFS 09)`,
  },
});

import { pageInfoDetails } from 'app/fragments';
import gql from 'graphql-tag';

export const BENEFIT_PACKAGE_MEMBERSHIPS_QUERY = gql`
  query benefitPackageMembershipsQuery(
    $benefitPackageId: ID!
    $first: Int
    $before: String
    $last: Int
    $after: String
    $search: String
    $includeTerminated: Boolean
  ) {
    benefitPackage(id: $benefitPackageId) {
      id
      name
      memberships(
        first: $first
        before: $before
        last: $last
        after: $after
        search: $search
        includeTerminated: $includeTerminated
      ) {
        edges {
          node {
            id
            employment {
              from
              status {
                from
                to
                type {
                  id
                }
              }
              to
            }
            membershipBenefitPackage {
              benefitPackage {
                id
                name
              }
            }
            monthlySalary
            userAccountId
            givenName
            lastName
            naturalPersonIdentifiers
          }
        }
        pageInfo {
          ...pageInfoDetails
        }
      }
    }
  }
  ${pageInfoDetails}
`;

import { singlePremiumDepositHistoryTableQuery } from 'app/apollo/graphql/types';

export const updateQuery = (
  previousResult: singlePremiumDepositHistoryTableQuery,
  {
    fetchMoreResult,
  }: {
    fetchMoreResult?: singlePremiumDepositHistoryTableQuery;
  },
) => {
  if (
    !previousResult?.membership?.singlePremiums ||
    !fetchMoreResult?.membership?.singlePremiums
  ) {
    return previousResult;
  }

  const { edges, pageInfo } = fetchMoreResult.membership.singlePremiums;

  return {
    membership: {
      ...previousResult.membership,
      singlePremiums: {
        __typename: previousResult.membership.singlePremiums.__typename,
        edges,
        pageInfo,
      },
    },
  };
};

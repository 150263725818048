import {
  A,
  bodyLargeCSS,
  bodyMediumCSS,
  Button,
  ButtonLayout,
  Card,
  CardContent,
  ellipsisCSS,
  media,
  SelectField,
  Separator,
  TextField,
} from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { EXTERNAL_URLS } from 'app/utils/external-urls';
import { FormattedCurrency, FormattedMessage } from 'components/formats';
import React from 'react';
import styled from 'styled-components';

import { movieTicketMessages } from './messages';

const TermsHref = (chunk: React.ReactNode) => (
  <A href={EXTERNAL_URLS.nordeaNodeMovieTicketAgreement} target="_blank">
    {chunk}
  </A>
);

const StyledCard = styled(Card)`
  margin-bottom: 1.5rem;
`;

export interface FormValues {
  email: string;
  quantity: string;
}

const FieldsSection = styled.div`
  margin-bottom: 1rem;
`;

const QuantitySection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;

  ${media.lessThan('tablet')`
    flex-direction: column-reverse;
    align-items: flex-start;
  `}
`;

const StyledSelectField = styled(SelectField)`
  width: 5rem;
  margin-right: 1rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.lessThan('tablet')`
    margin-bottom: 1.25rem;
  `}
`;

const Title = styled.div`
  ${bodyLargeCSS}
  ${ellipsisCSS}
`;

const Description = styled.div`
  color: ${p => p.theme.emphasisMedium};
  ${bodyMediumCSS}
  ${ellipsisCSS}
`;

const EmailSection = styled.div`
  max-width: 30rem;

  ${media.lessThan('tablet')`
    max-width: 100%;
  `}
`;

const SummarySection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
`;

const Label = styled.div`
  ${bodyLargeCSS}
  line-height: 3rem;
  ${ellipsisCSS}
`;

const Value = styled.div`
  color: ${p => p.theme.emphasisMedium};
  ${bodyMediumCSS}
  line-height: 3rem;
  ${ellipsisCSS}
`;

const SubmitSection = styled.div`
  margin-top: 1rem;
`;

const Terms = styled.div`
  margin: 1rem 0;
`;

export const MovieTicketForm: React.FC = () => (
  <>
    <h1>
      <FormattedMessage {...movieTicketMessages.formTitle} />
    </h1>
    <StyledCard style={{ overflow: 'visible', marginTop: '1rem' }}>
      <CardContent>
        <FieldsSection>
          <QuantitySection>
            <StyledSelectField
              dense
              label={<FormattedMessage {...commonMessages.quantity} />}
              name="quantity"
              disabled
              options={[{ label: 0, value: 0 }]}
              required
              value={0}
            />
            <Header>
              <Title>
                <FormattedMessage {...movieTicketMessages.cardTitle} />
              </Title>
              <Description>
                <FormattedMessage
                  {...movieTicketMessages.cardDescription}
                  values={{
                    amount: 0,
                  }}
                />
              </Description>
            </Header>
          </QuantitySection>
          <EmailSection>
            <TextField
              name="email"
              type="email"
              label={<FormattedMessage {...commonMessages.email} />}
              helperText={
                <FormattedMessage
                  {...movieTicketMessages.emailFieldHelperText}
                />
              }
              dense
              disabled
              required
            />
          </EmailSection>
        </FieldsSection>
        <Separator />
        <SummarySection>
          <Label>
            <FormattedMessage {...movieTicketMessages.totalAmountLabel} />
          </Label>
          <Value>
            <FormattedCurrency currency="SEK" value={0} />
          </Value>
        </SummarySection>
        <Separator />
        <SubmitSection>
          <Terms>
            <FormattedMessage
              {...movieTicketMessages.confirmMessage}
              values={{
                termsLink: TermsHref,
              }}
            />
          </Terms>
          <ButtonLayout>
            <Button type="submit" filled disabled>
              <FormattedMessage {...movieTicketMessages.buyButton} />
            </Button>
          </ButtonLayout>
        </SubmitSection>
      </CardContent>
    </StyledCard>
  </>
);

import { ApolloError } from '@apollo/client';
import {
  CheckboxField,
  Form,
  RadioGroupField,
  SelectField,
  TextField,
  useFormikContext,
} from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Grid,
  Section,
  SelectOption,
  Subsection,
} from '@frontend/ui';
import {
  LegalEntityType,
  PaymentMethod,
  smeEditCompanyQuery_nlpSalesReps_AdminUser as AdminUser,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { GridCell50, TextGrid } from 'components/GridCell';
import { SelectClearingField } from 'features/companies/company/select-clearing';
import { companyLegalEntityTypeMessages } from 'features/companies/messages';
import { HasCompanyGroupType } from 'features/sme/create';
import {
  smeAccountNumberMessages,
  smeCompanyMessages,
  smePaymentMethodMessages,
} from 'features/sme/messages/sme';
import React from 'react';
import { getSelectComponentMonthOptions } from 'utils/get-select-month-options';

import { FormValues } from '..';

interface Props {
  salesReps: readonly AdminUser[];
  submissionError?: ApolloError;
}

export const EditCompanyForm: React.FC<Props> = ({
  salesReps,
  submissionError,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { values, dirty, isSubmitting, isValid } =
    useFormikContext<FormValues>();

  const monthOptions = getSelectComponentMonthOptions({ intl });

  const legalEntityTypeOptions: SelectOption[] = Object.keys(
    LegalEntityType,
  ).map(legalEntity => ({
    value: legalEntity,
    label: formatMessage({
      select: legalEntity,
      messages: companyLegalEntityTypeMessages,
    }),
  }));

  const salesRepOptions = salesReps.map(
    ({ id, firstName, lastName, externalId }) => ({
      label: `${firstName} ${lastName} (${externalId})`,
      value: id,
    }),
  );

  return (
    <Form>
      <TextGrid>
        <Section>
          <Subsection>
            <TextField
              name="registrationNumber"
              dense
              disabled
              label={formatMessage(smeCompanyMessages.registrationNumber)}
            />
            <SelectField
              name="salesRep"
              dense
              required
              options={salesRepOptions}
              label={formatMessage(smeCompanyMessages.salesRep)}
            />
            <RadioGroupField
              name="hasCompanyGroup"
              required
              options={[
                {
                  label: formatMessage(commonMessages.yes),
                  value: HasCompanyGroupType.YES,
                },
                {
                  label: formatMessage(commonMessages.no),
                  value: HasCompanyGroupType.NO,
                },
              ]}
              label={formatMessage(smeCompanyMessages.partOfGroupLabel)}
            />
            <TextField
              name="companyName"
              dense
              disabled
              required
              label={formatMessage(smeCompanyMessages.companyName)}
            />
            <SelectField
              name="legalEntityType"
              dense
              disabled
              options={legalEntityTypeOptions}
              label={formatMessage(smeCompanyMessages.legalEntityType)}
            />
            <TextField
              name="address"
              dense
              disabled
              label={formatMessage(smeCompanyMessages.companyAddress)}
            />
            <Grid>
              <GridCell50>
                <TextField
                  name="postalCode"
                  dense
                  disabled
                  label={formatMessage(commonMessages.postalCode)}
                />
              </GridCell50>
              <GridCell50>
                <TextField
                  name="city"
                  dense
                  disabled
                  label={formatMessage(commonMessages.city)}
                />
              </GridCell50>
            </Grid>
            <Grid>
              <GridCell50>
                <TextField
                  name="adminFirstName"
                  dense
                  required
                  label={formatMessage(smeCompanyMessages.adminFirstName)}
                />
              </GridCell50>
              <GridCell50>
                <TextField
                  name="adminLastName"
                  dense
                  required
                  label={formatMessage(smeCompanyMessages.adminLastName)}
                />
              </GridCell50>
            </Grid>
            <TextField
              name="adminNaturalPersonIdentifier"
              dense
              required
              label={formatMessage(smeCompanyMessages.adminPid)}
            />
            <TextField
              name="adminEmail"
              dense
              required
              label={formatMessage(smeCompanyMessages.adminEmail)}
              type="email"
            />
            <TextField
              name="adminPhone"
              dense
              required
              label={formatMessage(smeCompanyMessages.adminPhone)}
              type="tel"
            />
            <CheckboxField
              name="separateContactAndAdmin"
              label={formatMessage(smeCompanyMessages.separateContactAndAdmin)}
            />
            {values.separateContactAndAdmin && (
              <>
                <Grid>
                  <GridCell50>
                    <TextField
                      name="contactFirstName"
                      dense
                      required
                      label={formatMessage(smeCompanyMessages.contactFirstName)}
                    />
                  </GridCell50>
                  <GridCell50>
                    <TextField
                      name="contactLastName"
                      dense
                      required
                      label={formatMessage(smeCompanyMessages.contactLastName)}
                    />
                  </GridCell50>
                </Grid>
                <TextField
                  name="contactNaturalPersonIdentifier"
                  dense
                  label={formatMessage(smeCompanyMessages.contactPid)}
                />
                <TextField
                  name="contactEmail"
                  dense
                  required
                  label={formatMessage(smeCompanyMessages.contactEmail)}
                  type="email"
                />
                <TextField
                  name="contactPhone"
                  dense
                  required
                  label={formatMessage(smeCompanyMessages.contactPhone)}
                  type="tel"
                />
              </>
            )}
            <SelectClearingField name="clearingSalesOffice" />
            <SelectField
              name="accountClosureMonth"
              dense
              options={monthOptions}
              required
              label={formatMessage(smeCompanyMessages.accountClosureMonth)}
            />
            <SelectField
              name="salaryReviewMonth"
              dense
              options={monthOptions}
              required
              label={formatMessage(smeCompanyMessages.salaryReviewMonth)}
            />
            <SelectField
              dense
              label={<FormattedMessage {...smeCompanyMessages.paymentMethod} />}
              name="paymentMethod"
              options={Object.values(PaymentMethod).map(value => ({
                label: formatMessage({
                  messages: smePaymentMethodMessages,
                  select: value,
                }),
                value,
              }))}
            />
            {values.paymentMethod &&
              values.paymentMethod !== PaymentMethod.INVOICE && (
                <TextField
                  dense
                  label={
                    <FormattedMessage
                      select={values.paymentMethod}
                      messages={smeAccountNumberMessages}
                    />
                  }
                  name="accountNumber"
                />
              )}
          </Subsection>
          {submissionError && <GraphQlError error={submissionError} />}
          <ButtonLayout>
            <Button
              filled
              type="submit"
              disabled={!isValid || !dirty}
              loading={isSubmitting}
            >
              <FormattedMessage {...commonMessages.updateInformationDetails} />
            </Button>
          </ButtonLayout>
        </Section>
      </TextGrid>
    </Form>
  );
};

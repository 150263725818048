import {
  Button,
  ButtonLayout,
  ModalFooter,
  ModalSubheader,
} from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import {
  smeEmployeesMessages,
  smeOwnerDescriptionMessages,
} from 'features/sme/messages/sme';
import React from 'react';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const OwnerDescriptionModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
}) => (
  <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
    <ModalHeader>
      <FormattedMessage {...smeEmployeesMessages.isOwner} />
    </ModalHeader>
    <ModalBody>
      <ModalSubheader>
        <FormattedMessage {...smeOwnerDescriptionMessages.jointStockCompany} />
      </ModalSubheader>
      <FormattedMessage
        {...smeOwnerDescriptionMessages.jointStockCompanyDescription}
      />
      <ModalSubheader>
        <FormattedMessage {...smeOwnerDescriptionMessages.generalPartnership} />
      </ModalSubheader>
      <FormattedMessage
        {...smeOwnerDescriptionMessages.generalPartnershipDescription}
      />
      <ModalSubheader>
        <FormattedMessage {...smeOwnerDescriptionMessages.limitedPartnership} />
      </ModalSubheader>
      <FormattedMessage
        {...smeOwnerDescriptionMessages.limitedPartnershipDescription}
      />
      <ModalSubheader>
        <FormattedMessage {...smeOwnerDescriptionMessages.proprietorship} />
      </ModalSubheader>
      <FormattedMessage
        {...smeOwnerDescriptionMessages.proprietorshipDescription}
      />
    </ModalBody>
    <ModalFooter>
      <ButtonLayout align="right">
        <Button text onClick={() => setIsOpen(false)}>
          <FormattedMessage {...commonMessages.cancel} />
        </Button>
      </ButtonLayout>
    </ModalFooter>
  </Modal>
);

import { NavigationAnchor } from 'contexts/navigation-anchor';
import { CreateDiscount } from 'features/companies/company/discounts/create';
import { discountMessages } from 'features/companies/company/discounts/messages';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MatchParams } from '../edit';

export const DiscountCreateRoute: React.FC = () => {
  const { url } = useRouteMatch<MatchParams>();

  return (
    <NavigationAnchor title={discountMessages.createDiscount} url={url}>
      <CreateDiscount />
    </NavigationAnchor>
  );
};

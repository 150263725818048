import {
  Button,
  Menu as _Menu,
  MenuSurfaceAnchor as _MenuSurfaceAnchor,
} from '@frontend/ui';
import { FormattedMessage, useIntl } from 'components/formats';
import { MenuItem } from 'components/MenuItem';
import {
  AtomicBlockUtils,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import { discountMessages } from 'features/companies/company/discounts/messages';
import React, { useState } from 'react';
import styled from 'styled-components';

import { EmbeddedComponentEntity } from '..';
import { EmbeddedComponentType } from '../types';

const Menu = styled(_Menu)`
  transform-origin: right top !important;
  top: 3.25rem !important;
  right: 0 !important;
  left: auto !important;
`;

const MenuSurfaceAnchor = styled(_MenuSurfaceAnchor)`
  margin-left: auto;
`;

/* prepareCursorPosition -
  This function is a total hack function which deals with terrible default draftJS behaviour
  Issue: When inserting a component before a header, the formatting of the header disappears.
  Solution: Move the cursor to the end of the previous block. If it's the first block, create a new one.
*/

const prepareCursorPosition = (editorState: EditorState) => {
  const currentSelection = editorState.getSelection();
  const hasCursorAtStartOfBlock = currentSelection.getStartOffset() === 0;
  if (!hasCursorAtStartOfBlock) {
    return editorState;
  }
  let _currentContent = editorState.getCurrentContent();
  let previousBlock = _currentContent.getBlockBefore(
    currentSelection.getAnchorKey(),
  );
  if (!previousBlock) {
    _currentContent = Modifier.splitBlock(_currentContent, currentSelection);
    // This looks suspicious and the linter borks. We should fix
    // eslint-disable-next-line
    editorState = EditorState.push(editorState, _currentContent, 'split-block');
    previousBlock = editorState.getCurrentContent().getFirstBlock();
  }
  if (previousBlock) {
    const endOfPreviousBlockSelection = new SelectionState({
      anchorKey: previousBlock.getKey(),
      anchorOffset: previousBlock?.getLength(),
      focusKey: previousBlock.getKey(),
      focusOffset: previousBlock?.getLength(),
      isBackward: false,
    });
    return EditorState.forceSelection(editorState, endOfPreviousBlockSelection);
  }
  return editorState; // We shouldn't end up here.
};

/* stripTrailingBlock -
  This function is a total hack function which deals with terrible default draftJS behaviour
  Issue: When removing space in DraftJS, a block is merged into the previous block
  even if the previous block is empty. Therefore, block style of headers may disappear when removing
  empty space before headers. AtomicBlockUtils.insertAtomicBlock adds a redudant empty block after each component
  which users are likely to try to remove which may trigger lost formatting. 
  Solution: To make this behaviour less obvious by removing the redundant block immediately. */

const stripTrailingBlock = (editorState: EditorState) => {
  const redudantBlock = editorState.getSelection();
  const afterKey = editorState
    .getCurrentContent()
    .getKeyAfter(redudantBlock.getAnchorKey());
  const afterBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(afterKey)
    .getType();
  const targetRange = new SelectionState({
    anchorKey: redudantBlock.getAnchorKey(),
    anchorOffset: 0,
    focusKey: afterKey,
    focusOffset: 0,
  });
  const contentState = Modifier.removeRange(
    Modifier.setBlockType(
      editorState.getCurrentContent(),
      targetRange,
      afterBlockType,
    ),
    targetRange,
    'forward',
  );
  return EditorState.push(editorState, contentState, 'remove-range');
};

interface CustomToolbarOptionProps {
  options: EmbeddedComponentType[];
  editorState?: EditorState;
  onChange?: (args: EditorState) => void;
}

interface Option {
  label: React.ReactNode;
  type: EmbeddedComponentType;
}

const embeddedComponentOptions: Option[] = [
  {
    label: <FormattedMessage {...discountMessages.discountCode} />,
    type: EmbeddedComponentType.CAMPAIGN_CODE,
  },
  {
    label: <FormattedMessage {...discountMessages.attachments} />,
    type: EmbeddedComponentType.ATTACHMENTS,
  },
];

export const ToolbarMenu: React.FC<CustomToolbarOptionProps> = ({
  editorState,
  onChange,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  if (!editorState || !onChange) {
    return null;
  }

  const addEmbeddedComponent = (option: Option) => {
    const _editorState = prepareCursorPosition(editorState);

    const content = _editorState.getCurrentContent();
    const contentWithEntity = content.createEntity(
      EmbeddedComponentEntity,
      'IMMUTABLE',
      {
        componentType: option.type,
      },
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      EditorState.set(_editorState, {
        currentContent: contentWithEntity,
      }),
      entityKey,
      ' ',
    );

    onChange(stripTrailingBlock(newEditorState));

    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const isAvailable = (availableOption: Option) =>
    options.find(providedOption => providedOption === availableOption.type);

  const availableOptions = embeddedComponentOptions.filter(isAvailable);

  return (
    <MenuSurfaceAnchor>
      <Button
        text
        onClick={() => setIsOpen(prevState => !prevState)}
        disabled={!availableOptions.length}
      >
        <FormattedMessage {...discountMessages.embeddEditorToolbarOption} />
      </Button>
      <Menu
        open={isOpen}
        style={{ transformOrigin: 'right top' }}
        onClose={() => setIsOpen(false)}
        label={formatMessage(discountMessages.embeddEditorToolbarOption)}
      >
        {/* XXX: Supported options should come from backend, right? */}
        {availableOptions.map(option => (
          <MenuItem
            key={option.type}
            onClick={() => addEmbeddedComponent(option)}
            noIcon
            title={option.label}
          />
        ))}
      </Menu>
    </MenuSurfaceAnchor>
  );
};

import { defineMessages } from 'react-intl';

export const menuMessages = defineMessages({
  home: {
    id: 'menuMessages.home',
    description: 'Hem',
    defaultMessage: 'Hem',
  },
  notifications: {
    id: 'menuMessages.notificatiosn',
    description: 'Notifications',
    defaultMessage: 'Notifikationer',
  },
  events: {
    id: 'menuMessages.events',
    description: 'Events',
    defaultMessage: 'Händelser',
  },
  logout: {
    id: 'menuMessages.logout',
    description: 'Logga ut',
    defaultMessage: 'Logga ut',
  },
  import: {
    id: 'menuMessages.import',
    defaultMessage: 'Importera',
  },
  overview: {
    id: 'menuMessages.overview',
    description: 'Overview',
    defaultMessage: 'Översikt',
  },
  pension: {
    id: 'menuMessages.pension',
    description: 'Pension',
    defaultMessage: 'Pension',
  },
  administrators: {
    id: 'menuMessages.administrators',
    description: 'Administrators',
    defaultMessage: 'Administratörer',
  },
  benefits: {
    id: 'menuMessages.benefits',
    defaultMessage: 'Förmåner',
  },
  organizationalUnits: {
    id: 'menuMessages.organizationalUnits',
    defaultMessage: 'Avdelningar',
  },
  costReporting: {
    id: 'menuMessages.costReporting',
    defaultMessage: 'Kostnadsrapportering',
  },
  billing: {
    id: 'menuMessages.billing',
    defaultMessage: 'Fakturering',
  },
  agreements: {
    id: 'menuMessages.agreements',
    defaultMessage: 'Avtal',
  },
  accountPlan: {
    id: 'menuMessages.accountPlan',
    defaultMessage: 'Kontoplan',
  },
  totalCompensation: {
    id: 'menuMessages.totalCompensation',
    defaultMessage: 'Lön och ersättning',
  },
  salaryExchange: {
    id: 'menuMessages.salaryExchange',
    defaultMessage: 'Löneväxling',
  },
  reports: {
    id: 'menuMessages.reports',
    defaultMessage: 'Rapporter',
  },
  employmentEvents: {
    id: 'menuMessages.employmentEvents',
    defaultMessage: 'Anställningshändelser',
  },
  insuranceEvents: {
    id: 'menuMessages.insuranceEvents',
    defaultMessage: 'Försäkringshändelser',
  },
  chooseLanguage: {
    id: 'menuMessages.chooseLanguage',
    defaultMessage: 'Välj språk',
  },
});

import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import {
  organizationalUnitQuery,
  organizationalUnitQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { TopLoading } from 'components/TopLoading';
import qs from 'query-string';
import React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { ORGANIZATIONAL_UNIT_QUERY } from '../graphql/queries';
import { orgUnitMessages } from '../messages';
import { useSubmit } from './utils/use-submit';

export const DeleteOrgUnitModal: React.FC = () => {
  const {
    params: { companyId },
  } = useRouteMatch<CompanyMatchParams>();
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const { delete: id } = qs.parse(search);
  const onRequestClose = () => push(pathname);

  const {
    submit,
    error: deleteError,
    loading: deleteLoading,
  } = useSubmit({
    id,
    companyId,
    onRequestClose,
  });

  const { data, loading, error } = useQuery<
    organizationalUnitQuery,
    organizationalUnitQueryVariables
  >(ORGANIZATIONAL_UNIT_QUERY, {
    errorPolicy: 'all',
    variables: { id },
    skip: !id,
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.organizationalUnit) {
    return null;
  }

  const memberships = data?.organizationalUnit?.memberships?.edges ?? [];
  const hasMemberships = memberships.length > 0;
  const name = data?.organizationalUnit?.name;

  return (
    <Modal isOpen onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...orgUnitMessages.deleteOrgUnit} />
      </ModalHeader>
      <ModalBody>
        {name && (
          <FormattedMessage
            {...orgUnitMessages.deleteOrgUnitConfirmation}
            values={{ name }}
          />
        )}
        {(error || deleteError) && (
          <GraphQlError inModal error={error ?? deleteError} />
        )}
        {hasMemberships && (
          <NotificationCard inModal type="warning">
            <FormattedMessage
              {...orgUnitMessages.deleteOrgUnitWithMemberships}
            />
          </NotificationCard>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={onRequestClose}>
            <FormattedMessage {...orgUnitMessages.cancel} />
          </Button>
          <Button
            disabled={hasMemberships}
            onClick={submit}
            danger
            text
            loading={deleteLoading}
          >
            <FormattedMessage {...orgUnitMessages.deleteOrgUnit} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};

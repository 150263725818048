import { Formik } from '@frontend/formik';
import { Button, PageLede, Section } from '@frontend/ui';
import {
  editJobOfferQuery,
  editJobOfferQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { JobOffersEditRouteMatchParams } from 'app/pages/companies/company/job-offers/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyState } from 'components/EmptyState';
import { FormattedMessage, useIntl } from 'components/formats';
import { TextGrid } from 'components/GridCell';
import { useConfirm } from 'contexts/confirmation';
import React from 'react';
import { useRouteMatch } from 'react-router';

import { MONTHLY_SALARY_REMUNERATION_TYPE_ID } from '../../utils/constants';
import { JobOfferStatusNotification } from '../components/StatusNotification';
import { FormValues, JobOfferForm, validationSchema } from '../form';
import { EDIT_JOB_OFFER_QUERY } from '../graphql/queries';
import { jobOfferMessages } from '../messages';
import { useDelete } from './utils/use-delete';
import { useSubmit } from './utils/use-submit';

export const Edit: React.FC = () => {
  const {
    params: { jobOfferId },
  } = useRouteMatch<JobOffersEditRouteMatchParams>();
  const intl = useIntl();
  const { formatMessage } = intl;
  const submit = useSubmit();
  const { _delete, loading: deleteLoading } = useDelete();
  const { confirm } = useConfirm();

  const { data } = useQuery<editJobOfferQuery, editJobOfferQueryVariables>(
    EDIT_JOB_OFFER_QUERY,
    {
      variables: { jobOfferId },
      errorPolicy: 'all',
      suspend: true,
    },
  );

  if (!data?.jobOffer) {
    return <EmptyState />;
  }

  const { jobOffer } = data;
  const coverPicturePreviewUrl = jobOffer.coverPictureFile?.url;
  const profilePicturePreviewUrl = jobOffer.profilePictureFile?.url;

  const initialValues: FormValues = {
    title: jobOffer.title ?? '',
    body: jobOffer.body ?? '',
    prospectName: jobOffer.prospectName ?? '',
    birthdate: jobOffer.birthdate ?? '',
    profilePicture: {
      previewUrl: profilePicturePreviewUrl,
      fallbackUrl: jobOffer.companyLogo ?? undefined,
    },
    coverPicture: coverPicturePreviewUrl
      ? { previewUrl: coverPicturePreviewUrl }
      : undefined,
    yearlyVacationDays: jobOffer.yearlyVacationDays?.toString() ?? '',
    collectiveAgreement: jobOffer.collectiveAgreement ?? '',
    monthlyLunchAllowance: jobOffer.monthlyLunchAllowance ?? '',
    yearlyWellnessAllowance: jobOffer.yearlyWellnessAllowance ?? '',
    monthlyCompanyCarValue: jobOffer.monthlyCompanyCarValue ?? '',
    visible: !jobOffer.hidden,
    untilFurtherNotice: jobOffer.validUntil === null ? 'yes' : 'no',
    validUntil: jobOffer.validUntil ?? '',
    workLocation: jobOffer.workLocation ?? '',
    workSetting: jobOffer.workSetting ?? '',
    showDiscount: jobOffer.showDiscount,
    hasOptionsProgram: !!jobOffer.optionsProgram,
    optionsProgram: jobOffer.optionsProgram,
    benefitPackageId: jobOffer.benefitPackageId ?? '',
    monthlySalary: jobOffer.remunerations?.find(
      r => r.idCode === MONTHLY_SALARY_REMUNERATION_TYPE_ID,
    )?.value,
    remunerations: jobOffer.remunerations?.filter(
      r => r.idCode !== MONTHLY_SALARY_REMUNERATION_TYPE_ID,
    ),
    attachmentFiles: jobOffer.attachmentFiles?.map((file, i) => ({
      name: file.filename ?? (i + 1).toString(),
      id: file.id,
      url: file.url,
    })),
    optionsProgrammeFiles: jobOffer.optionsProgrammeFiles?.map((file, i) => ({
      name: file.filename ?? (i + 1).toString(),
      id: file.id,
      url: file.url,
    })),
  };

  return (
    <Section>
      <PageLede>
        <TextGrid>
          <FormattedMessage {...jobOfferMessages.jobOfferDescr} />
        </TextGrid>
      </PageLede>
      <JobOfferStatusNotification jobOfferId={jobOffer.id} />
      <Formik<FormValues>
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema(intl)}
      >
        {({ setErrors }) => (
          <JobOfferForm
            secondaryAction={
              <Button
                type="button"
                loading={deleteLoading}
                danger
                text
                onClick={async () => {
                  const confirmed = await confirm({
                    title: formatMessage(
                      jobOfferMessages.confirmRemoveJobOffer,
                    ),
                    description: formatMessage(
                      jobOfferMessages.confirmRemoveJobOfferDescription,
                    ),
                  });
                  if (confirmed) {
                    _delete({ setErrors });
                  }
                }}
              >
                <FormattedMessage {...commonMessages.remove} />
              </Button>
            }
          />
        )}
      </Formik>
    </Section>
  );
};

import { Formik } from '@frontend/formik';
import {
  discountPresentationQuery,
  discountPresentationQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/discounts/edit';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { FormattedMessage } from 'components/formats';
import { Modal } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';

import { discountMessages } from '../../../messages';
import { BenefitForm, BenefitFormValues } from '../form';
import { DISCOUNT_PRESENTATION_QUERY } from '../graphql/queries';
import { isCustomOffer } from '../utils/is-custom-offer';
import { getInitialValuesFromBenefit } from './utils/get-initial-values-from-benefit';
import { useSubmit } from './utils/use-submit';

export const DiscountPresentationEdit: React.FC = () => {
  const match = useRouteMatch<MatchParams>();
  const history = useHistory();
  const { benefitId, companyId } = match.params;
  const { locale } = useIntlContext();
  const { data, previousData, loading, error } = useQuery<
    discountPresentationQuery,
    discountPresentationQueryVariables
  >(DISCOUNT_PRESENTATION_QUERY, {
    variables: {
      id: benefitId,
      locale,
    },
    errorPolicy: 'all',
  });

  const benefit = data?.benefit ?? previousData?.benefit;
  const { initialValues } = getInitialValuesFromBenefit(benefit, locale) ?? {};
  const { submit, error: submissionError } = useSubmit({
    initialValues,
    history,
  });

  if (!initialValues || !benefit) {
    if (loading) {
      return <TopLoading />;
    }
    return (
      <EmptyStatePage
        parentLink={`/companies/${companyId}/benefit-packages/discounts/${benefitId}/presentation`}
        error={error}
      />
    );
  }

  if (!isCustomOffer(benefit.type)) {
    return (
      <Redirect to={`/companies/${companyId}/benefit-packages/discounts`} />
    );
  }

  return (
    <Modal size="full-window">
      {loading && <TopLoading />}
      <Formik<BenefitFormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submit}
      >
        {({ values, isSubmitting, dirty }) => (
          <BenefitForm
            title={
              <FormattedMessage {...discountMessages.editBenefitPresentation} />
            }
            values={values}
            parentLink={`/companies/${companyId}/benefit-packages/discounts/${benefitId}/presentation`}
            dirty={dirty}
            isSubmitting={isSubmitting}
            queryError={error}
            submissionError={submissionError}
          />
        )}
      </Formik>
    </Modal>
  );
};

import { ContentContainer, Fab, Icon, Section } from '@frontend/ui';
import { edit } from '@frontend/ui/icons';
import {
  discountPresentationQuery,
  discountPresentationQueryVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/discounts/edit';
import { useQuery } from 'app/utils/use-query';
import { useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { discountMessages } from '../../messages';
import { BenefitPresentation } from './components/Presentation';
import { DISCOUNT_PRESENTATION_QUERY } from './graphql/queries';
import { isCustomOffer } from './utils/is-custom-offer';

export const DiscountPresentation: React.FC = ({ ...props }) => {
  const match = useRouteMatch<MatchParams>();
  const { benefitId } = match.params;
  const history = useHistory();

  const { formatMessage } = useIntl();
  const { locale } = useIntlContext();

  const { data, loading, error } = useQuery<
    discountPresentationQuery,
    discountPresentationQueryVariables
  >(DISCOUNT_PRESENTATION_QUERY, {
    variables: {
      id: benefitId,
      locale,
    },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.benefit) {
    return (
      <ContentContainer>
        <GraphQlError error={error} />
      </ContentContainer>
    );
  }

  const { benefit } = data;

  return (
    <Section>
      <BenefitPresentation
        benefit={benefit}
        error={error}
        context="admin-preview"
        {...props}
      />
      {isCustomOffer(benefit.type) && (
        <Fab
          label={formatMessage(discountMessages.editBenefitPresentation)}
          icon={<Icon icon={edit} size="medium" decorative />}
          onClick={() => history.push(`${match.url}/edit`)}
          style={{
            position: 'fixed',
            right: '1.5rem',
            bottom: '1.5rem',
            zIndex: 1,
          }}
        />
      )}
    </Section>
  );
};

// We have serveral linting issues in this file. Should be fixed
/* eslint-disable */
import { DraftEntityMutability, RawDraftContentState } from 'draft-js';
import { Remarkable } from 'remarkable';
import { EmbeddedComponentEntity } from './';

/* --- DRAFTJS TO MARKDOWN --- */

/* --- Custom Plugin Config for MarkdownToDraft  --- 

 The "draftJS to markdown" converter supports handlers of custom entities (metadata)
 which can be mapped to desired markdown. 

  We represent react components (atomic content blocks with entity type EmbeddedComponentEntity)
     as ::embedded[COMPONENT_TYPE] in markdown. The syntax is inspired by:
     https://talk.commonmark.org/t/generic-directives-plugins-syntax/444 
*/

export const draftToMarkdownOptions = {
  entityItems: {
    [EmbeddedComponentEntity]: {
      open: () => '',
      close: entity => `::embedded[${entity.data.componentType}]`,
    },
  },
};

/* --- MARKDOWN TO DRAFTJS --- */

/* --- Remarkable Parser --- 
  Parsing strings into remarkable blocks. 

 * This matches ::embedded[input], where input is any string,
    to the remarkable block type RemarkableEmbeddedBlock.
    `input` is mapped to the property `content`. 

 * If more sophisticated markdown is needed, extend this parser :)
*/

const embeddedParser: Remarkable.BlockParsingRule = (
  state,
  startLine,
  _,
  checkMode,
) => {
  const position = state.bMarks[startLine] + state.tShift[startLine];
  const max = state.eMarks[startLine];

  const embeddedRegEx = /\::embedded\[(.*?)\]/gim;

  if (position >= max) {
    return false;
  }

  if (!state.src) {
    return false;
  }
  const match = embeddedRegEx.exec(state.src.slice(position, max));

  if (!match || !match.length) {
    return false;
  }

  const [, embeddedContentType] = match;

  if (!checkMode) {
    state.tokens.push({
      type: `${RemarkableEmbeddedBlock}_open`,
      content: embeddedContentType,
      level: state.level,
      lines: [startLine, state.line],
    });
    state.tokens.push({
      type: `${RemarkableEmbeddedBlock}_close`,
      level: state.level,
    });
  }
  state.line = startLine + 1;
  return true;
};

const RemarkableEmbeddedBlock = 'remarkable_embedded_block';

const embeddedParserPlugin = (
  remarkable: Remarkable,
  options: Remarkable.Options,
) => {
  remarkable.block.ruler.before(
    'code',
    RemarkableEmbeddedBlock,
    embeddedParser,
    options,
  );
};

/* --- Custom Plugin Config for MarkdownToDraft based on Remarkable --- 

 The "markdown to draftJS" converter supports plugins based on remarkable
 parsers so we can map custom markdown to desired DraftJS content block types.

 markDownToDraft recognises any remarkable block type as {remarkableBlockTypename}_open
 where any parser defined property is accessible on the item in the callback function.

 For embedded components, we returm a block of type 'atomic'
 (the custom content block type in DraftJS).
 
 We use metadata (entries in draftJS) to provide information about the embedded component.
 The plugin doesn't support attaching entries to content blocks directly, so, as an
 intermediate step, we add the embedded component information to the text field of the block.

*/

export const markdownToDraftOptions = {
  remarkablePlugins: [embeddedParserPlugin],
  blockTypes: {
    [`${RemarkableEmbeddedBlock}_open`]: item => ({
      type: 'atomic',
      text: item.content,
    }),
  },
};

/* modifyAtomic
  - moves the atomic block text into an entity (as metadata) of type EmbeddedComponentEntity.  
*/

export const modifyAtomic = (rawDraft: RawDraftContentState) => {
  let idCounter = -1;
  const generateUniqueKey = () => {
    idCounter++;
    return idCounter;
  };

  const moveTextToEntity = _data => ({
    type: EmbeddedComponentEntity,
    mutability: 'IMMUTABLE' as DraftEntityMutability,
    data: { componentType: _data },
  });

  const { blocks, entityMap } = rawDraft;
  const _entityMap = { ...(entityMap || {}) };

  blocks.forEach(block => {
    if (block.type === 'atomic') {
      const key = generateUniqueKey();
      _entityMap[key] = moveTextToEntity(block.text);
      block.entityRanges = block?.entityRanges ?? [];
      block.entityRanges.push({ offset: 0, length: 1, key });
      block.text = ' ';
    }
  });
  return { blocks, entityMap: _entityMap };
};

import { ApolloError, useMutation } from '@apollo/client';
import { getIn } from '@frontend/formik';
import {
  createBenefitSupplierMutation,
  createBenefitSupplierMutationVariables,
  updateBenefitSupplierMutation,
  updateBenefitSupplierMutationVariables,
} from 'app/apollo/graphql/types';

import { NEW_SUPPLIER } from '../components/SupplierModal';
import { BENEFIT_SUPPLIERS_QUERY } from '../components/SupplierModal/graphql/queries';
import { Supplier } from '../form';
import {
  CREATE_BENEFIT_SUPPLIER_MUTATION,
  UPDATE_BENEFIT_SUPPLIER_MUTATION,
} from '../graphql/mutations';
import { getFilePath } from './get-file-paths';

interface Props {
  companyId: string;
}

interface UpdateProps {
  initialSupplier?: Supplier;
  supplier?: Supplier;
}

interface UpdateResponse {
  supplierId?: string;
}

interface Submit {
  updateSupplier: (args: UpdateProps) => Promise<UpdateResponse>;
  updateSupplierError?: ApolloError;
}

export const useUpdateSupplier = ({ companyId }: Props): Submit => {
  const [createSupplier, { error: createSupplierError }] = useMutation<
    createBenefitSupplierMutation,
    createBenefitSupplierMutationVariables
  >(CREATE_BENEFIT_SUPPLIER_MUTATION, {
    errorPolicy: 'all',
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: BENEFIT_SUPPLIERS_QUERY, variables: { companyId } },
    ],
  });

  const [updateSupplier, { error: updateSupplierError }] = useMutation<
    updateBenefitSupplierMutation,
    updateBenefitSupplierMutationVariables
  >(UPDATE_BENEFIT_SUPPLIER_MUTATION, {
    errorPolicy: 'all',
  });

  const _updateSupplier = async ({
    initialSupplier,
    supplier,
  }: UpdateProps) => {
    const logoImagePath =
      supplier?.logo && (await getFilePath(companyId, supplier?.logo));

    if (supplier?.id === NEW_SUPPLIER) {
      const { data } = await createSupplier({
        variables: {
          input: {
            companyId,
            name: supplier.name,
            logoPath: logoImagePath,
            websiteUrl: supplier.websiteUrl,
          },
        },
      });

      const supplierId = data?.createBenefitSupplier?.supplier.id;

      if (!supplierId) {
        throw new Error();
      }
      return { supplierId };
    }

    const isCustomSupplier = supplier?.companyId;
    const editableSupplierFields = ['name', 'logo.path', 'websiteUrl'];
    const hasSupplierBeenEdited = editableSupplierFields.some(
      field => getIn(supplier, field) !== getIn(initialSupplier, field),
    );

    if (isCustomSupplier && hasSupplierBeenEdited && supplier?.id) {
      await updateSupplier({
        variables: {
          input: {
            id: supplier.id,
            name: supplier?.name,
            logoPath: logoImagePath,
            websiteUrl: supplier?.websiteUrl,
          },
        },
      });
    }
    return { supplierId: supplier?.id };
  };

  return {
    updateSupplier: _updateSupplier,
    updateSupplierError: createSupplierError ?? updateSupplierError,
  };
};

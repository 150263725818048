import {
  Button,
  ButtonLayout,
  Card,
  CardContent,
  TextField,
} from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import React from 'react';

import { BenefitPresentationContext } from '../../components/Presentation';

export interface FormValues {
  city: string;
  email: string;
  name: string;
  need: string;
  phone: string;
}

interface Props {
  context: BenefitPresentationContext;
}

export const NannyNuForm: React.FC<Props> = () => (
  <Card>
    <CardContent>
      <TextField
        dense
        required
        label={<FormattedMessage {...commonMessages.name} />}
      />
      <TextField
        dense
        required
        type="tel"
        label={<FormattedMessage {...commonMessages.phone} />}
      />
      <TextField
        dense
        required
        type="email"
        label={<FormattedMessage {...commonMessages.email} />}
      />
      <TextField
        dense
        required
        name="city"
        label={<FormattedMessage {...commonMessages.city} />}
      />
      <TextField
        textarea
        required
        name="need"
        label={<FormattedMessage {...commonMessages.description} />}
      />
      <ButtonLayout>
        <Button type="submit" filled disabled>
          <FormattedMessage {...commonMessages.send} />
        </Button>
      </ButtonLayout>
    </CardContent>
  </Card>
);

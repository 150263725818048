import { useFormikContext } from '@frontend/formik';
import {
  Button,
  ButtonLayout,
  Fab as _Fab,
  Grid,
  GridCell,
  Icon,
  ModalFooter,
  SelectField,
  SelectOption,
  TextField,
} from '@frontend/ui';
import { edit } from '@frontend/ui/icons';
import { formatExternalUrl } from '@frontend/utils';
import {
  benefitSuppliersQuery,
  benefitSuppliersQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/companies/company/discounts/edit';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { discountMessages } from 'features/companies/company/discounts/messages';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

import {
  BenefitFormValues,
  Supplier as SupplierFieldValue,
  UploadableImage,
} from '../../form';
import { ImageUpload } from '../ImageUpload';
import { BENEFIT_SUPPLIERS_QUERY } from './graphql/queries';

const Fab = styled(_Fab)`
  position: absolute;
  bottom: 1.5rem;
  left: 6rem;
`;

export const NEW_SUPPLIER = 'NEW_SUPPLIER';

export const SupplierModal: React.FC = () => {
  const match = useRouteMatch<MatchParams>();
  const { companyId } = match.params;

  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [supplierOptions, setSupplierOptions] = useState<SelectOption[]>();
  const formikContext = useFormikContext<BenefitFormValues>();
  const [supplier, setSupplier] = useState<SupplierFieldValue | undefined>(
    formikContext.values.supplier,
  );

  useEffect(() => {
    if (isModalOpen) {
      setSupplier(formikContext.values.supplier);
    }
  }, [isModalOpen]);

  const { data: suppliers, error } = useQuery<
    benefitSuppliersQuery,
    benefitSuppliersQueryVariables
  >(BENEFIT_SUPPLIERS_QUERY, {
    variables: {
      companyId,
    },
    errorPolicy: 'all',
    onCompleted: data => {
      if (!data) {
        return;
      }
      const sortedSupplierOptions = data.company?.benefitSuppliers
        ?.map(opt => ({
          label: opt.name,
          value: opt.id,
        }))
        ?.sort((a, b) =>
          a.label.toLowerCase().localeCompare(b.label.toLocaleLowerCase()),
        );
      sortedSupplierOptions?.unshift({
        label: formatMessage(discountMessages.addSupplier),
        value: NEW_SUPPLIER,
      });
      setSupplierOptions(sortedSupplierOptions);
    },
  });

  const handleSubmit = () => {
    if (supplier) {
      const value: SupplierFieldValue = {
        ...supplier,
        websiteUrl: supplier.websiteUrl
          ? formatExternalUrl(supplier.websiteUrl)
          : null,
      };
      formikContext.setFieldValue('supplier', value);
      setIsModalOpen(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (supplier) {
      const { name, value } = event.target;
      const _supplier = { ...supplier, [name]: value };
      setSupplier(_supplier);
    }
  };

  const handleDropAccepted = (acceptedFiles: File[]) => {
    if (supplier) {
      const file = acceptedFiles[0];
      const logo: UploadableImage = {
        name: file.name,
        file,
        url: URL.createObjectURL(file),
      };
      const _supplier = { ...supplier, logo };
      setSupplier(_supplier);
    }
  };

  // It appears MDCSelectEvent is not exported so the event below is not typed
  const handleSelectChange = event => {
    const { value } = event.detail;

    if (value === NEW_SUPPLIER) {
      const newSupplier: SupplierFieldValue = {
        id: NEW_SUPPLIER,
        name: '',
        websiteUrl: '',
        companyId,
      };
      setSupplier(newSupplier);
    }

    const selectedSupplier = suppliers?.company?.benefitSuppliers?.find(
      x => x.id === value,
    );

    if (selectedSupplier) {
      const _supplier = {
        ...selectedSupplier,
        logo: selectedSupplier.logo
          ? {
              url: selectedSupplier.logo.url,
              path: selectedSupplier.logo.path,
            }
          : undefined,
      };
      setSupplier(_supplier);
    }
  };

  const isDefaultSupplier = !supplier?.companyId;
  const isSubmitDisabled =
    !supplier?.name ||
    !supplier.websiteUrl ||
    !(supplier.logo?.path || (supplier.logo?.name && supplier.logo?.file));

  return (
    <>
      <Fab
        mini
        label={formatMessage(discountMessages.editTitle)}
        onClick={() => setIsModalOpen(true)}
        icon={<Icon icon={edit} size="medium" decorative />}
      />
      <Modal
        size="small"
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ModalHeader>
          <FormattedMessage {...discountMessages.addSupplier} />
        </ModalHeader>
        <ModalBody>
          <Grid>
            <GridCell span={12}>
              <SelectField
                label={<FormattedMessage {...commonMessages.supplier} />}
                type="select"
                value={supplier?.id}
                onChange={handleSelectChange}
                options={supplierOptions}
                fixed
                required
                dense
              />
            </GridCell>

            <GridCell span={12}>
              <TextField
                label={<FormattedMessage {...commonMessages.name} />}
                name="name"
                value={supplier?.name}
                onChange={handleChange}
                disabled={isDefaultSupplier}
                required
                dense
              />
            </GridCell>
            <GridCell span={12}>
              <TextField
                label={
                  <FormattedMessage {...discountMessages.websiteAddress} />
                }
                name="websiteUrl"
                value={supplier?.websiteUrl || ''}
                onChange={handleChange}
                disabled={isDefaultSupplier}
                required
                dense
              />
            </GridCell>
            <GridCell span={12}>
              <ImageUpload
                onDropAccepted={handleDropAccepted}
                previewUrl={supplier?.logo?.url || ''}
                disabled={isDefaultSupplier}
              />
            </GridCell>
          </Grid>

          {error && <GraphQlError inModal error={error} />}
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button onClick={() => setIsModalOpen(false)} text>
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
            <Button onClick={handleSubmit} text disabled={isSubmitDisabled}>
              <FormattedMessage {...commonMessages.confirm} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
    </>
  );
};

import remarkDirective from 'remark-directive';
import remarkGfm from 'remark-gfm';
import { visit } from 'unist-util-visit';

const reactMarkdownDirective = () => tree =>
  visit(
    tree,
    ['text', 'textDirective', 'leafDirective', 'containerDirective'],
    node => {
      // eslint-disable-next-line
      node.data = {
        hName: node.name,
        hProperties: node.attributes,
        ...node.data,
      };
      return node;
    },
  );

export const embeddedMarkdownPlugin = [
  remarkDirective,
  reactMarkdownDirective,
  remarkGfm,
];

import { CheckboxField } from '@frontend/formik';
import { WizardSection, WizardSectionHeader } from '@frontend/ui';
import { validationMessages } from 'app/messages/common';
import { employeeFormMessages } from 'app/messages/employees';
import { FormattedMessage, IntlShape } from 'components/formats';
import React from 'react';
import * as Yup from 'yup';

export interface FormValues {
  confirmImport: boolean;
  skipWelcomeEmails: boolean;
}

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    confirmImport: Yup.boolean()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .oneOf([true], intl.formatMessage(validationMessages.mandatoryField)),
  });

export const initialValues: FormValues = {
  confirmImport: false,
  skipWelcomeEmails: true,
};

export const ConfirmFields: React.FC = () => (
  <WizardSection>
    <WizardSectionHeader>
      <FormattedMessage {...employeeFormMessages.confirmWelcomeEmails} />
    </WizardSectionHeader>
    <CheckboxField
      name="skipWelcomeEmails"
      label={
        <FormattedMessage {...employeeFormMessages.confirmSkipWelcomeEmails} />
      }
    />
    <WizardSectionHeader>
      <FormattedMessage {...employeeFormMessages.confirmImport} />
    </WizardSectionHeader>
    <CheckboxField
      name="confirmImport"
      label={
        <FormattedMessage
          {...employeeFormMessages.confirmSubmitImportEmployees}
        />
      }
    />
  </WizardSection>
);

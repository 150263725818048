import { useMutation } from '@apollo/client';
import { Button, ButtonLayout, ModalFooter, TextField } from '@frontend/ui';
import { onPressEnter } from '@frontend/utils';
import {
  createBenefitCategoryMutation,
  createBenefitCategoryMutationVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { MatchParams } from 'app/pages/companies/company/discounts/edit';
import { FormattedMessage } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { discountMessages } from 'features/companies/company/discounts/messages';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { CREATE_BENEFIT_CATEGORY_MUTATION } from '../graphql/mutations';
import { BENEFITS_CATEGORY_QUERY } from '../graphql/queries';

interface Props {
  isOpen: boolean;
  onCreated: (id: string) => void;
  onRequestClose: () => void;
}

export const CategoryModal: React.FC<Props> = ({
  isOpen,
  onCreated,
  onRequestClose,
  ...props
}) => {
  const match = useRouteMatch<MatchParams>();
  const { companyId } = match.params;
  const [categorySE, setCategorySE] = useState('');
  const [categoryEN, setCategoryEN] = useState('');

  const handleClose = () => {
    setCategoryEN('');
    setCategorySE('');
    onRequestClose();
  };

  const [createCategory, { error, loading }] = useMutation<
    createBenefitCategoryMutation,
    createBenefitCategoryMutationVariables
  >(CREATE_BENEFIT_CATEGORY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [BENEFITS_CATEGORY_QUERY],
    onCompleted: data => {
      if (!data.createBenefitCategory) {
        return null;
      }
      const id = data.createBenefitCategory?.categoryId;
      onCreated(id);
      handleClose();
      return null;
    },
  });

  const isValid = !!categorySE && !!categoryEN;

  const handleSubmit = () => {
    if (!isValid) {
      return;
    }
    createCategory({
      variables: {
        input: {
          translations: [
            { name: categoryEN, locale: 'en-US' },
            { name: categorySE, locale: 'sv-SE' },
          ],
          companyId,
        },
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} size="small" {...props}>
      <ModalHeader>
        <FormattedMessage {...discountMessages.addCategory} />
      </ModalHeader>
      <ModalBody>
        <TextField
          label={<FormattedMessage {...discountMessages.categoryNameSwedish} />}
          value={categorySE}
          onChange={e => setCategorySE(e.target.value)}
          dense
          required
          onKeyUp={onPressEnter(handleSubmit)}
        />
        <TextField
          label={<FormattedMessage {...discountMessages.categoryNameEnglish} />}
          value={categoryEN}
          onChange={e => setCategoryEN(e.target.value)}
          dense
          required
          onKeyUp={onPressEnter(handleSubmit)}
        />
        {error && <GraphQlError inModal error={error} />}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button text onClick={handleClose}>
            <FormattedMessage {...formMessages.cancel} />
          </Button>
          <Button
            type="submit"
            text
            disabled={!isValid}
            onClick={handleSubmit}
            loading={loading}
          >
            <FormattedMessage {...commonMessages.add} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};

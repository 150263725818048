import { ContentContainer, Section } from '@frontend/ui';
import { companyMessages } from 'app/messages/company';
import { FormattedMessage } from 'components/formats';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { EditCompanyDetails } from 'features/companies/company/details/edit';
import { Page } from 'features/page';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MatchParams as CompanyMatchParams } from '../..';

export const EditCompany: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = props => (
  <NavigationAnchor
    title={companyMessages.updateCompanyInformation}
    url={props.match.url}
  >
    <Page
      parentLink={`/companies/${props.match.params.companyId}/settings`}
      title={<FormattedMessage {...companyMessages.updateCompanyInformation} />}
    >
      <ContentContainer>
        <Section>
          <EditCompanyDetails {...props} />
        </Section>
      </ContentContainer>
    </Page>
  </NavigationAnchor>
);

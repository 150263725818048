import { defineMessages } from 'react-intl';

export const movieTicketMessages = defineMessages({
  formTitle: {
    id: 'movieTicketMessages.formTitle',
    defaultMessage: 'Varukorg',
  },
  cardTitle: {
    id: 'movieTicketMessages.cardTitle',
    defaultMessage: 'Biobiljett - En traditionell bioupplevelse',
  },
  cardDescription: {
    id: 'movieTicketMessages.cardDescription',
    defaultMessage: 'Nordea Nodes pris: {amount, number, ::currency/SEK .}',
  },
  totalAmountLabel: {
    id: 'movieTicketMessages.totalAmountLabel',
    defaultMessage: 'Totalbelopp',
  },
  confirmMessage: {
    id: 'movieTicketMessages.confirmMessage',
    defaultMessage:
      'Vid köp av biljetter godkänner du <termsLink>våra villkor</termsLink>',
  },
  buyButton: {
    id: 'movieTicketMessages.buyButton',
    defaultMessage: 'Köp biljetter',
  },
  emailFieldHelperText: {
    id: 'movieTicketMessages.emailFieldHelperText',
    defaultMessage:
      'Dina biljetter kommer att skickas till den här e-postadressen',
  },
});

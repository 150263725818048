import { membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge_node_MembershipEventHistory as EventHistory } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

import { isBenefitEvent } from '../../utils/is-benefit-event';
import { isEmploymentEvent } from '../../utils/is-employment-event';
import { isPersonAddedEvent } from '../../utils/is-person-added-event';
import { isRemunerationEvent } from '../../utils/is-remuneration-event';
import { isStatusEvent } from '../../utils/is-status-event';

interface Props {
  event: EventHistory;
}

export const EventType: React.FC<Props> = ({ event }) =>
  event.events?.some(e => isStatusEvent(e)) ? (
    <FormattedMessage {...commonMessages.status} />
  ) : event.events?.some(e => isBenefitEvent(e)) ? (
    <FormattedMessage {...commonMessages.benefitPackage} />
  ) : event.events?.some(e => isEmploymentEvent(e)) ? (
    <FormattedMessage {...commonMessages.employment} />
  ) : event.events?.some(e => isRemunerationEvent(e)) ? (
    <FormattedMessage {...commonMessages.remuneration} />
  ) : event.events?.some(e => isPersonAddedEvent(e)) ? (
    <FormattedMessage {...commonMessages.created} />
  ) : (
    <NoValue />
  );

import { toEffectiveThrough } from '@frontend/utils';
import {
  EmployeeHistoryEventType,
  membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge_node_MembershipEventHistory as EventHistory,
  membershipEventHistoryTableQuery_membership_Membership_eventHistory_MembershipEventHistoryConnection_edges_MembershipEventHistoryEdge_node_MembershipEventHistory_events_MembershipEventHistoryEvent as EventHistoryEvents,
} from 'app/apollo/graphql/types';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { employeeStatus } from 'app/messages/status';
import { DetailCell } from 'components/DetailCell';
import {
  FormattedCurrency,
  FormattedDate,
  FormattedMessage,
  FormattedPercentInteger,
} from 'components/formats';
import { NoValue } from 'components/NoValue';
import React from 'react';

import { isBenefitEvent } from '../../utils/is-benefit-event';
import { isEmploymentEvent } from '../../utils/is-employment-event';
import { isPersonAddedEvent } from '../../utils/is-person-added-event';
import { isRemunerationEvent } from '../../utils/is-remuneration-event';
import { isStatusEvent } from '../../utils/is-status-event';

const getStatusEventDetails = (eventData: readonly EventHistoryEvents[]) => {
  const status = eventData.find(
    ({ event }) => event === EmployeeHistoryEventType.ACTIVITY_SET_TYPE,
  )?.value;
  const startDate = eventData.find(
    ({ event }) => event === EmployeeHistoryEventType.ACTIVITY_SET_START,
  )?.value;
  const endDate = eventData.find(
    ({ event }) => event === EmployeeHistoryEventType.ACTIVITY_SET_END,
  )?.value;
  const rate = eventData.find(
    ({ event }) => event === EmployeeHistoryEventType.ACTIVITY_SET_RATE,
  )?.value;

  return (
    <DetailCell>
      <p>
        <FormattedMessage {...commonMessages.status} />
        <span>: </span>
        {status ? (
          <FormattedMessage messages={employeeStatus} select={status} />
        ) : (
          <NoValue />
        )}
      </p>
      <p>
        <FormattedMessage {...commonMessages.startDate} />
        <span>: </span>
        {startDate ? <FormattedDate value={startDate} /> : <NoValue />}
      </p>
      <p>
        <FormattedMessage {...commonMessages.endDate} />
        <span>: </span>
        {endDate ? <FormattedDate value={endDate} /> : <NoValue />}
      </p>
      <p>
        <FormattedMessage {...commonMessages.extent} />
        <span>: </span>
        {rate ? <FormattedPercentInteger value={Number(rate)} /> : <NoValue />}
      </p>
    </DetailCell>
  );
};

const getBenefitEventDetails = (eventData: readonly EventHistoryEvents[]) => {
  const benefitPackage = eventData.find(
    ({ event }) => event === EmployeeHistoryEventType.BENEFIT_SET,
  )?.value;

  return (
    <DetailCell>
      <p>
        <FormattedMessage {...commonMessages.benefitPackage} />
        <span>: </span>
        {benefitPackage ? <span>{benefitPackage}</span> : <NoValue />}
      </p>
    </DetailCell>
  );
};

const getEmploymentEventDetails = (
  eventData: readonly EventHistoryEvents[],
) => {
  const startDate = eventData.find(
    ({ event }) => event === EmployeeHistoryEventType.EMPLOYMENT_SET_START,
  )?.value;
  const endDate = eventData.find(
    ({ event }) => event === EmployeeHistoryEventType.EMPLOYMENT_SET_END,
  )?.value;

  return (
    <DetailCell>
      <p>
        <FormattedMessage {...commonMessages.startDate} />
        <span>: </span>
        {startDate ? <FormattedDate value={startDate} /> : <NoValue />}
      </p>
      <p>
        <FormattedMessage {...commonMessages.endDate} />
        <span>: </span>
        {endDate ? (
          <FormattedDate value={toEffectiveThrough(endDate)} />
        ) : (
          <NoValue />
        )}
      </p>
    </DetailCell>
  );
};

const getRemunerationEventDetails = (
  eventData: readonly EventHistoryEvents[],
) => {
  const type = eventData.find(
    ({ event }) => event === EmployeeHistoryEventType.REMUNERATION_SET_TYPE,
  )?.value;
  const value = eventData.find(
    ({ event }) => event === EmployeeHistoryEventType.REMUNERATION_SET_PRICE,
  )?.value;

  return (
    <DetailCell>
      <p>
        <FormattedMessage {...commonBenefitMessages.remunerationType} />
        <span>: </span>
        {type ? <span>{type}</span> : <NoValue />}
      </p>
      <p>
        <FormattedMessage {...commonMessages.value} />
        <span>: </span>
        {value ? (
          <FormattedCurrency value={value} currency="SEK" />
        ) : (
          <NoValue />
        )}
      </p>
    </DetailCell>
  );
};

interface Props {
  event: EventHistory;
}

export const EventDetails: React.FC<Props> = ({ event }) =>
  event.events?.some(e => isStatusEvent(e)) ? (
    getStatusEventDetails(event.events)
  ) : event.events?.some(e => isBenefitEvent(e)) ? (
    getBenefitEventDetails(event.events)
  ) : event.events?.some(e => isEmploymentEvent(e)) ? (
    getEmploymentEventDetails(event.events)
  ) : event.events?.some(e => isRemunerationEvent(e)) ? (
    getRemunerationEventDetails(event.events)
  ) : event.events?.some(e => isPersonAddedEvent(e)) ? (
    <FormattedMessage {...commonMessages.personAdded} />
  ) : (
    <NoValue />
  );

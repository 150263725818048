import { Table, Td, Th, Tr } from '@frontend/ui';
import { addCircle } from '@frontend/ui/icons';
import {
  discountsOverviewQuery_company_Company_benefits_FlexBenefitConnection_edges_FlexBenefitEdge_node_FlexBenefit as Benefit,
  pageInfoDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { FormattedMessage } from 'components/formats';
import { NoValue } from 'components/NoValue';
import { PerPageSelectProps } from 'components/PerPageSelect';
import { TableNavigation } from 'components/TableNavigation';
import { MatchParams } from 'features/companies/company';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import { discountMessages } from '../messages';

interface Navigation extends PerPageSelectProps {
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
}

interface BenefitsOverviewTableProps {
  benefits: Benefit[];
  navigation: Navigation;
}

export const DiscountsTable: React.FC<BenefitsOverviewTableProps> = ({
  benefits,
  navigation,
}) => {
  const match = useRouteMatch<MatchParams>();
  const { companyId } = match.params;
  const history = useHistory();

  return (
    <>
      <ChipsWrapper>
        <AssistChip
          text={<FormattedMessage {...formMessages.createOffer} />}
          to={`/companies/${companyId}/benefit-packages/discounts/create`}
          leadingIcon={addCircle}
        />
      </ChipsWrapper>
      <Table navigation={<TableNavigation {...navigation} />}>
        <colgroup>
          <col style={{ width: '33%' }} />
          <col style={{ width: '33%' }} />
          <col style={{ width: '33%' }} />
        </colgroup>
        <thead>
          <Tr>
            <Th multiline>
              <FormattedMessage {...discountMessages.offer} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.category} />
            </Th>
            <Th align="left">
              <FormattedMessage {...commonMessages.via} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {benefits.map(benefit => (
            <Tr
              linked
              onClick={() =>
                history.push(`${match.url}/${benefit.id}/presentation`)
              }
            >
              <Td multiline>{benefit.content.title}</Td>
              <Td>
                {benefit.categories.length ? (
                  benefit.categories[0].name
                ) : (
                  <NoValue />
                )}
              </Td>
              <Td align="left">{benefit.supplier.name}</Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

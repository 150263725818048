import gql from 'graphql-tag';

export const DISCOUNT_LAYOUT_QUERY = gql`
  query discountLayoutQuery($id: ID!) {
    benefit: flexBenefit(id: $id) {
      id
      type
      content {
        title
        locale
      }
      supplier {
        id
        name
      }
    }
  }
`;

import { FormattedRange } from 'components/formats';
import React from 'react';

interface FormattedRangeProps {
  lowerBound: number;
  upperBound?: number;
}

/**
 * This component re-exports the FormattedRange component from the components/formats module
 * but correctly accounts for the off-by-one error in the upperBound prop.
 */
export const FormattedPremiumMatrixAgeRange: React.FC<FormattedRangeProps> = ({
  lowerBound,
  upperBound,
}) => {
  const _upperBound = upperBound ? upperBound - 1 : '';
  return <FormattedRange lowerBound={lowerBound} upperBound={_upperBound} />;
};

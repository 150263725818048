import {
  Button,
  Card,
  SnapshotCardLayout,
  SnapshotCardLayoutProps,
} from '@frontend/ui';
import { CardLink, LinkOrAnchorProps } from 'components/CardLink';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  height: 100%;
`;

export type LinkItem = LinkOrAnchorProps & {
  label?: React.ReactNode;
};

interface Props extends Omit<SnapshotCardLayoutProps, 'action'> {
  link?: LinkItem;
}

export const SnapshotCard: React.FC<Props> = ({ link, ...props }) => {
  if (!link) {
    return (
      <StyledCard filled rounded>
        <SnapshotCardLayout {...props} filled />
      </StyledCard>
    );
  }

  const { label, ...linkProps } = link;

  return (
    <StyledCard filled rounded>
      <CardLink {...linkProps}>
        <SnapshotCardLayout
          {...props}
          action={label && <Button tonal>{label}</Button>}
          filled
        />
      </CardLink>
    </StyledCard>
  );
};

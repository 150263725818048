import { smeCompanyBenefitPackagesQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage_benefits_ProposalBenefit as Benefit } from 'app/apollo/graphql/types';
import { pensionBenefitMessages } from 'app/messages/benefits';
import { translatePBBLevel } from 'app/utils/constants';
import { DetailCell } from 'components/DetailCell';
import { FormattedMessage, useIntl } from 'components/formats';
import { NoValue } from 'components/NoValue';
import {
  smeBenefitDisabilityLevelMessages,
  smeBenefitFormMessages,
  smeBenefitPensionPremiumCalculationMethodMessages,
  smeBenefitsMessages,
  smeHealthcareLevelMessages,
  smePremiumDeductionMessages,
  smeSalaryExchangeMessages,
} from 'features/sme/messages/sme';
import React from 'react';

interface Config<C extends string = string> {
  __typename?: C;
}

export const isBenefitOfType = <T extends Config, C extends string>(
  typename: C,
  config: T,
): config is Extract<T, { __typename?: C }> => config.__typename === typename;

interface Props {
  benefit: Benefit;
}

export const Details: React.FC<Props> = ({ benefit }) => {
  const { formatMessage } = useIntl();
  const { configuration } = benefit;
  if (isBenefitOfType('ProposalAccidentBenefitConfig', configuration)) {
    return translatePBBLevel(configuration.accidentLevel);
  }
  if (isBenefitOfType('ProposalHealthcareBenefitConfig', configuration)) {
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            select={configuration.healthcareLevel}
            messages={smeHealthcareLevelMessages}
          />
        </p>
        <p>
          {configuration.waitingPeriod ? (
            <FormattedMessage {...smeBenefitFormMessages.waitingPeriodYes} />
          ) : (
            <FormattedMessage {...smeBenefitFormMessages.waitingPeriodNo} />
          )}
        </p>
      </DetailCell>
    );
  }
  if (isBenefitOfType('ProposalLifeBenefitConfig', configuration)) {
    return translatePBBLevel(configuration.lifeLevel);
  }
  if (isBenefitOfType('ProposalSicknessBenefitConfig', configuration)) {
    return (
      <FormattedMessage
        select={configuration.sicknessLevel}
        messages={smeBenefitDisabilityLevelMessages}
      />
    );
  }
  if (
    isBenefitOfType('ProposalOccupationalPensionBenefitConfig', configuration)
  ) {
    const deductions = configuration.premiumDeductions?.length
      ? configuration.premiumDeductions
          .map(deduction =>
            formatMessage({
              messages: smePremiumDeductionMessages,
              select: deduction,
            }),
          )
          .join(', ')
      : [];
    return (
      <DetailCell>
        <p>
          <FormattedMessage
            select={configuration.premiumCalculationMethod}
            messages={smeBenefitPensionPremiumCalculationMethodMessages}
          />
        </p>
        {configuration.premiumWaiver && (
          <p>
            <FormattedMessage {...pensionBenefitMessages.premiumWaiver} />
          </p>
        )}
        {configuration.extraFixedPremium && (
          <p>
            <FormattedMessage {...pensionBenefitMessages.fixedExtraPremium} />
          </p>
        )}
        {!!deductions.length && (
          <p>
            <FormattedMessage
              {...pensionBenefitMessages.deductionsTableMessage}
              values={{ deductions }}
            />
          </p>
        )}
      </DetailCell>
    );
  }
  if (isBenefitOfType('ProposalSalaryExchangeBenefitConfig', configuration)) {
    return (
      configuration.pensionToSalaryMax && (
        <FormattedMessage
          {...smeSalaryExchangeMessages.ongoingMax}
          values={{ share: configuration.pensionToSalaryMax }}
        />
      )
    );
  }

  if (isBenefitOfType('ProposalTglBenefitConfig', configuration)) {
    return <FormattedMessage {...smeBenefitsMessages.tglBenefitDescription} />;
  }

  return <NoValue />;
};

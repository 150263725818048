import { Table, Td, Th, Tr } from '@frontend/ui';
import {
  membershipsQuery_memberships_MembershipConnection_edges_MembershipEdge_node_Membership as Membership,
  pageInfoDetails,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { employeeMessages } from 'app/messages/employees';
import { FormattedMessage } from 'components/formats';
import { TableNavigation } from 'components/TableNavigation';
import React from 'react';

import { EmployeeTableRow } from '../EmployeeTableRow';

interface Props {
  employees: Membership[];
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
}

const EmployeeTableColgroup = () => (
  <colgroup>
    <col style={{ width: '30%' }} />
    <col style={{ width: '15%' }} />
    <col style={{ width: '30%' }} />
    <col style={{ width: '25%' }} />
  </colgroup>
);

export const EmployeesTable: React.FC<Props> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  employees,
}) => (
  <Table
    navigation={
      <TableNavigation
        pageInfo={pageInfo}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    }
    fixed
  >
    {employees.length === 0 ? (
      <tbody>
        <Tr>
          <Td>
            <FormattedMessage {...employeeMessages.noEmployeeMatch} />
          </Td>
        </Tr>
      </tbody>
    ) : (
      <>
        <EmployeeTableColgroup />
        <thead>
          <Tr>
            <Th>
              <FormattedMessage {...commonMessages.userAccountId} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.id} />
            </Th>
            <Th>
              <FormattedMessage {...commonMessages.name} />
            </Th>
            <Th align="left">
              <FormattedMessage {...commonMessages.company} />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {employees.map(employee => (
            <EmployeeTableRow
              key={`${employee.userAccountId}${employee.company.id}`}
              employee={employee}
            />
          ))}
        </tbody>
      </>
    )}
  </Table>
);

export default {
  children: [
    {
      name: 'path',
      attribs: {
        fill: 'currentColor',
        d: 'M8 11h3v10h2V11h3l-4-4-4 4zM4 3v2h16V3H4z',
      },
    },
  ],
};

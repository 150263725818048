import { ProposalBaseAmountType } from 'app/apollo/graphql/types';

import { ProposalPremiumMatrixFormValues } from '../../../utils/types';

export const customPremiumMatrixInitialValues: ProposalPremiumMatrixFormValues =
  {
    age: [0, 16, 67],
    baseAmount: ['0', '7.5', '30'],
    baseAmountType: ProposalBaseAmountType.IBB,
    intervals: [
      ['0', '0', '0'],
      ['0', '0', '0'],
      ['0', '0', '0'],
    ],
    salaryMultiplier: '12',
  };

export enum PremiumIntervalsValidationError {
  AGES_ASCENDING = 'AGES_ASCENDING',
  BASE_AMOUNTS_ASCENDING = 'BASE_AMOUNTS_ASCENDING',
  BASE_AMOUNT_NOT_OVER_100 = 'BASE_AMOUNT_NOT_OVER_100',
  PREMIUMS_NOT_OVER_100 = 'PREMIUMS_NOT_OVER_100',
}

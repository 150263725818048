import gql from 'graphql-tag';

export const CREATE_BENEFIT_SUPPLIER_MUTATION = gql`
  mutation createBenefitSupplierMutation(
    $input: FlexCreateBenefitSupplierInput!
  ) {
    createBenefitSupplier(input: $input) {
      supplier {
        id
        name
      }
    }
  }
`;

export const UPDATE_BENEFIT_SUPPLIER_MUTATION = gql`
  mutation updateBenefitSupplierMutation(
    $input: FlexUpdateBenefitSupplierInput!
  ) {
    updateBenefitSupplier(input: $input) {
      supplier {
        id
        name
        logo {
          url
          path
          name
        }
        websiteUrl
      }
    }
  }
`;

import gql from 'graphql-tag';

export const UPDATE_FEES_LOOKED_AT_MUTATION = gql`
  mutation updateFeesLookedAtMutation($input: ProposalIdInput!) {
    setFeesLookedAt(input: $input) {
      id
      validity {
        validFees {
          isValid
        }
      }
    }
  }
`;

import axios from 'axios';

export const uploadFile = async (uploadUrl: string, file: File) => {
  const config = {
    headers: {
      'Content-Type': file.type,
    },
  };
  return axios.put(uploadUrl, file, config);
};

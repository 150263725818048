import { Td, Tr } from '@frontend/ui';
import { getEmploymentStatus, toNumber } from '@frontend/utils';
import { companyEmployeesOverviewQuery_company_Company_memberships_MembershipConnection_edges_MembershipEdge_node_Membership as Employee } from 'app/apollo/graphql/types';
import { employmentStatusMessages } from 'app/messages/employees';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { MatchParams as BenefitPackageMatchParams } from 'app/pages/companies/company/benefit-packages/benefit-package';
import { FormattedMessage, FormattedNumber } from 'components/formats';
import { FormattedNaturalPersonIdentifier } from 'components/FormattedNaturalPersonIdentifier';
import { NoValue } from 'components/NoValue';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';

type MatchParams = CompanyMatchParams | BenefitPackageMatchParams;
interface Props {
  employee: Employee;
}

export const EmployeeTableRow: React.FC<Props> = ({ employee }) => {
  const { push } = useHistory();
  const { params } = useRouteMatch<MatchParams>();

  const employmentStatus = getEmploymentStatus(employee.employment);
  return (
    <Tr
      linked
      onClick={() => {
        push(
          `/companies/${params.companyId}/employees/${employee.userAccountId}`,
        );
      }}
    >
      <Td align="left">{employee.userAccountId}</Td>
      <Td type="number" align="left">
        <FormattedNaturalPersonIdentifier
          nids={employee.naturalPersonIdentifiers}
        />
      </Td>
      <Td>{`${employee.givenName} ${employee.lastName}`}</Td>
      <Td>
        {employmentStatus ? (
          <FormattedMessage
            messages={employmentStatusMessages}
            select={employmentStatus}
          />
        ) : (
          <NoValue />
        )}
      </Td>
      <Td>
        {employee.membershipBenefitPackage?.benefitPackage?.name ?? <NoValue />}
      </Td>
      <Td type="number">
        {employee.monthlySalary ? (
          <FormattedNumber value={toNumber(employee.monthlySalary) ?? 0} />
        ) : (
          <NoValue />
        )}
      </Td>
    </Tr>
  );
};

import gql from 'graphql-tag';

export const UPDATE_COMPILATION_LOOKED_AT_MUTATION = gql`
  mutation updateCompilationLookedAtMutation($input: ProposalIdInput!) {
    setCompilationLookedAt(input: $input) {
      id
      validity {
        validProposal {
          isValid
        }
        validCompilation {
          isValid
        }
      }
    }
  }
`;

import React, { createContext, useContext, useMemo, useState } from 'react';

interface ContextProps {
  setIsReadOnly: (isReadOnly: boolean) => void;
  isReadOnly?: boolean;
}

export const RichTextEditorContext = createContext<ContextProps>({
  setIsReadOnly: (isReadOnly: boolean) => isReadOnly,
});

export const useRichTextEditorContext = (): ContextProps =>
  useContext(RichTextEditorContext);

interface Props {
  children: React.ReactNode;
}

export const RichTextEditorContextProvider: React.FC<Props> = ({
  children,
}) => {
  const [isReadOnly, setIsReadOnly] = useState(false);
  const value = useMemo(
    () => ({
      isReadOnly,
      setIsReadOnly,
    }),
    [isReadOnly],
  );

  return (
    <RichTextEditorContext.Provider value={value}>
      {children}
    </RichTextEditorContext.Provider>
  );
};

import { NaturalPersonIdentifier } from '@advinans/belt-natural-person-identifier';
import { Button, ButtonLayout, ModalFooter, TextField } from '@frontend/ui';
import { onPressEnter } from '@frontend/utils';
import { commonMessages, validationMessages } from 'app/messages/common';
import { employeeFormMessages } from 'app/messages/employees';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, useIntl } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { validateNaturalPersonIdentifier } from 'validations';

interface Props {
  isOpen: boolean;
  onAddValue: (value: string) => void;
  onRequestClose: () => void;
}

export const NidTableFieldModal: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  onAddValue,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);
  const hasSubmitted = useRef(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (isOpen) {
      setTouched(false);
      setValue('');
      hasSubmitted.current = false;
    }
  }, [isOpen]);

  const validate = () => {
    setError(
      !validateNaturalPersonIdentifier(value)
        ? intl.formatMessage(validationMessages.isValidNid)
        : undefined,
    );
  };

  useEffect(validate, [value]);

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    validate();
  };

  const isValidInput = !error;

  const submit = () => {
    if (!isValidInput || hasSubmitted.current) {
      return;
    }
    hasSubmitted.current = true;
    onAddValue(NaturalPersonIdentifier.FromString(value).toString());
    onRequestClose();
  };

  return (
    <Modal size="small" isOpen={isOpen} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...employeeFormMessages.addNid} />
      </ModalHeader>
      {/* eslint-disable-next-line */}
      <div role="form" onKeyDown={onPressEnter(submit)}>
        <ModalBody>
          <TextField
            dense
            label={<FormattedMessage {...commonMessages.nidLong} />}
            value={value}
            onChange={onValueChange}
            errorText={error}
            validationMsg={touched && !!error}
            onBlur={() => setTouched(true)}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button text onClick={onRequestClose}>
              <FormattedMessage {...formMessages.cancel} />
            </Button>
            <Button text disabled={!isValidInput} onClick={submit}>
              <FormattedMessage {...commonMessages.add} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </div>
    </Modal>
  );
};

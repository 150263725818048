import { ButtonLayout, IconButton } from '@frontend/ui';
import { keyboardArrowLeft, keyboardArrowRight } from '@frontend/ui/icons';
import { pageInfoDetails } from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { useIntl } from 'components/formats';
import { PerPageSelect, PerPageSelectProps } from 'components/PerPageSelect';
import React from 'react';

interface OwnProps extends PerPageSelectProps {
  onNextPage: () => void;
  onPreviousPage: () => void;
  pageInfo: pageInfoDetails;
}

export const TableNavigation: React.FC<OwnProps> = ({
  pageInfo,
  onNextPage,
  onPreviousPage,
  perPage,
  setPerPage,
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PerPageSelect perPage={perPage} setPerPage={setPerPage} />
      <ButtonLayout align="right">
        <IconButton
          disabled={!pageInfo.hasPreviousPage}
          onClick={onPreviousPage}
          icon={keyboardArrowLeft}
          label={formatMessage(commonMessages.goToPreviousPage)}
        />
        <IconButton
          disabled={!pageInfo.hasNextPage}
          onClick={onNextPage}
          icon={keyboardArrowRight}
          label={formatMessage(commonMessages.goToNextPage)}
        />
      </ButtonLayout>
    </>
  );
};

import { Formik } from '@frontend/formik';
import { MatchParams } from 'app/pages/companies/company/discounts/edit';
import { FormattedMessage } from 'components/formats';
import { Modal } from 'components/Modal';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import React from 'react';
import { useParams } from 'react-router-dom';

import { BenefitForm, BenefitFormValues } from '../discount/presentation/form';
import { discountMessages } from '../messages';
import { useSubmit } from './utils/use-submit';

const INITIAL_VALUES = {
  'sv-SE': {
    content: {
      title: 'Ange titel',
      short: 'Ange kort beskrivning',
      body: 'Ersätt denna text med bekrivning av förmånen',
      locale: 'sv-SE',
    },
  },
  'en-US': {
    content: {
      title: 'Your title',
      short: 'Short description',
      body: 'Replace this text with information about the benefit or offer',
      locale: 'en-US',
    },
  },
};

export const CreateDiscount: React.FC = () => {
  const { companyId } = useParams<MatchParams>();
  const { locale } = useIntlContext();
  const initialValues: BenefitFormValues = INITIAL_VALUES[locale ?? 'sv-SE'];
  const { submit, error } = useSubmit({
    initialValues,
  });

  return (
    <Modal size="full-window">
      <Formik<BenefitFormValues>
        initialValues={initialValues}
        onSubmit={submit}
      >
        {({ values, isSubmitting, dirty }) => (
          <BenefitForm
            title={<FormattedMessage {...discountMessages.createDiscount} />}
            dirty={dirty}
            values={values}
            submissionError={error}
            isSubmitting={isSubmitting}
            parentLink={`/companies/${companyId}/benefit-packages/discounts`}
          />
        )}
      </Formik>
    </Modal>
  );
};

import { Form, Formik, TextField } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import {
  editBenefitPackageModalQuery,
  editBenefitPackageModalQuery_proposal_Proposal_benefitPackages_ProposalBenefitPackage as BenefitPackage,
  editBenefitPackageModalQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { MatchParams as ProposalMatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import {
  smeBenefitPackagesMessages,
  smeRepresentativesMessages,
} from 'features/sme/messages/sme';
import qs from 'query-string';
import React, { useRef } from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import * as Yup from 'yup';

import { EDIT_BENEFIT_PACKAGE_MODAL_QUERY } from './graphql/queries';
import { useSubmit } from './utils/use-submit';

export interface FormValues {
  benefitPackageId: string;
  name: string;
  proposalId: string;
}

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const EditBenefitPackageModal = () => {
  const intl = useIntl();

  const history = useHistory();
  const location = useLocation();
  const { params } = useRouteMatch<ProposalMatchParams>();

  const previousBenefitPackageRef = useRef<BenefitPackage>();

  const { 'edit-benefit-package': benefitPackageId } = qs.parse(
    location.search,
  );

  const onRequestClose = () => {
    stripSearchParams(history, location, ['edit-benefit-package']);
  };

  const { submit, submissionError } = useSubmit({ onRequestClose });

  const { data, loading, error } = useQuery<
    editBenefitPackageModalQuery,
    editBenefitPackageModalQueryVariables
  >(EDIT_BENEFIT_PACKAGE_MODAL_QUERY, {
    errorPolicy: 'all',
    variables: { proposalId: params.proposalId },
  });

  if (benefitPackageId && loading) {
    return <TopLoading />;
  }

  const benefitPackage = data?.proposal?.benefitPackages?.find(
    ({ id }) => id === benefitPackageId,
  );

  if (benefitPackage) {
    previousBenefitPackageRef.current = benefitPackage;
  }

  // Render previous data when modal is fading out
  // so error state is not shown
  const _benefitPackage = benefitPackage ?? previousBenefitPackageRef.current;

  if (!_benefitPackage) {
    return (
      <Modal isOpen={benefitPackageId} onRequestClose={onRequestClose}>
        <ModalHeader>
          <FormattedMessage
            {...smeRepresentativesMessages.editRepresentative}
          />
        </ModalHeader>
        <ModalBody>
          <GraphQlError error={error} inModal />
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button text onClick={onRequestClose}>
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
    );
  }

  const initialValues: FormValues = {
    benefitPackageId: _benefitPackage.id,
    name: _benefitPackage.name,
    proposalId: params.proposalId,
  };

  return (
    <Modal
      isOpen={benefitPackageId}
      onRequestClose={onRequestClose}
      size="medium"
    >
      <ModalHeader>
        <FormattedMessage {...smeBenefitPackagesMessages.editName} />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              <TextField
                dense
                name="name"
                label={<FormattedMessage {...commonMessages.name} />}
                required
              />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  text
                  type="submit"
                >
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

interface Args {
  benefitPackageId: string;
  location: RouteComponentProps['location'];
}

export const getEditBenefitPackageLink = ({
  location,
  benefitPackageId,
}: Args): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'edit-benefit-package': benefitPackageId }),
});

import {
  discountLayoutQuery,
  discountLayoutQueryVariables,
} from 'app/apollo/graphql/types';
import { useQuery } from 'app/utils/use-query';
import { EmptyStatePage } from 'components/EmptyStatePage';
import { useIntl } from 'components/formats';
import { TopLoading } from 'components/TopLoading';
import { NavigationAnchor } from 'contexts/navigation-anchor';
import { DiscountConfiguration } from 'features/companies/company/discounts/discount/configuration';
import { DiscountPresentation } from 'features/companies/company/discounts/discount/presentation';
import { DiscountPresentationEdit } from 'features/companies/company/discounts/discount/presentation/edit';
import { discountMessages } from 'features/companies/company/discounts/messages';
import { Page } from 'features/page';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { MatchParams as CompanyMatchParams } from '../..';
import { DISCOUNT_LAYOUT_QUERY } from '../graphql/queries';

export interface MatchParams extends CompanyMatchParams {
  benefitId: string;
}

export const DiscountEditRoute: React.FC = () => {
  const { formatMessage } = useIntl();
  const {
    params: { benefitId, companyId },
    path,
    url,
  } = useRouteMatch<MatchParams>();

  const { data, loading, error } = useQuery<
    discountLayoutQuery,
    discountLayoutQueryVariables
  >(DISCOUNT_LAYOUT_QUERY, {
    variables: {
      id: benefitId,
    },
    errorPolicy: 'all',
  });

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.benefit) {
    return (
      <EmptyStatePage
        error={error}
        parentLink={`/companies/${companyId}/benefit-packages/discounts`}
      />
    );
  }

  const { benefit } = data;

  const tabs = [
    {
      title: discountMessages.configuration,
      url,
    },
    {
      title: discountMessages.presentation,
      url: `${url}/presentation`,
    },
  ];

  return (
    <NavigationAnchor
      title={formatMessage(discountMessages.editDiscount, {
        name: benefit.content.title,
      })}
      url={url}
    >
      <Switch>
        <Route exact path={tabs.map(({ url: tabUrl }) => tabUrl)}>
          <Page
            title={`${benefit.supplier.name} - ${benefit.content.title}`}
            tabs={tabs}
            parentLink={`/companies/${companyId}/benefit-packages/discounts`}
          >
            <Switch>
              <Route exact path={path} component={DiscountConfiguration} />
              <Route
                exact
                path={`${path}/presentation`}
                component={DiscountPresentation}
              />
              <Route component={EmptyStatePage} />
            </Switch>
          </Page>
        </Route>
        <Route
          path={`${path}/presentation/edit`}
          component={DiscountPresentationEdit}
        />
        <Route component={EmptyStatePage} />
      </Switch>
    </NavigationAnchor>
  );
};

import gql from 'graphql-tag';

import { occupationalPensionChangeDetails } from '../../pension/graphql/queries';

export const UPDATE_PENSION_PREMIUM_MODAL_QUERY = gql`
  query updatePensionPremiumModalQuery(
    $companyId: ID!
    $userAccountId: ID!
    $includeFixedPremium: Boolean!
  ) {
    membership(companyId: $companyId, userAccountId: $userAccountId) {
      id
      insurances {
        edges {
          node {
            id
            institute {
              id
            }
            effectiveDate
            nextPremiumPayment {
              value
            }
            previousPremiumPayment {
              value
            }
            components {
              id
              status
              typeCode
            }
          }
        }
      }
      # This field is deprecated and should not be used for determining
      # entitlement to fixed pension benefit.
      #
      # A correct implementation of this should query the entitlements API. This would, however,
      # be very slow and is probably not worth the hassle. But for completeness, the selectable
      # premium effective dates should be based on the entitlements resolved for every month as
      # today - 3 months, today - 2 months, today - 1 month, today, and today + 1 month.
      benefitPackageEffectiveDate
    }
    fixedPremium(companyId: $companyId, userAccountId: $userAccountId)
      @include(if: $includeFixedPremium) {
      edges {
        node {
          userAccountId
          companyId
          premium
          effectiveDate
          createdAt
        }
      }
    }
  }
`;

export const OCCUPATIONAL_PENSION_CHANGES_QUERY = gql`
  query occupationalPensionChangesQuery($companyId: ID!, $userAccountId: ID!) {
    changes(companyId: $companyId, userAccountId: $userAccountId) {
      ... on FlexOccupationalPensionChange {
        ...occupationalPensionChangeDetails
      }
    }
  }
  ${occupationalPensionChangeDetails}
`;

import { Icon, Text } from '@frontend/ui';
import { lockOpen } from '@frontend/ui/icons';
import { CopyTextButton } from 'components/CopyTextButton';
import { FormattedMessage } from 'components/formats';
import { NotificationCard } from 'components/NotificationCard';
import React from 'react';
import styled from 'styled-components';

import { campaignCodeMessages } from './messages';

const Wrapper = styled.div`
  display: flex;
  gap: 0.875rem;
  margin-top: 1rem;
`;

interface Props {
  campaignCode: string;
}

export const CampaignCodeCard: React.FC<Props> = ({ campaignCode }) => (
  <NotificationCard
    actions={
      <CopyTextButton
        copyText={campaignCode}
        buttonText={<FormattedMessage {...campaignCodeMessages.copyCode} />}
      />
    }
  >
    <Text use="bodyLarge" as="div">
      <FormattedMessage {...campaignCodeMessages.campaignCode} />
    </Text>
    <Wrapper>
      <Icon icon={lockOpen} decorative />
      <Text use="titleSmall" color="green" as="div">
        {campaignCode}
      </Text>
    </Wrapper>
  </NotificationCard>
);

import { commonMessages } from 'app/messages/common';
import { DEFAULT_RESULT_PER_PAGE } from 'app/utils/constants';
import { FormattedMessage } from 'components/formats';
import { InlineSelectField } from 'components/InlineSelect';
import qs from 'query-string';
import React, { useId } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const DEFAULT_PER_PAGE_OPTIONS = [10, 50, 75, 100];

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const InnerLabel = styled.label`
  margin-right: 0.125rem;
  color: ${p => p.theme.onSurfaceVariant};
`;

export interface PerPageSelectProps {
  perPage?: string;
  setPerPage?: (perPage: string) => void;
}

export const PerPageSelect: React.FC<PerPageSelectProps> = ({
  perPage,
  setPerPage,
}) => {
  const history = useHistory();
  const id = useId();

  if (setPerPage) {
    return (
      <Container>
        <InnerLabel htmlFor={id}>
          <FormattedMessage {...commonMessages.perPageResult} />
        </InnerLabel>
        <InlineSelectField
          id={id}
          required
          value={perPage}
          label={<FormattedMessage {...commonMessages.perPage} />}
          onChange={({ detail: { value } }) => {
            setPerPage(value);
          }}
          options={DEFAULT_PER_PAGE_OPTIONS.map(option => ({
            label: option,
            value: option,
          }))}
          withoutMargin
          nbrOfCharacters={perPage?.length ?? 3}
        />
      </Container>
    );
  }

  const { per_page } = qs.parse(location.search);

  const handleChange = ({ detail: { value } }) => {
    const currentSearch = qs.parse(location.search);
    const search = qs.stringify({ ...currentSearch, per_page: value });
    history.push({
      search,
    });
  };

  const perPageValue: string =
    per_page ?? (perPage || DEFAULT_RESULT_PER_PAGE.toString());

  return (
    <Container>
      <InnerLabel htmlFor={id}>
        <FormattedMessage {...commonMessages.perPageResult} />
      </InnerLabel>
      <InlineSelectField
        id={id}
        required
        value={perPageValue}
        label={<FormattedMessage {...commonMessages.perPage} />}
        onChange={handleChange}
        options={DEFAULT_PER_PAGE_OPTIONS.map(option => ({
          label: option,
          value: option,
        }))}
        withoutMargin
        nbrOfCharacters={perPageValue.length}
      />
    </Container>
  );
};

import {
  Button,
  ButtonLayout,
  ModalFooter,
  ModalSubheader,
} from '@frontend/ui';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import React from 'react';

import {
  benefitDisabilityLevelMessages,
  ownerDescriptionMessages,
} from '../../messages';

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const ConsolidationModal: React.FC<Props> = ({ isOpen, setIsOpen }) => (
  <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
    <ModalHeader>
      <FormattedMessage {...benefitDisabilityLevelMessages.CONSOLIDATION} />
    </ModalHeader>
    <ModalBody>
      <ModalSubheader>
        <FormattedMessage {...ownerDescriptionMessages.jointStockCompany} />
      </ModalSubheader>
      <FormattedMessage
        {...ownerDescriptionMessages.jointStockCompanyDescription}
      />
      <ModalSubheader>
        <FormattedMessage {...ownerDescriptionMessages.generalPartnership} />
      </ModalSubheader>
      <FormattedMessage
        {...ownerDescriptionMessages.generalPartnershipDescription}
      />
      <ModalSubheader>
        <FormattedMessage {...ownerDescriptionMessages.limitedPartnership} />
      </ModalSubheader>
      <FormattedMessage
        {...ownerDescriptionMessages.limitedPartnershipDescription}
      />
      <ModalSubheader>
        <FormattedMessage {...ownerDescriptionMessages.proprietorship} />
      </ModalSubheader>
      <FormattedMessage
        {...ownerDescriptionMessages.proprietorshipDescription}
      />
    </ModalBody>
    <ModalFooter>
      <ButtonLayout align="right">
        <Button text onClick={() => setIsOpen(false)}>
          <FormattedMessage {...commonMessages.cancel} />
        </Button>
      </ButtonLayout>
    </ModalFooter>
  </Modal>
);

import { defineMessages } from 'react-intl';

import { PremiumIntervalsValidationError } from './constants';

export const premiumIntervalValidationMessages =
  defineMessages<PremiumIntervalsValidationError>({
    AGES_ASCENDING: {
      id: 'premiumIntervalValidationMessages.AGES_ASCENDING',
      defaultMessage: 'Åldersintervall måste vara i stigande ordning',
    },
    BASE_AMOUNTS_ASCENDING: {
      id: 'premiumIntervalValidationMessages.BASE_AMOUNTS_ASCENDING',
      defaultMessage: 'Basbeloppsintervall måste vara i stigande ordning',
    },
    BASE_AMOUNT_NOT_OVER_100: {
      id: 'premiumIntervalValidationMessages.BASE_AMOUNT_NOT_OVER_100',
      defaultMessage: 'Basbelopp får inte överstiga 100',
    },
    PREMIUMS_NOT_OVER_100: {
      id: 'premiumIntervalValidationMessages.PREMIUMS_NOT_OVER_100',
      defaultMessage: 'Premier kan inte överstiga 100%',
    },
  });

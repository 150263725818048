import { CheckboxField, DatePickerField, Form, Formik } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import {
  executeProposalQuery,
  executeProposalQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { useQuery } from 'app/utils/use-query';
import { report } from 'components/ErrorBoundary/lib/report';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { TopLoading } from 'components/TopLoading';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import { detect as detectBrowser } from 'detect-browser';
import { smeCompanyProposalsMessages } from 'features/sme/messages/sme';
import qs from 'query-string';
import React, { useMemo } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import { EXECUTE_PROPOSAL_QUERY } from './graphql/queries';
import { getProposalStartDate } from './utils/get-proposal-start-date';
import { useSubmit } from './utils/use-submit';

export const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    signedByRelevantParties: Yup.boolean().required(
      intl.formatMessage(commonMessages.requiredField),
    ),
    dateOfSigning: Yup.string().required(
      intl.formatMessage(commonMessages.requiredField),
    ),
  });

export interface FormValues {
  dateOfSigning: string;
  signedByRelevantParties: boolean;
}

export const initialValues: FormValues = {
  dateOfSigning: '',
  signedByRelevantParties: false,
};

export const ExecuteProposalModal: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const browser = useMemo(() => detectBrowser(), []);
  const intl = useIntl();

  const { 'execute-proposal': proposalId } = qs.parse(location.search);

  const { loading, data, error } = useQuery<
    executeProposalQuery,
    executeProposalQueryVariables
  >(EXECUTE_PROPOSAL_QUERY, {
    errorPolicy: 'all',
    skip: !proposalId,
    variables: {
      id: proposalId,
    },
  });

  const executeProposal = !!proposalId;

  const onRequestClose = () => {
    stripSearchParams(history, location, ['execute-proposal']);
  };

  const { submit, submissionError } = useSubmit({ onRequestClose, proposalId });

  if (!proposalId) {
    return null;
  }

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.proposal?.startDate) {
    // It is assumed that this modal shouldn't ever be used without a start date.
    // Should this ever happen, we want to know about it, though we continue to render the modal
    // so that the user can see the error message.
    report(
      new Error(
        `Attempting send proposal with id ${proposalId} to production but start date was missing`,
      ),
      {
        httpRequest: {
          url: window.location.href,
          userAgent: `${browser?.os}, ${browser?.name}@${browser?.version}`,
        },
        user: 'backstage',
      },
    );
  }

  const min = format(
    subDays(
      data?.proposal?.startDate
        ? new Date(data.proposal.startDate)
        : new Date(),
      30,
    ),
    'yyyy-MM-dd',
  );
  const max = format(new Date(), 'yyyy-MM-dd');
  const startDate = data?.proposal?.startDate;

  return (
    <Modal isOpen={executeProposal} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage
          {...smeCompanyProposalsMessages.confirmSignedProposal}
        />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid, values: { dateOfSigning }, errors }) => (
          <Form>
            <ModalBody>
              {(error || !startDate) && <GraphQlError inModal error={error} />}

              {/* 
                Without start date we cannot proceed with the execution of the proposal,
                and therefore the form elements will not be rendered.
              */}
              {!!startDate && (
                <>
                  <DatePickerField
                    dense
                    label={
                      <FormattedMessage
                        {...smeCompanyProposalsMessages.dateOfSigning}
                      />
                    }
                    name="dateOfSigning"
                    min={min}
                    max={max}
                    required
                  />
                  {!!dateOfSigning && !errors?.dateOfSigning && (
                    <CheckboxField
                      label={
                        <FormattedMessage
                          {...smeCompanyProposalsMessages.signedByAllPartiesConfirmation}
                          values={{
                            startDate: new Date(
                              getProposalStartDate(dateOfSigning, startDate),
                            ),
                          }}
                        />
                      }
                      required
                      name="signedByRelevantParties"
                    />
                  )}
                </>
              )}
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  text
                  type="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  <FormattedMessage
                    {...smeCompanyProposalsMessages.sendToProduction}
                  />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

interface Args {
  location: RouteComponentProps['location'];
  proposalId: string;
}

export const getExecuteProposalLink = ({
  location,
  proposalId,
}: Args): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'execute-proposal': proposalId }),
});

import gql from 'graphql-tag';

export const BENEFIT_SUPPLIERS_QUERY = gql`
  query benefitSuppliersQuery($companyId: ID!) {
    company(id: $companyId) {
      benefitSuppliers {
        id
        name
        logo {
          url
          path
        }
        websiteUrl
        companyId
      }
    }
  }
`;

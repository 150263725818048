import gql from 'graphql-tag';

export const SME_COMPANY_EDIT_DISABILITY_QUERY = gql`
  query smeCompanyEditDisabilityQuery($benefitId: ID!, $proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      companySize
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          ... on ProposalSicknessBenefitConfig {
            converting
            convertingFrom
            extendSalaryLimit
            extendedSickPay
            salaryMultiplier
            sicknessLevel
          }
        }
      }
      benefitPackages {
        id
        name
      }
    }
  }
`;

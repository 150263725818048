import { Form, Formik, SelectField, TextField } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { add } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import {
  ProposalDefaultBenefitPackage,
  proposalSavingsAdviceQuery,
  proposalSavingsAdviceQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { TopLoading } from 'components/TopLoading';
import {
  smeBenefitPackagesMessages,
  smeDefaultBenefitPackagesMessages,
} from 'features/sme/messages/sme';
import qs from 'query-string';
import React, { useId } from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { PROPOSAL_SAVINGS_ADVICE_QUERY } from '../../graphql/queries';
import { hasPensionBenefitAccess as _hasPensionBenefitAccess } from '../../utils/has-pension-benefit-access';
import { useSubmit } from './utils/use-submit';

const BENEFIT_PACKAGE_FIELD_NAME = 'defaultBenefitPackage';

const DEFAULT_BENEFIT_GROUPS_WITH_PENSION: ProposalDefaultBenefitPackage[] = [
  ProposalDefaultBenefitPackage.EMPLOYEE_WITH_RISK,
  ProposalDefaultBenefitPackage.OWNER_FIXED_PREMIUM,
  ProposalDefaultBenefitPackage.OWNER_OR_CEO_FULL_PROTECTION,
];

const DEFAULT_BENEFIT_GROUPS_WITHOUT_PENSION: ProposalDefaultBenefitPackage[] =
  [
    ProposalDefaultBenefitPackage.PROBATIONARY_EMPLOYEE,
    ProposalDefaultBenefitPackage.SICKNESS_ONLY,
  ];

const DEFAULT_BENEFIT_GROUPS: ProposalDefaultBenefitPackage[] = [
  ...DEFAULT_BENEFIT_GROUPS_WITH_PENSION,
  ...DEFAULT_BENEFIT_GROUPS_WITHOUT_PENSION,
];

interface ValidationSchemaArgs {
  /**
   * Designates whether the proposal should allow for pension benefits to be created.
   *
   * This is a temporary measure. Ideally this should be managed by backend
   */
  hasPensionBenefitAccess: boolean;
  intl: IntlShape;
}

const validationSchema = ({
  intl,
  hasPensionBenefitAccess,
}: ValidationSchemaArgs) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
    defaultBenefitPackage: Yup.string<ProposalDefaultBenefitPackage>()
      .nullable()
      .test(
        'pension',
        intl.formatMessage(
          validationMessages.pensionBenefitNotAvailableForBackage,
        ),
        value =>
          !!value && !hasPensionBenefitAccess
            ? !DEFAULT_BENEFIT_GROUPS_WITH_PENSION.includes(value)
            : true,
      ),
  });

export interface FormValues {
  defaultBenefitPackage: ProposalDefaultBenefitPackage | '';
  name: string;
  proposalId: string;
}

export const CreateBenefitPackageModal = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const history = useHistory();
  const location = useLocation();
  const { params } = useRouteMatch<MatchParams>();
  const id = useId();

  const { loading, data, error } = useQuery<
    proposalSavingsAdviceQuery,
    proposalSavingsAdviceQueryVariables
  >(PROPOSAL_SAVINGS_ADVICE_QUERY, {
    errorPolicy: 'all',
    variables: { id: params.proposalId },
  });

  const { 'create-benefit-package': createBenefitPackage } = qs.parse(
    location.search,
  );

  const onRequestClose = () => {
    stripSearchParams(history, location, ['create-benefit-package']);
  };

  const { submit, submissionError } = useSubmit({ onRequestClose, params });

  const initialValues: FormValues = {
    defaultBenefitPackage: '',
    name: '',
    proposalId: params.proposalId,
  };

  if (loading) {
    return <TopLoading />;
  }

  const hasPensionBenefitAccess = _hasPensionBenefitAccess(data?.proposal);

  return (
    <Modal isOpen={createBenefitPackage} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage
          {...smeBenefitPackagesMessages.createBenefitPackage}
        />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema({ intl, hasPensionBenefitAccess })}
      >
        {({ isSubmitting, isValid, errors }) => {
          const benefitPackageError = errors[BENEFIT_PACKAGE_FIELD_NAME];

          return (
            <Form>
              <ModalBody>
                {error && <GraphQlError inModal error={error} />}
                <p>
                  <FormattedMessage
                    {...smeBenefitPackagesMessages.benefitPackageDescription}
                  />
                </p>
                <TextField
                  dense
                  name="name"
                  label={<FormattedMessage {...commonMessages.name} />}
                  required
                />
                <SelectField
                  dense
                  name={BENEFIT_PACKAGE_FIELD_NAME}
                  fixed
                  label={
                    <FormattedMessage
                      {...smeBenefitPackagesMessages.selectDefaultBenefitPackage}
                    />
                  }
                  options={DEFAULT_BENEFIT_GROUPS.map(
                    defaultBenefitPackage => ({
                      label: formatMessage({
                        select: defaultBenefitPackage,
                        messages: smeDefaultBenefitPackagesMessages,
                      }),
                      value: defaultBenefitPackage,
                    }),
                  )}
                  // This is a temporary solution to make the error message appear
                  // as our select field does not support error messages yet
                  aria-errormessage={benefitPackageError ? id : undefined}
                  aria-invalid={!!benefitPackageError}
                />
                {!!benefitPackageError && (
                  <NotificationCard type="error" inModal id={id}>
                    {benefitPackageError}
                  </NotificationCard>
                )}
                {submissionError && (
                  <GraphQlError inModal error={submissionError} />
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonLayout align="right">
                  <Button text onClick={onRequestClose}>
                    <FormattedMessage {...commonMessages.cancel} />
                  </Button>
                  <Button
                    disabled={!isValid}
                    loading={isSubmitting}
                    text
                    type="submit"
                  >
                    <FormattedMessage {...commonMessages.create} />
                  </Button>
                </ButtonLayout>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const getCreateBenefitPackageLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'create-benefit-package': true }),
});

export const CreateBenefitPackageAssistChip: React.FC = () => {
  const location = useLocation();
  const isReadonly = useProposalReadonly();

  return (
    <AssistChip
      text={
        <FormattedMessage
          {...smeBenefitPackagesMessages.createBenefitPackage}
        />
      }
      leadingIcon={add}
      to={getCreateBenefitPackageLink(location)}
      disabled={isReadonly}
      collapseMarginBottom
    />
  );
};

import { InlineChevronTd, TableRowProps, Td, Tr } from '@frontend/ui';
import type { TdProps } from '@frontend/ui/components/Table/components';
import React, {
  createContext,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';

interface CollapseContext {
  addId: (id: string) => void;
  collapsed: boolean;
  ids: string[];
}

const CollapseContext = createContext<CollapseContext>({
  collapsed: false,
  ids: [],
  addId: (id: string) => id,
});

interface Props extends TableRowProps {
  subrows: React.ReactNode;
}

export const CollapsibleTr: React.FC<Props> = ({
  subrows,
  children,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [ids, setIds] = useState<string[]>([]);
  const addId = (id: string) => setIds(_ids => [..._ids, id]);

  const value = useMemo(() => ({ collapsed, ids, addId }), [collapsed, ids]);

  return (
    <>
      <Tr {...props}>
        <InlineChevronTd
          expanded={!collapsed}
          toggle={() => setCollapsed(_collapsed => !_collapsed)}
          ids={ids.join(' ')}
        />
        {children}
      </Tr>
      <CollapseContext.Provider value={value}>
        {subrows}
      </CollapseContext.Provider>
    </>
  );
};

export const SubTr: React.FC<TableRowProps> = ({ children, ...props }) => {
  const { collapsed, addId, ids } = useContext(CollapseContext);
  const id = useId();

  useEffect(() => {
    if (!ids.includes(id)) {
      addId(id);
    }
  }, [addId, id, ids]);

  return (
    <Tr {...props} subrow collapsible hide={collapsed} id={id}>
      <Td chevron collapsible hide={collapsed} />
      {children}
    </Tr>
  );
};

export const SubTd: React.FC<TdProps> = props => {
  const { collapsed } = useContext(CollapseContext);
  return <Td {...props} collapsible hide={collapsed} />;
};

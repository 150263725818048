import { useFormikContext } from '@frontend/formik';
import { Subsection } from '@frontend/ui';
import { Attachments } from 'components/Attachments';
import React from 'react';

import { BenefitFormValues } from '../../form';
import { AttachmentsModal } from '../AttachmentsModal';

interface Props {
  deleteAction?: (func: () => void) => React.ReactNode;
}

export const EditAttachments: React.FC<Props> = ({ deleteAction }) => {
  const formikContext = useFormikContext<BenefitFormValues>();
  const { attachments } = formikContext.values.content;

  const handleRemoveAllAttachments = () => {
    formikContext.setFieldValue('content.attachments', []);
  };

  return (
    <Subsection>
      <Attachments attachments={attachments} />
      <AttachmentsModal />
      {deleteAction && deleteAction(handleRemoveAllAttachments)}
    </Subsection>
  );
};

import {
  Icon,
  Illustration as _Illustration,
  Menu,
  MenuChevron as _MenuChevron,
  MenuItem,
  MenuSurfaceAnchor,
} from '@frontend/ui';
import { arrowDropDown, check } from '@frontend/ui/icons';
import { commonMessages } from 'app/messages/common';
import { menuMessages } from 'app/messages/menu';
import greatBritain from 'assets/illustrations/greatBritain.svg';
import sweden from 'assets/illustrations/sweden.svg';
import { useIntl } from 'components/formats';
import { EffectiveLocale } from 'contexts/IntlProviderWrapper';
import React, { useId, useState } from 'react';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  margin-top: 3.25rem;
  min-width: 13.375rem;
`;

const ToggleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0.25rem 0;
`;

const Illustration = styled(_Illustration)`
  margin-right: 0.625rem;
`;

const MenuChevron = styled(_MenuChevron)`
  margin-left: 1rem;
`;

interface Language {
  icon: string;
  label: string;
  locale: EffectiveLocale;
}

export const LANGUAGES: Language[] = [
  { locale: 'sv-SE', icon: sweden, label: 'Svenska' },
  { locale: 'en-US', icon: greatBritain, label: 'English' },
];

interface Props {
  setLocale: (locale: EffectiveLocale) => void;
  value: EffectiveLocale | undefined;
}

export const LanguageSelector: React.FC<Props> = ({ setLocale, value }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { formatMessage } = useIntl();
  const id = useId();

  const currentLanguage =
    LANGUAGES.find(language => language.locale === value) ?? LANGUAGES[0];

  const handleClick = selectedLocale => {
    setLocale(selectedLocale);
  };

  return (
    <MenuSurfaceAnchor>
      <StyledMenu
        id={id}
        open={isMenuOpen}
        onClose={() => setMenuOpen(false)}
        fixed
        label={formatMessage(commonMessages.language)}
      >
        {LANGUAGES.map(({ locale, label, icon }) => (
          <MenuItem
            key={locale}
            onClick={() => handleClick(locale)}
            icon={<Illustration size="small" src={icon} alt="" />}
            trailingIcon={
              locale === value && (
                <Icon
                  size="medium"
                  icon={check}
                  color="onSurfaceVariant"
                  decorative
                />
              )
            }
          >
            {label}
          </MenuItem>
        ))}
      </StyledMenu>
      <ToggleButton
        onClick={e => {
          e.preventDefault();
          setMenuOpen(!isMenuOpen);
        }}
        aria-label={formatMessage(menuMessages.chooseLanguage)}
        aria-haspopup
        aria-expanded={isMenuOpen}
        aria-controls={id}
      >
        <Illustration size="small" src={currentLanguage.icon} alt="" />
        {currentLanguage.label}
        <MenuChevron isOpen={isMenuOpen} size="small" icon={arrowDropDown} />
      </ToggleButton>
    </MenuSurfaceAnchor>
  );
};

import {
  BodyLarge,
  BodyMedium,
  ChipSet as _ChipSet,
  CircularMedia,
  CircularMediaProps as _CircularMediaProps,
  ContentContainer,
  Grid,
  GridCell,
  HeadlineSmall,
  Hero as _Hero,
  media,
  PAGE_HEADER_OFFSET,
} from '@frontend/ui';
import type { ThemeColor } from '@frontend/ui/theme';
import { useIsSmallScreen } from 'app/utils/use-is-small-screen';
import { Props as AssistChipProps } from 'components/AssistChip';
import { RoutedTab, RoutedTabBar } from 'components/RoutedTabBar';
import { Page } from 'features/page';
import React from 'react';
import styled from 'styled-components';
import { MOBILE_SCREEN_RESOLUTION } from 'utils/constants';

const RelativeContainer = styled.div`
  position: relative;
`;

const Hero = styled(_Hero)`
  border-radius: 1.75rem;
`;

interface HeroImageProps {
  children?: React.ReactNode;
  withHeroImage?: boolean;
}

type CircularMediaProps = _CircularMediaProps & HeroImageProps;

const CircularMediaWrapper = styled.div<CircularMediaProps>`
  ${p =>
    p.withHeroImage &&
    `
    position: absolute;
    top: -4.5rem;
    border: 0.5rem solid ${p.theme.surface};
  `}

  ${media.lessThan('tablet')<CircularMediaProps>`
    ${p => p.withHeroImage && `top: -2.5rem;`}
  `};

  border-radius: 50%;
  box-sizing: content-box;
`;

export const Logo: React.FC<CircularMediaProps> = ({
  withHeroImage,
  image,
  children,
}) => {
  const isSmallScreen = useIsSmallScreen(MOBILE_SCREEN_RESOLUTION);

  return (
    <RelativeContainer>
      <CircularMediaWrapper withHeroImage={withHeroImage}>
        <CircularMedia
          size={isSmallScreen ? 'large' : 'xlarge'}
          image={image}
        />
        {children}
      </CircularMediaWrapper>
    </RelativeContainer>
  );
};

interface MediaContainerProps {
  withHeroImage?: boolean;
  withLogo?: boolean;
}

export const MediaContainer = styled.div<MediaContainerProps>`
  ${p =>
    p.withHeroImage &&
    `
      margin-left: 0.5rem;
      margin-right: 0.5rem;
  `}

  ${p =>
    p.withHeroImage
      ? p.withLogo
        ? `margin-top: -${PAGE_HEADER_OFFSET}rem; margin-bottom: 5.5rem;`
        : `margin-top: -${PAGE_HEADER_OFFSET}rem; margin-bottom: 1.5rem;`
      : `margin-bottom: 1.5rem;`};

  ${media.lessThan('tablet')<MediaContainerProps>`
    ${p => p.withHeroImage && p.withLogo && `margin-bottom: 3.5rem;`}
  `}
`;

export const HeaderContainer = styled.div`
  margin-bottom: 1.5rem;
`;

interface TextContainerProps {
  noChips?: boolean;
}

export const TextContainer = styled.div<TextContainerProps>`
  margin-bottom: ${p => (p.noChips ? '3rem' : '1.5rem')};
`;

export const Overline = styled(BodyMedium)`
  display: block;
  color: ${p => p.theme.onSurfaceVariant};
  margin-bottom: 0.5rem;
`;

export const Title = styled(HeadlineSmall)`
  display: block;
`;

export const Description = styled(BodyLarge)<{ color?: ThemeColor }>`
  display: flex;
  margin-top: 1rem;
  color: ${p => (p.color ? p.theme[p.color] : p.theme.onSurfaceVariant)};
  align-items: center;
`;

export const ChipSet = styled(_ChipSet)`
  margin-top: 0.75rem;
  margin-bottom: 0rem;
`;

interface Props {
  children: React.ReactNode;
  title: React.ReactNode;
  actions?: AssistChipProps[];
  chips?: React.ReactNode;
  description?: React.ReactNode;
  descriptionColor?: ThemeColor;
  heroImage?: string | null;
  logoUrl?: string;
  overline?: React.ReactNode;
  parentLink?: string;
  tabs?: RoutedTab[];
  withinPage?: boolean;
}

export const FeaturePageLayout: React.FC<Props> = ({
  title,
  parentLink,
  heroImage,
  logoUrl,
  overline,
  description,
  descriptionColor,
  chips,
  children,
  actions,
  withinPage,
  tabs,
}) => {
  const content = (
    <>
      <HeaderContainer>
        <MediaContainer withHeroImage={!!heroImage} withLogo={!!logoUrl}>
          {heroImage && <Hero image={heroImage} />}
          <ContentContainer>
            {logoUrl && <Logo image={logoUrl} withHeroImage={!!heroImage} />}
          </ContentContainer>
        </MediaContainer>
        <ContentContainer>
          <TextContainer noChips={!chips}>
            <Grid>
              <GridCell span={8}>
                {overline && <Overline>{overline}</Overline>}
                {title && <Title>{title}</Title>}
                {description && (
                  <Description color={descriptionColor}>
                    {description}
                  </Description>
                )}
              </GridCell>
            </Grid>
          </TextContainer>
          {chips && <ChipSet>{chips}</ChipSet>}
          {tabs && <RoutedTabBar tabs={tabs} />}
        </ContentContainer>
      </HeaderContainer>
      <ContentContainer>{children}</ContentContainer>
    </>
  );

  if (withinPage) {
    return content;
  }

  return (
    <Page parentLink={parentLink} actions={actions}>
      {content}
    </Page>
  );
};

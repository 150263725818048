import { useFormikContext } from '@frontend/formik';
import { Button, ButtonLayout, Fab, Icon, ModalFooter } from '@frontend/ui';
import { addPhotoAlternative } from '@frontend/ui/icons';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages } from 'app/messages/common';
import { FormattedMessage, useIntl } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import React, { useState } from 'react';

import { BenefitFormValues, UploadableImage } from '../../form';
import { ImageUpload } from '../ImageUpload';

export const HeroModal: React.FC = () => {
  const intl = useIntl();
  const { values, setFieldValue } = useFormikContext<BenefitFormValues>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File>();
  const [previewUrl, setPreviewUrl] = useState<string>();

  const handleDropAccepted = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setPreviewUrl(URL.createObjectURL(file));
    setImageFile(file);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    if (!imageFile || !previewUrl) {
      return;
    }
    const value: UploadableImage = {
      name: imageFile.name,
      file: imageFile,
      url: previewUrl,
    };
    setFieldValue('content.heroImage', value);
    setIsModalOpen(false);
  };

  return (
    <>
      <Fab
        mini
        label={intl.formatMessage(commonBenefitMessages.changeCoverImage)}
        onClick={() => setIsModalOpen(!isModalOpen)}
        icon={<Icon icon={addPhotoAlternative} size="medium" decorative />}
      />
      <Modal
        size="small"
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ModalHeader>
          <FormattedMessage {...commonBenefitMessages.changeCoverImage} />
        </ModalHeader>
        <ModalBody>
          <ImageUpload
            onDropAccepted={handleDropAccepted}
            previewUrl={previewUrl ?? values.content.heroImage?.url}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonLayout align="right">
            <Button onClick={handleClose} text>
              <FormattedMessage {...commonMessages.cancel} />
            </Button>
            <Button onClick={handleSubmit} text disabled={!imageFile}>
              <FormattedMessage {...commonMessages.confirm} />
            </Button>
          </ButtonLayout>
        </ModalFooter>
      </Modal>
    </>
  );
};

/* eslint-disable */
import { SelectField } from '@frontend/ui';
import React, { useEffect, useState } from 'react';

interface BlockType {
  doCollapse: () => void;
  doExpand: () => void;
  onChange: (input: any) => void;
  onExpandEvent: () => void;
  config?: { [key: string]: any };
  expanded?: boolean;
  currentState?: { [key: string]: any };
  translations?: { [key: string]: any };
}

export const BlockType: React.FC<BlockType> = ({
  translations,
  config,
  currentState,
  onChange,
}) => {
  const getBlockTypes = _translations => [
    {
      label: 'Normal',
      displayName: _translations['components.controls.blocktype.normal'],
    },
    {
      label: 'H1',
      displayName: _translations['components.controls.blocktype.h1'],
    },
    {
      label: 'H2',
      displayName: _translations['components.controls.blocktype.h2'],
    },
    {
      label: 'H3',
      displayName: _translations['components.controls.blocktype.h3'],
    },
    {
      label: 'H4',
      displayName: _translations['components.controls.blocktype.h4'],
    },
    {
      label: 'H5',
      displayName: _translations['components.controls.blocktype.h5'],
    },
    {
      label: 'H6',
      displayName: _translations['components.controls.blocktype.h6'],
    },
    {
      label: 'Blockquote',
      displayName: _translations['components.controls.blocktype.blockquote'],
    },
    {
      label: 'Code',
      displayName: _translations['components.controls.blocktype.code'],
    },
  ];

  const [blockTypes, setBlockTypes] = useState(getBlockTypes(translations));

  useEffect(() => {
    setBlockTypes(getBlockTypes(translations));
  }, [translations]);

  const blocks = blockTypes.filter(
    ({ label }) => config?.options.indexOf(label) > -1,
  );
  const currentBlockData = blockTypes.filter(
    b => b.label === currentState?.blockType,
  );
  const currentLabel =
    currentBlockData?.length && currentBlockData[0]?.displayName;

  return (
    <SelectField
      plain
      required
      value={currentState?.blockType}
      label={
        currentLabel ||
        (!!translations &&
          translations['components.controls.blocktype.blocktype'])
      }
      onChange={e => !!onChange && onChange(e.detail.value)}
      options={blocks.map(block => ({
        label: block.displayName,
        value: block.label,
      }))}
      withoutMargin
    />
  );
};

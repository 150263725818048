import { defineMessages } from 'react-intl';

export const campaignCodeMessages = defineMessages({
  campaignCode: {
    id: 'campaignCodeMessages.campaignCode',
    defaultMessage: 'Kampanjkod',
  },
  copyCode: {
    id: 'campaignCodeMessages.copyCode',
    defaultMessage: 'kopiera kod',
  },
});

import { useMutation } from '@apollo/client';
import { Section } from '@frontend/ui';
import { edit, sync } from '@frontend/ui/icons';
import {
  companyDetailsQuery,
  companyDetailsQueryVariables,
  refreshExternalCompanyDetailsMutation,
  refreshExternalCompanyDetailsMutationVariables,
} from 'app/apollo/graphql/types';
import { commonMessages } from 'app/messages/common';
import { companyMessages } from 'app/messages/company';
import { MatchParams as CompanyMatchParams } from 'app/pages/companies/company';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { ChipsWrapper } from 'components/ChipsWrapper';
import { DescriptionTable } from 'components/DescriptionTable';
import { FormattedMessage, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { TopLoading } from 'components/TopLoading';
import { REFRESH_EXTERNAL_COMPANY_DETAILS_MUTATION } from 'features/companies/graphql/mutations';
import { COMPANY_DETAILS_QUERY } from 'features/companies/graphql/queries';
import { useNotification } from 'features/notifications';
import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router';

import { LoginMethodTable } from './components/LoginMethodTable';
import { NewsLetterSettings } from './components/NewsLetterSettings';
import { getCompanyDetails } from './utils/get-company-details';

export interface CompanyDetailRow {
  id: string;
  label: React.ReactNode;
  value: null | React.ReactNode;
  type?: 'number';
}

export const CompanyDetails: React.FC<
  RouteComponentProps<CompanyMatchParams>
> = ({
  match: {
    params: { companyId },
  },
}) => {
  const match = useRouteMatch();
  const intl = useIntl();
  const { data, loading, error } = useQuery<
    companyDetailsQuery,
    companyDetailsQueryVariables
  >(COMPANY_DETAILS_QUERY, {
    skip: !companyId,
    errorPolicy: 'all',
    variables: { id: companyId },
  });

  const [
    refreshExternalCompanyDetails,
    { loading: updateLoading, error: updateError },
  ] = useMutation<
    refreshExternalCompanyDetailsMutation,
    refreshExternalCompanyDetailsMutationVariables
  >(REFRESH_EXTERNAL_COMPANY_DETAILS_MUTATION, {
    variables: { input: { id: companyId, refreshExternalInformation: true } },
  });

  const { send } = useNotification();

  if (loading) {
    return <TopLoading />;
  }

  if (!data?.company) {
    return <GraphQlError error={error} />;
  }

  const { company } = data;
  const companyDetails = getCompanyDetails(company, intl);

  return (
    <>
      {error && <GraphQlError error={error} />}
      <Section>
        <ChipsWrapper>
          <AssistChip
            to={`${match.url}/edit`}
            text={
              <FormattedMessage {...commonMessages.updateInformationDetails} />
            }
            leadingIcon={edit}
          />
          <AssistChip
            text={
              <FormattedMessage
                {...companyMessages.fetchNewCompanyInformation}
              />
            }
            leadingIcon={sync}
            loading={updateLoading}
            onClick={async () => {
              try {
                await refreshExternalCompanyDetails({
                  variables: {
                    input: { id: companyId, refreshExternalInformation: true },
                  },
                });

                send({
                  message: intl.formatMessage(
                    companyMessages.fetchNewCompanyInformationSuccess,
                  ),
                  type: 'success',
                });
              } catch (e) {
                // Do nothing
              }
            }}
          />
        </ChipsWrapper>
        {updateError && <GraphQlError error={updateError} />}
        <DescriptionTable
          alignLeft
          entries={companyDetails.map(detail => ({
            id: detail.id,
            label: detail.label,
            value: detail.value,
          }))}
        />
      </Section>
      <LoginMethodTable
        id={company.id}
        emailLoginAllowed={company.emailLoginAllowed}
      />
      <NewsLetterSettings
        emailLanguage={company.employeeEmailLanguage}
        newsletterOptOut={company.newsletterOptOut}
        companyId={companyId}
      />
    </>
  );
};

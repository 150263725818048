export const EXTERNAL_URLS = {
  collectum: 'https://www.collectum.se/',
  epassiBikeStandardPolicyHref: 'https://epassibike.se/pages/policy',
  skatteverketHealthcareRules:
    'https://skatteverket.se/privat/skatter/arbeteochinkomst/formaner/personalvardmotionochfriskvard.4.7459477810df5bccdd4800014540.html',
  skatteverketPensionPremiums:
    'https://www4.skatteverket.se/rattsligvagledning/edition/2019.3/339027.html',
  nordeaNodeMovieTicketAgreement:
    'https://docs.nordeanode.se/node/movie-ticket-precontract-terms-2024-02-01.pdf',
};

import { ApolloError } from '@apollo/client';
import { ChipGroupField, Form, Formik, TextField } from '@frontend/formik';
import { Button, ButtonLayout, Icon, ModalFooter } from '@frontend/ui';
import { check } from '@frontend/ui/icons';
import {
  CompanyBillingAttachmentFormat,
  companyBillingConfigurationDetails,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { isEmail } from 'validations';
import * as Yup from 'yup';

import { billingMessages } from '../../messages';

const BILLING_FORMATS = Object.values(CompanyBillingAttachmentFormat);

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'valid email',
        intl.formatMessage(validationMessages.isValidEmail),
        value => isEmail(value),
      ),
    attachmentFormats: Yup.array().min(
      1,
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

const ButtonWrapper = styled.div<{ hasDelete: boolean }>`
  ${p =>
    p.hasDelete &&
    `
    display: flex;
    justify-content: space-between;
  `}
`;

export interface FormValues {
  attachmentFormats: readonly CompanyBillingAttachmentFormat[];
  email: string;
}

interface Props {
  isOpen: boolean;
  label: string;
  onRequestClose: () => void;
  onSubmit: (formValues: FormValues) => Promise<void>;
  billingEmail?: companyBillingConfigurationDetails;
  error?: ApolloError;
  onRequestDelete?: () => Promise<void>;
}

export const BillingEmailModal: React.FC<Props> = ({
  billingEmail,
  onRequestDelete,
  onRequestClose,
  isOpen,
  onSubmit,
  error,
  label,
}) => {
  const intl = useIntl();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        initialValues={{
          email: billingEmail?.email ?? '',
          attachmentFormats: billingEmail?.attachmentFormats ?? [],
        }}
        enableReinitialize
        validationSchema={validationSchema(intl)}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <ModalHeader>{label}</ModalHeader>
            <ModalBody>
              <p>
                <FormattedMessage {...billingMessages.billingEmailNote} />
              </p>
              <TextField
                type="email"
                name="email"
                label={<FormattedMessage {...commonMessages.email} />}
              />
              <ChipGroupField
                required
                name="attachmentFormats"
                selectedIcon={<Icon icon={check} decorative />}
                legend={
                  <FormattedMessage {...billingMessages.attachmentsRequired} />
                }
                options={BILLING_FORMATS.map(format => ({
                  label: format,
                  value: format,
                }))}
              />
              {error && <GraphQlError inModal error={error} />}
            </ModalBody>
            <ModalFooter>
              <ButtonWrapper hasDelete={!!onRequestDelete}>
                {onRequestDelete && (
                  <Button
                    danger
                    loading={deleteLoading}
                    onClick={async () => {
                      try {
                        setDeleteLoading(true);
                        await onRequestDelete();
                        setDeleteLoading(false);
                      } catch {
                        // Do nothing, error is handled in apollo hook
                      }
                    }}
                  >
                    <FormattedMessage {...billingMessages.deleteBillingEmail} />
                  </Button>
                )}
                <ButtonLayout align="right">
                  <Button text onClick={onRequestClose}>
                    <FormattedMessage {...formMessages.cancel} />
                  </Button>
                  <Button
                    type="submit"
                    filled
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    <FormattedMessage {...formMessages.save} />
                  </Button>
                </ButtonLayout>
              </ButtonWrapper>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

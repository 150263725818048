import { Form, Formik, TextField } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { stripSearchParams } from '@frontend/utils';
import { commonBenefitMessages } from 'app/messages/benefits';
import { commonMessages, validationMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import qs from 'query-string';
import React from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router';
import * as Yup from 'yup';

import { useSubmit } from './utils/use-submit';

export interface FormValues {
  name: string;
}

const validationSchema = (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(validationMessages.mandatoryField),
    ),
  });

export const CreateBenefitPackageModal = () => {
  const intl = useIntl();

  const history = useHistory();
  const location = useLocation();

  const search = qs.parse(location.search);

  const isOpen = 'create' in search;

  const onRequestClose = () => {
    stripSearchParams(history, location, ['create']);
  };

  const { submit, submissionError } = useSubmit({
    onRequestClose,
  });

  const initialValues: FormValues = {
    name: '',
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} size="medium">
      <ModalHeader>
        <FormattedMessage {...commonBenefitMessages.createBenefitPackage} />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={submit}
        validateOnMount
        validationSchema={validationSchema(intl)}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <ModalBody>
              <TextField
                dense
                name="name"
                label={<FormattedMessage {...commonMessages.name} />}
                required
              />
              {submissionError && (
                <GraphQlError inModal error={submissionError} />
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
                <Button
                  disabled={!isValid}
                  loading={isSubmitting}
                  text
                  type="submit"
                >
                  <FormattedMessage {...formMessages.save} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

interface Args {
  location: RouteComponentProps['location'];
}

export const getCreateBenefitPackageLink = ({
  location,
}: Args): RouteComponentProps['location'] => ({
  ...location,
  search: 'create',
});

import { discountPresentationQuery_benefit_FlexBenefit as Benefit } from 'app/apollo/graphql/types';
import { EffectiveLocale } from 'contexts/IntlProviderWrapper';

import { BenefitFormValues } from '../../form';

interface InitialValues {
  initialValues: BenefitFormValues;
}

export const getInitialValuesFromBenefit = (
  benefit?: Benefit | null,
  locale?: EffectiveLocale,
): InitialValues | null => {
  if (!benefit) {
    return null;
  }

  const {
    id,
    content: {
      title,
      short,
      heroImage,
      heroImageAlignment,
      body,
      callToAction,
      attachments,
    },
    supplier: { name, logo, id: supplierId, websiteUrl, companyId },
    categories,
    configuration,
  } = benefit;

  return {
    initialValues: {
      id,
      content: {
        title,
        short,
        locale: locale ?? 'sv-SE',
        heroImage: heroImage
          ? {
              url: heroImage.url,
              path: heroImage.path,
            }
          : undefined,
        heroImageAlignment,
        body,
        callToAction: callToAction.map(cta => ({
          title: cta.title,
          link: cta.link,
          type: cta.type,
        })),
        attachments: attachments.map(attachment => ({
          name: attachment.name,
          path: attachment.path,
          url: attachment.url,
        })),
      },
      supplier: {
        id: supplierId,
        name,
        logo: logo
          ? {
              url: logo.url,
              path: logo.path,
            }
          : undefined,
        websiteUrl,
        companyId,
      },
      categories: categories.map(c => ({
        companyId: c.companyId,
        name: c.name,
        id: c.id,
        locale: c.locale,
      })),
      configuration,
    },
  };
};

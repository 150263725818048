import gql from 'graphql-tag';

export const SME_UPDATE_MISSING_COMPANY_DETAILS_MUTATION = gql`
  mutation smeUpdateMissingCompanyDetailsMutation($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      company {
        id
        accountNumber
        clearingCode {
          code
        }
        paymentMethod
      }
    }
  }
`;

export const SME_GENERATE_PROPOSAL_MUTATION = gql`
  mutation smeGenerateProposalMutation($input: GenerateProposalInput!) {
    generateProposal(input: $input) {
      id
      status
      documents {
        offer {
          name
          path
          url
        }
        otherDocuments {
          name
          path
          url
        }
      }
    }
  }
`;

import { FlexSalaryExchangeRequestStatus } from 'app/apollo/graphql/types';
import { defineMessages } from 'react-intl';

export const companySalaryExchangeMessages = defineMessages({
  upcomingSalaryExchangesEffectiveFrom: {
    id: 'companySalaryExchangeMessages.upcomingSalaryExchangesEffectiveFrom',
    defaultMessage: 'Nya löneväxlingar fr.o.m nästa månad',
  },
  salaryExchanges: {
    id: 'companySalaryExchangeMessages.salaryExchanges',
    defaultMessage: 'Löneväxlingar',
  },
  onetimeSalaryExchanges: {
    id: 'companySalaryExchangeMessages.onetimeSalaryExchanges',
    defaultMessage: 'Bonusväxlingar',
  },
  reviewSalaryExchangeDesc: {
    id: 'companySalaryExchangeMessages.reviewSalaryExchangeDesc',
    defaultMessage:
      'Det finns löneväxlingar som inte längre följer er löneväxlingspolicy. Detta kan bero på att anställda har fått ändrad inkomst, så att löneavdraget är för högt.',
  },
  salaryExchangesCount: {
    id: 'companySalaryExchangeMessages.salaryExchanges',
    defaultMessage:
      '{count, number} {count, plural, one {löneväxling} other {löneväxlingar}}',
  },
  upcomingSalaryExchanges: {
    id: 'companySalaryExchangeMessages.upcomingSalaryExchanges',
    defaultMessage: 'Nya löneväxlingar',
  },
  upcomingSalaryExchangesDesc: {
    id: 'companySalaryExchangeMessages.upcomingSalaryExchangesDesc',
    defaultMessage: 'Fr.o.m nästa månad',
  },
  salaryExchangeRequestsCount: {
    id: 'companySalaryExchangeMessages.salaryExchangeRequestsCount',
    defaultMessage:
      '{count, number} {count, plural, one {ansökning} other {ansökningar}}',
  },
  violatedSalaryExchangeLabel: {
    id: 'companySalaryExchangeMessages.violatedSalaryExchangeLabel',
    defaultMessage: 'Strider mot policy',
  },
  noSalaryExchangeItems: {
    id: 'companySalaryExchangeMessages.noSalaryExchangeItems',
    defaultMessage: 'Inga löneväxlingar',
  },
  benefitQualifyingIncome: {
    id: 'companySalaryExchangeMessages.benefitQualifyingIncome',
    defaultMessage: 'Förmånsgrundande lön',
  },
  activeStatusTooltip: {
    id: 'companySalaryExchangeMessages.activeStatusTooltip',
    defaultMessage: `Löneavdrag gäller från och med {effectiveDate}`,
  },
  inactiveStatusTooltip: {
    id: 'companySalaryExchangeMessages.inactiveStatusTooltip',
    defaultMessage: `Löneväxling avslutas från och med {effectiveDate}`,
  },
  violatedStatusTooltip: {
    id: 'companySalaryExchangeMessages.violatedStatusTooltip',
    defaultMessage: `Löneavdraget strider mot policy`,
  },
  deduction: {
    id: 'companySalaryExchangeMessages.deduction',
    defaultMessage: 'Avdrag',
  },
  premium: {
    id: 'companySalaryExchangeMessages.premium',
    defaultMessage: 'Premie',
  },
  stopSalaryExchange: {
    id: 'companySalaryExchangeMessages.stopSalaryExchange',
    defaultMessage: `Avsluta {count, plural,
      =1 {löneväxling}
      other {löneväxlingar}
    }`,
  },
  noSalaryExchangeRequestItems: {
    id: 'companySalaryExchangeMessages.noSalaryExchangeRequestItems',
    defaultMessage: 'Inga löneväxlingar att hantera',
  },
  noSalaryExchangeRequestItemsText: {
    id: 'companySalaryExchangeMessages.noSalaryExchangeRequestItemsText',
    defaultMessage:
      'När det finns löneväxlingar att hantera kommer du se dem här.',
  },
  terminateSalaryExchangeSubmitError: {
    id: 'companySalaryExchangeMessages.terminateSalaryExchangeSubmitError',
    defaultMessage: 'Det gick inte att avsluta några löneväxlingar',
  },
  comments: {
    id: 'companySalaryExchangeMessages.comments',
    defaultMessage: 'Kommentar',
  },
  canceledSalaryExchangesCount: {
    id: 'companySalaryExchangeMessages.canceledSalaryExchangesCount',
    defaultMessage:
      '{count, number} {count, plural, one {löneväxling avslutad} other {löneväxlingar avslutade}}',
  },
  confirmTerminateTitle: {
    id: 'salaryExchangeAdminMessages.confirmTerminateTitle',
    defaultMessage: 'Avsluta löneväxlingar?',
  },
  confirmTerminate: {
    id: 'companySalaryExchangeMessages.confirmTerminate',
    defaultMessage:
      'Valda löneväxlingar kommer att avslutas från och med nästa månad.',
  },
  salaryExchangeRequestsDesc: {
    id: 'companySalaryExchangeMessages.salaryExchangeRequestsDesc',
    defaultMessage:
      'Om löneväxlingen godkänts aktiveras den för den anställde. Avtal om löneväxlingen finns under dokument, den anställde hittar sitt avtal inloggad i sin portal. Glöm inte att justera löneavdraget för den anställde.\n\nAnnars får den anställde ett meddelande om att begäran nekats och lägger du till en kommentar visas den för den anställde. Det är endast den aktuella begäran som nekas, finns det redan en aktiv löneväxling så påverkas den inte.',
  },
  reject: {
    id: 'companySalaryExchangeMessages.reject',
    defaultMessage: `Avslå begäran om löneväxling`,
  },
  rejectUpcoming: {
    id: 'companySalaryExchangeMessages.rejectUpcoming',
    defaultMessage: `Avsluta nya löneväxlingar`,
  },
  rejectAdvinansDesc: {
    id: 'companySalaryExchangeMessages.rejectAdvinansDesc',
    defaultMessage: `Den anställde får ett meddelande om att begäran nekats och lägger du till en kommentar visas den för den anställde. Det är endast den aktuella begäran som nekas, finns det redan en aktiv löneväxling så påverkas den inte.`,
  },
  rejectSubmitErrorMessagesTitle: {
    id: 'companySalaryExchangeMessages.rejectSubmitErrorMessagesTitle',
    defaultMessage: 'En eller flera ansökningar kunde inte nekas',
  },
  accept: {
    id: 'companySalaryExchangeMessages.accept',
    defaultMessage: `Godkänn begäran om löneväxling`,
  },
  approvalAdvinansDesc: {
    id: 'companySalaryExchangeMessages.approvalAdvinansDesc',
    defaultMessage: `När löneväxlingen godkänts aktiveras den för den anställde. Avtal om löneväxlingen finns under dokument, den anställde hittar sitt avtal inloggad i sin portal. Glöm inte att justera löneavdraget för den anställde.`,
  },
  approvalCollectumDesc: {
    id: 'companySalaryExchangeMessages.approvalCollectumDesc',
    defaultMessage: `Löneväxlingar genom Collectum kräver månadsvis rapportering av premier. Ni hittar uppdaterad rapportfil i vår tjänst. Filen ska ha skickats in via Collectums Internetkontor innan månadens fakturakörning som är runt den 15:e. Kontrollera datum på {collectumLink}`,
  },
  collectumSiteLinkText: {
    id: 'companySalaryExchangeMessages.collectumSiteLinkText',
    defaultMessage: `Collectums hemsida`,
  },
  approveSubmitErrorMessagesTitle: {
    id: 'companySalaryExchangeMessages.approveSubmitErrorMessagesTitle',
    defaultMessage: 'En eller flera ansökningar kunde inte godkännas',
  },
  confirmApproveTitle: {
    id: 'salaryExchangeAdminMessages.confirmApproveTitle',
    defaultMessage: 'Godkänn löneväxlingar?',
  },
  confirmApprove: {
    id: 'companySalaryExchangeMessages.confirmApprove',
    defaultMessage:
      'Valda löneväxlingar kommer att godkännas och börjar gälla från och med nästa månad.',
  },
  acceptSubmitSuccessMessage: {
    id: 'companySalaryExchangeMessages.acceptSubmitSuccessMessage',
    defaultMessage: `{count, plural, =1 {1 begäran} other {# begäranden}} om löneväxling har godkänts.`,
  },
  confirmRejectTitle: {
    id: 'salaryExchangeAdminMessages.confirmRejectTitle',
    defaultMessage: 'Neka löneväxlingar?',
  },
  confirmReject: {
    id: 'companySalaryExchangeMessages.confirmReject',
    defaultMessage: 'Valda löneväxlingar kommer att nekas.',
  },
  rejectSubmitSuccessMessage: {
    id: 'companySalaryExchangeMessages.rejectSubmitSuccessMessage',
    defaultMessage: `{count, plural, =1 {1 begäran} other {# begäranden}} om löneväxling har avslagits.`,
  },
  salaryExchangeCapacityTitle: {
    id: 'companySalaryExchangeMessages.salaryExchangeCapacityTitle',
    defaultMessage: 'Kontrollera utrymme för pensionsavsättningar',
  },
  salaryExchangeCapacityText: {
    id: 'companySalaryExchangeMessages.salaryExchangeCapacityText',
    defaultMessage: `För er som arbetsgivare är avsättningar till tjänstepension avdragsgillt enligt huvudregeln upp till 35 % av den pensionsmedförande lönen eller max 10 prisbasbelopp. Pensionsmedförande lön är antingen föregående beskattningsår eller innevarande beskattningsår och definieras av all ersättning som är pensionsgrundande. Nedan kan du göra en beräkning givet tjänstepensionsavsättningar och den anställdes pensionsmedförande lön. {link}`,
  },
  readMoreTaxOffice: {
    id: 'companySalaryExchangeMessages.readMoreTaxOffice',
    defaultMessage: 'Läs mer om reglerna hos Skatteverket',
  },
  salaryExchangePremium: {
    id: 'companySalaryExchangeMessages.salaryExchangePremium',
    defaultMessage: 'Löneväxlingspremie',
  },
  highestSalaryExchangePremium: {
    id: 'companySalaryExchangeMessages.highestSalaryExchangePremium',
    defaultMessage: 'Högsta löneväxlingspremie',
  },
  capacityRemaining: {
    id: 'companySalaryExchangeMessages.capacityRemaining',
    defaultMessage: 'Kvar av utrymme',
  },
  insufficientCapacityMessage: {
    id: 'companySalaryExchangeMessages.insufficientCapacityMessage',
    defaultMessage: `De angivna premierna för pensionsavsättningar och försäkringar ger inget utrymme för löneväxling.`,
  },
  salaryExchangeWithinRange: {
    id: 'companySalaryExchangeMessages.salaryExchangeWithinRange',
    defaultMessage: `Löneväxlingspremien ({premium}) ligger inom det utrymme för pensionsavsättningar som finns.`,
  },
  tooHighSxRequested: {
    id: 'companySalaryExchangeMessages.tooHighSxRequested',
    defaultMessage: `Löneväxlingspremien ({premium}) ligger över det utrymme för pensionsavsättningar som finns.`,
  },
  yearlySalary: {
    id: 'companySalaryExchangeMessages.yearlySalary',
    defaultMessage: `Pensionsmedförande årslön`,
  },
  occupationalPensionPremiums: {
    id: 'companySalaryExchangeMessages.occupationalPensionPremiums',
    defaultMessage: `Tjänstepensionspremier`,
  },
  insurances: {
    id: 'companySalaryExchangeMessages.insurances',
    defaultMessage: `Sjukförsäkring och premiebefrielseförsäkring`,
  },
  oneTimeDeposits: {
    id: 'companySalaryExchangeMessages.oneTimeDeposits',
    defaultMessage: `Eventuella engångsavsättningar till pension*`,
  },
  oneTimeDepositsHelperText: {
    id: 'companySalaryExchangeMessages.oneTimeDepositsHelperText',
    defaultMessage: `*Exempelvis bonusavstående`,
  },
  historyHeader: {
    id: 'companySalaryExchangeMessages.historyHeader',
    defaultMessage: `Historik för begäran om löneväxling`,
  },
  onetimeHistoryHeader: {
    id: 'companySalaryExchangeMessages.onetimeHistoryHeader',
    defaultMessage: `Historik för begäran om bonusväxling`,
  },
  salaryExchangeReportsDesc: {
    id: 'companySalaryExchangeMessages.salaryExchangeReportsDesc',
    defaultMessage:
      'Aktiva och avslutade löneväxlingar sammanställs inför varje månad för avstämning mot lönesystemet. I sammanställningen framgår bruttolöneavdrag och premie per person.',
  },
  salaryExchangeReportCollectumDesc: {
    id: 'companySalaryExchangeMessages.salaryExchangeReportCollectumDesc',
    defaultMessage: `Hanteras löneväxling genom Collectum så ska rapportfilen skickas in via Collectums Internetkontor innan månadens fakturakörning som är runt den 15:e. {link}.`,
  },
  readMoreAboutCollectum: {
    id: 'companySalaryExchangeMessages.readMoreAboutCollectum',
    defaultMessage: 'Läs mer hos Collectum',
  },
});

export const salaryExchangeRequestStatusMessages =
  defineMessages<FlexSalaryExchangeRequestStatus>({
    APPROVED: {
      id: 'salaryExchangeRequestStatusMessages.APPROVED',
      defaultMessage: 'Accepterad',
    },
    CANCELLED: {
      id: 'salaryExchangeRequestStatusMessages.CANCELLED',
      defaultMessage: 'Avbruten',
    },
    REJECTED: {
      id: 'salaryExchangeRequestStatusMessages.REJECTED',
      defaultMessage: 'Nekad',
    },
    REQUESTED: {
      id: 'salaryExchangeRequestStatusMessages.REQUESTED',
      defaultMessage: 'Ansökt',
    },
  });

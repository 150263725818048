import { ApolloError, useMutation } from '@apollo/client';
import {
  FlexUpdateBenefitInput,
  updateBenefitMutation,
  updateBenefitMutationVariables,
} from 'app/apollo/graphql/types';
import { MatchParams } from 'app/pages/companies/company/discounts/edit';
import { useIntl } from 'components/formats';
import { useIntlContext } from 'contexts/IntlProviderWrapper';
import { UPDATE_BENEFIT_MUTATION } from 'features/companies/company/benefits/edit/graphql/mutations';
import { discountMessages } from 'features/companies/company/discounts/messages';
import { useNotification } from 'features/notifications';
import { RouteComponentProps, useRouteMatch } from 'react-router';

import { BenefitFormValues } from '../../form';
import { DISCOUNT_PRESENTATION_QUERY } from '../../graphql/queries';
import { getFilePath, getFilePaths } from '../../utils/get-file-paths';
import { useUpdateSupplier } from '../../utils/use-update-supplier';

interface Props {
  history: RouteComponentProps['history'];
  initialValues?: BenefitFormValues;
}

interface Submit {
  submit: (values: BenefitFormValues) => Promise<void>;
  error?: ApolloError;
}

export const useSubmit = (props: Props): Submit => {
  const match = useRouteMatch<MatchParams>();
  const { companyId } = match.params;
  const { locale } = useIntlContext();
  const { formatMessage } = useIntl();
  const { send } = useNotification();

  const {
    initialValues,
    history: { push },
  } = props;

  const { updateSupplier, updateSupplierError } = useUpdateSupplier({
    companyId,
  });

  const [updateBenefit, { error: updateError }] = useMutation<
    updateBenefitMutation,
    updateBenefitMutationVariables
  >(UPDATE_BENEFIT_MUTATION, {
    errorPolicy: 'all',
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: DISCOUNT_PRESENTATION_QUERY,
        variables: { id: initialValues?.id },
      },
    ],
  });

  const submit = async (values: BenefitFormValues) => {
    try {
      if (!values.id) {
        throw new Error();
      }

      const { supplierId } = await updateSupplier({
        initialSupplier: initialValues?.supplier,
        supplier: values.supplier,
      });

      const heroImagePath =
        values.content.heroImage &&
        (await getFilePath(companyId, values.content.heroImage));

      const attachmentPaths = await getFilePaths(
        companyId,
        values.content.attachments,
      );

      const categoryIds = values.categories?.map(({ id }) => id);

      const input: FlexUpdateBenefitInput = {
        id: values.id,
        content: {
          title: values.content.title,
          short: values.content.short,
          body: values.content.body,
          callToAction: values.content.callToAction,
          locale: locale ?? 'sv-SE',
          heroImagePath,
          heroImageAlignment: values.content.heroImageAlignment,
          cardImagePath: heroImagePath,
          attachmentPaths,
        },
        supplierId,
        categoryIds,
      };

      const res = await updateBenefit({
        variables: { input },
      });

      const benefitId = res.data?.updateBenefit?.benefit?.id;

      send({
        type: 'success',
        message: formatMessage(discountMessages.successfulBenefitSave, {
          name: values.content.title,
        }),
      });
      push(
        `/companies/${companyId}/benefit-packages/discounts/${benefitId}/presentation`,
      );
    } catch {
      // Do nothing
    }
  };

  return {
    submit,
    error: updateError ?? updateSupplierError,
  };
};

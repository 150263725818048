import { cardLinkCss, CardPrimaryAction } from '@frontend/ui';
import { Link } from 'components/Links/Link';
import React from 'react';
import { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  ${cardLinkCss}
`;

const StyledAnchor = styled.a`
  ${cardLinkCss}
`;

export type LinkOrAnchorProps =
  | LinkProps
  | React.AnchorHTMLAttributes<HTMLAnchorElement>;

type CardLinkProps = LinkOrAnchorProps & {
  children: React.ReactNode;
};

const isInternal = (props: LinkOrAnchorProps): props is LinkProps =>
  props.href === undefined;

export const CardLink: React.FC<CardLinkProps> = ({ children, ...props }) => {
  if (isInternal(props)) {
    return (
      <StyledLink {...props}>
        <CardPrimaryAction>{children}</CardPrimaryAction>
      </StyledLink>
    );
  }
  return (
    <StyledAnchor {...props} as="a">
      <CardPrimaryAction>{children}</CardPrimaryAction>
    </StyledAnchor>
  );
};

import { useMutation } from '@apollo/client';
import { Form, Formik } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import {
  assert,
  getSubmitErrors,
  toDecimalFraction,
  toEffectiveUntil,
} from '@frontend/utils';
import {
  addEmploymentActivityMutation,
  addEmploymentActivityMutationVariables,
  EmploymentActivityType,
} from 'app/apollo/graphql/types';
import { employmentMessages } from 'app/messages/employees';
import { formMessages, formSubmitMessages } from 'app/messages/form';
import { EmploymentRouteMatchParams } from 'app/pages/companies/company/employees/employee/employments/employment';
import { FormattedMessage, useIntl } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { useNotification } from 'features/notifications';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';

import { employmentActivityValidationSchema } from '../employment-activity-validation-schema';
import { EmploymentActivityFormFields } from '../EmploymentActivityFormFields';
import { EMPLOYMENT_ACTIVITIES_QUERY } from '../graphql/queries';
import { ADD_EMPLOYMENT_ACTIVITY_MUTATION } from './graphql/mutations';

interface FormValues {
  rate: number;
  effectiveDate?: string;
  effectiveThrough?: string;
  type?: EmploymentActivityType;
}

interface Props {
  employeeName: string;
  isOpen: boolean;
  onRequestClose: () => void;
}

export const AddEmploymentActivityModal: React.FC<Props> = ({
  employeeName,
  isOpen,
  onRequestClose,
}) => {
  const {
    params: { employmentId },
  } = useRouteMatch<EmploymentRouteMatchParams>();
  const { send } = useNotification();
  const intl = useIntl();

  const [formError, setFormError] = useState<string | undefined>();

  const [addEmploymentActivity] = useMutation<
    addEmploymentActivityMutation,
    addEmploymentActivityMutationVariables
  >(ADD_EMPLOYMENT_ACTIVITY_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: EMPLOYMENT_ACTIVITIES_QUERY,
        variables: { id: employmentId },
      },
    ],
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik<FormValues>
        initialValues={{
          rate: 100,
        }}
        isInitialValid={false}
        validationSchema={employmentActivityValidationSchema(intl)}
        onSubmit={async ({ effectiveThrough, effectiveDate, type, rate }) => {
          try {
            assert(effectiveDate != null);
            assert(type != null);

            await addEmploymentActivity({
              variables: {
                input: {
                  employmentId,
                  effectiveDate,
                  effectiveUntil: effectiveThrough
                    ? toEffectiveUntil(effectiveThrough)
                    : null,
                  type,
                  rate: rate ? toDecimalFraction(rate) : undefined,
                },
              },
            });
            send({
              message: intl.formatMessage(
                formSubmitMessages.addEmploymentActivitySuccess,
                { type },
              ),
              type: 'success',
            });
            onRequestClose();
          } catch (error) {
            setFormError(getSubmitErrors({ intl, error }));
          }
        }}
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <ModalHeader>
              <FormattedMessage
                {...employmentMessages.addEmploymentActivityForName}
                values={{ employeeName }}
              />
            </ModalHeader>
            <ModalBody>
              <EmploymentActivityFormFields />
              {formError && (
                <NotificationCard inModal type="error">
                  {formError}
                </NotificationCard>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonLayout align="right">
                <Button text onClick={onRequestClose}>
                  <FormattedMessage {...formMessages.cancel} />
                </Button>
                <Button
                  type="submit"
                  filled
                  disabled={!isValid}
                  loading={isSubmitting}
                >
                  <FormattedMessage {...formMessages.approve} />
                </Button>
              </ButtonLayout>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

import gql from 'graphql-tag';

export const SME_COMPANY_EDIT_SALARY_EXCHANGE_QUERY = gql`
  query smeCompanyEditSalaryExchangeQuery($benefitId: ID!, $proposalId: ID!) {
    proposal(id: $proposalId) {
      id
      benefit(id: $benefitId) {
        id
        benefitPackages {
          id
        }
        configuration {
          ... on ProposalSalaryExchangeBenefitConfig {
            automaticApproval
            incomeMinIBB
            pensionToSalaryMax
            ongoingMax
            pensionTaxBenefitShare
            premiumWaiver
            retirementAge
            salaryMultiplier
          }
        }
      }
      benefitPackages {
        id
        name
      }
    }
  }
`;

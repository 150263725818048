import gql from 'graphql-tag';

export const BENEFITS_CATEGORY_QUERY = gql`
  query benefitCategoriesQuery(
    $companyId: ID
    $userAccountId: ID
    $locale: String
  ) {
    benefitCategories(
      companyId: $companyId
      userAccountId: $userAccountId
      locale: $locale
    ) {
      id
      companyId
      name
      locale
    }
  }
`;

import { FormikContextType, useFormikContext } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter, TextField } from '@frontend/ui';
import { onPressEnter } from '@frontend/utils';
import { commonMessages } from 'app/messages/common';
import { formMessages } from 'app/messages/form';
import { FormattedMessage, useIntl } from 'components/formats';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import React, { useEffect, useReducer } from 'react';
import { MessageDescriptor } from 'react-intl';

import { BenefitFormValues } from '../../form';

export interface ModalField {
  label: MessageDescriptor;
  name: string;
  validate?: (value: string | undefined) => string | undefined;
  validationMsg?: string;
  value?: string;
}

export interface ModalFieldProps {
  fields: ModalField[];

  onConfirm: (
    fields: ModalField[],
    context: FormikContextType<BenefitFormValues>,
  ) => void;

  title: React.ReactNode;
}

interface Props extends ModalFieldProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const reducer = (
  state: ModalField[],
  action: Partial<ModalField> | ModalField[],
): ModalField[] => {
  if (Array.isArray(action)) {
    return action;
  }

  return state.map(field =>
    action.name === field.name
      ? { ...field, value: action.value, validationMsg: action.validationMsg }
      : field,
  );
};

export const EditModal: React.FC<Props> = ({
  title,
  isOpen,
  fields,
  onConfirm,
  onRequestClose,
}) => {
  const context = useFormikContext<BenefitFormValues>();
  const [state, dispatch] = useReducer(reducer, fields);
  const intl = useIntl();

  useEffect(() => {
    dispatch(fields);
  }, [fields]);

  // if a validation message exists on any field,
  // then the form should be considered invalid
  const invalid = state.some(field => !!field.validationMsg);

  const onChange = (args: Partial<ModalField>) => {
    const { value, name, validate } = args;
    dispatch({
      value,
      name,
      validationMsg: validate ? validate(value) : undefined,
    });
  };

  const confirm = () => {
    if (invalid) {
      return;
    }
    onConfirm(state, context);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      size="small"
      role="form"
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        {state.map(({ value, label, name, validationMsg, validate }) => (
          <TextField
            key={name}
            value={value}
            label={intl.formatMessage(label)}
            onChange={event =>
              onChange({ value: event.target.value, name, validate })
            }
            onKeyUp={onPressEnter(confirm)}
            errorText={validationMsg}
            validationMsg={!!validationMsg}
            dense
          />
        ))}
      </ModalBody>
      <ModalFooter>
        <ButtonLayout align="right">
          <Button onClick={onRequestClose} text>
            <FormattedMessage {...formMessages.cancel} />
          </Button>
          <Button type="button" onClick={confirm} text disabled={invalid}>
            <FormattedMessage {...commonMessages.confirm} />
          </Button>
        </ButtonLayout>
      </ModalFooter>
    </Modal>
  );
};

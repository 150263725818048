import { ApolloError } from '@apollo/client';
import {
  Button,
  ContentContainer,
  Fab,
  Hero as _Hero,
  Icon,
  media,
  Section,
} from '@frontend/ui';
import { edit as editIcon } from '@frontend/ui/icons';
import {
  discountPresentationQuery_benefit_FlexBenefit as Benefit,
  discountPresentationQuery_benefit_FlexBenefit_content_FlexBenefitContent_callToAction_FlexCallToAction as CallToAction,
} from 'app/apollo/graphql/types';
import { DefaultA } from 'components/DefaultA';
import {
  Description,
  HeaderContainer,
  Logo,
  MediaContainer,
  Overline,
  TextContainer,
  Title,
} from 'components/FeaturePageLayout';
import { useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { discountMessages } from 'features/companies/company/discounts/messages';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
  BenefitFormValues,
  CallToAction as CallToActionFieldValue,
  Category,
} from '../../form';
import { Categories } from '../Categories';
import { EditModal, ModalFieldProps } from '../EditModal';
import { getCtaModalFields, getTitleModalFields } from '../EditModal/fields';
import { HeroModal } from '../HeroModal';
import { ImageAlignment } from '../ImageAlignment';
import { PresentationBody } from '../PresentationBody';
import { SupplierModal } from '../SupplierModal';

const Hero = styled(_Hero)<{ verticalAlign?: string }>`
  ${p => p.verticalAlign && `background-position-y: ${p.verticalAlign}`};
  border-radius: 1.75rem;
`;

const HeroEditWrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: end;
  height: 100%;
  width: 100%;
  padding: 1.625rem;
  justify-content: space-between;

  ${media.lessThan('tablet')`
    padding: .25rem;
  `};
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
`;

const CallToActionButton = styled(Button)`
  margin-right: 1rem;
`;

export const FullBleed = styled.div``;

const ContentBodyContainer = styled.div`
  & > :not(${FullBleed}) {
    width: calc(8 / 12 * 100%);
    ${media.lessThan('desktop')`
      width: auto;
  `}
  }
`;

export type BenefitPresentationContext = 'edit' | 'admin-preview';

type BenefitType = Benefit | BenefitFormValues;

interface CommonProps {
  benefit: BenefitType;
  error?: ApolloError;
}

interface PreviewProps extends CommonProps {
  context?: Exclude<BenefitPresentationContext, 'edit'>;
  setCategories?: never;
}

interface EditProps extends CommonProps {
  context: 'edit';
  setCategories: (categories: Category[]) => void;
}

type Props = PreviewProps | EditProps;

const isEditProps = (props: Props): props is EditProps => !!props.setCategories;

export const BenefitPresentation: React.FC<Props> = props => {
  const { benefit, error, context } = props;
  const intl = useIntl();
  const [modalProps, setModalFields] = useState<ModalFieldProps | undefined>(
    undefined,
  );

  const openModal = (modalFields: ModalFieldProps) => {
    setModalFields(modalFields);
  };

  const heroImageUrl = benefit.content.heroImage?.url;
  const displayHeroImage = !!heroImageUrl || context === 'edit';

  const callToAction: ReadonlyArray<CallToActionFieldValue | CallToAction> =
    benefit.content.callToAction ?? [];

  return (
    <Section>
      <HeaderContainer>
        <MediaContainer withHeroImage={displayHeroImage} withLogo>
          {displayHeroImage && (
            <Hero
              image={heroImageUrl}
              verticalAlign={benefit.content.heroImageAlignment}
            >
              {context === 'edit' && (
                <HeroEditWrapper>
                  <HeroModal />
                  {heroImageUrl && <ImageAlignment />}
                </HeroEditWrapper>
              )}
            </Hero>
          )}
          <ContentContainer>
            <Logo
              withHeroImage={displayHeroImage}
              image={benefit.supplier?.logo?.url}
            >
              {context === 'edit' && <SupplierModal />}
            </Logo>
          </ContentContainer>
        </MediaContainer>
        <ContentContainer>
          <ContentBodyContainer>
            <TextContainer>
              <Overline>{benefit.supplier?.name}</Overline>
              <Title
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {benefit.content.title}
                {context === 'edit' && (
                  <Fab
                    mini
                    label={intl.formatMessage(discountMessages.editTitle)}
                    onClick={() =>
                      openModal(getTitleModalFields(intl, benefit))
                    }
                    icon={<Icon icon={editIcon} size="medium" decorative />}
                    style={{ marginLeft: '1rem' }}
                  />
                )}
              </Title>
              <Description>{benefit.content.short}</Description>
              {(!!callToAction.length || context === 'edit') && (
                <ActionWrapper>
                  {callToAction.map(({ link, title, type }, id) => (
                    <DefaultA key={id} href={link} target="_blank">
                      <CallToActionButton filled={type === 'primary'}>
                        {title}
                      </CallToActionButton>
                    </DefaultA>
                  ))}
                  {context === 'edit' && (
                    <>
                      {callToAction.length === 0 && (
                        <CallToActionButton disabled>
                          {intl.formatMessage(discountMessages.exampleLink)}
                        </CallToActionButton>
                      )}
                      <Fab
                        mini
                        label={intl.formatMessage(discountMessages.editLinks)}
                        onClick={() => openModal(getCtaModalFields(benefit))}
                        icon={<Icon icon={editIcon} size="medium" decorative />}
                      />
                    </>
                  )}
                </ActionWrapper>
              )}
            </TextContainer>
          </ContentBodyContainer>
        </ContentContainer>
        {isEditProps(props) ? (
          <Categories
            setCategories={props.setCategories}
            selectedCategories={benefit.categories ?? []}
            context="edit"
          />
        ) : (
          <Categories
            selectedCategories={benefit.categories ?? []}
            context={props.context ?? 'admin-preview'}
          />
        )}
      </HeaderContainer>
      <ContentContainer>
        {error && <GraphQlError error={error} />}
        <Section>
          <div className="with-presentation-text-styles fullwidth-table">
            <ContentBodyContainer>
              <PresentationBody
                body={benefit.content.body}
                config={benefit.configuration}
                attachments={benefit.content.attachments}
                context={context ?? 'admin-preview'}
              />
              {!!modalProps && (
                <EditModal
                  isOpen={!!modalProps}
                  title={modalProps.title}
                  onRequestClose={() => setModalFields(undefined)}
                  onConfirm={modalProps.onConfirm}
                  fields={modalProps.fields}
                />
              )}
            </ContentBodyContainer>
          </div>
        </Section>
      </ContentContainer>
    </Section>
  );
};

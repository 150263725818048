import { Form, Formik, SelectField } from '@frontend/formik';
import { Button, ButtonLayout, ModalFooter } from '@frontend/ui';
import { add } from '@frontend/ui/icons';
import { stripSearchParams } from '@frontend/utils';
import {
  ProposalBenefitType,
  proposalSavingsAdviceQuery,
  proposalSavingsAdviceQueryVariables,
} from 'app/apollo/graphql/types';
import { commonMessages, validationMessages } from 'app/messages/common';
import { MatchParams } from 'app/pages/sme/company/proposal';
import { benefitUrls } from 'app/pages/sme/company/proposal/benefits/create';
import { useQuery } from 'app/utils/use-query';
import { AssistChip } from 'components/AssistChip';
import { FormattedMessage, IntlShape, useIntl } from 'components/formats';
import { GraphQlError } from 'components/GraphQlError';
import { Modal, ModalBody, ModalHeader } from 'components/Modal';
import { NotificationCard } from 'components/NotificationCard';
import { TopLoading } from 'components/TopLoading';
import {
  smeBenefitsMessages,
  smeBenefitTypesMessages,
} from 'features/sme/messages/sme';
import qs from 'query-string';
import React, { useId } from 'react';
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router';
import * as Yup from 'yup';

import { useProposalReadonly } from '../../../utils/use-proposal-readonly';
import { PROPOSAL_SAVINGS_ADVICE_QUERY } from '../../graphql/queries';
import { hasPensionBenefitAccess as _hasPensionBenefitAccess } from '../../utils/has-pension-benefit-access';
import { DEFAULT_BENEFITS_SORT_ORDER } from '../../utils/sort-benefits-by';

const BENEFIT_TYPE_FIELD_NAME = 'benefitType';

interface ValidationSchemaArgs {
  /**
   * Designates whether the proposal should allow for pension benefits to be created.
   *
   * This is a temporary measure. Ideally this should be managed by backend
   */
  hasPensionBenefitAccess: boolean;
  intl: IntlShape;
}

const validationSchema = ({
  intl,
  hasPensionBenefitAccess,
}: ValidationSchemaArgs) =>
  Yup.object().shape({
    benefitType: Yup.string()
      .required(intl.formatMessage(validationMessages.mandatoryField))
      .test(
        'pension',
        intl.formatMessage(validationMessages.pensionBenefitNotAvailable),
        value =>
          hasPensionBenefitAccess ||
          value !== ProposalBenefitType.OCCUPATIONAL_PENSION,
      ),
  });
export interface FormValues {
  benefitType: ProposalBenefitType | '';
}

export const CreateBenefitModal = () => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const history = useHistory();
  const { push } = history;
  const location = useLocation();
  const { params } = useRouteMatch<MatchParams>();
  const id = useId();

  const { loading, data, error } = useQuery<
    proposalSavingsAdviceQuery,
    proposalSavingsAdviceQueryVariables
  >(PROPOSAL_SAVINGS_ADVICE_QUERY, {
    errorPolicy: 'all',
    variables: { id: params.proposalId },
  });

  const { 'create-benefit': createBenefit } = qs.parse(location.search);

  const onRequestClose = () => {
    stripSearchParams(history, location, ['create-benefit']);
  };

  const initialValues: FormValues = {
    benefitType: '',
  };

  if (loading) {
    return <TopLoading />;
  }

  const hasPensionBenefitAccess = _hasPensionBenefitAccess(data?.proposal);

  return (
    <Modal isOpen={createBenefit} onRequestClose={onRequestClose}>
      <ModalHeader>
        <FormattedMessage {...smeBenefitsMessages.createBenefit} />
      </ModalHeader>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={value =>
          push(
            `/sme/${params.companyId}/proposals/${params.proposalId}/benefits/create/${benefitUrls[value.benefitType]}`,
          )
        }
        validationSchema={validationSchema({ intl, hasPensionBenefitAccess })}
      >
        {({ isSubmitting, isValid, errors }) => {
          const benefitTypeError = errors[BENEFIT_TYPE_FIELD_NAME];

          return (
            <Form>
              <ModalBody>
                {error && <GraphQlError inModal error={error} />}
                <p>
                  <FormattedMessage
                    {...smeBenefitsMessages.createBenefitDescription}
                  />
                </p>
                <SelectField
                  dense
                  name={BENEFIT_TYPE_FIELD_NAME}
                  fixed
                  label={
                    <FormattedMessage
                      {...smeBenefitsMessages.selectBenefitType}
                    />
                  }
                  options={Object.values(ProposalBenefitType)
                    .sort(
                      (a, b) =>
                        DEFAULT_BENEFITS_SORT_ORDER[a] -
                        DEFAULT_BENEFITS_SORT_ORDER[b],
                    )
                    .map(benefitType => ({
                      label: formatMessage({
                        select: benefitType,
                        messages: smeBenefitTypesMessages,
                      }),
                      value: benefitType,
                    }))}
                  required
                  // This is a temporary solution to make the error message appear
                  // as our select field does not support error messages yet
                  aria-errormessage={benefitTypeError ? id : undefined}
                  aria-invalid={!!benefitTypeError}
                />
                {!!benefitTypeError && (
                  <NotificationCard type="error" inModal id={id}>
                    {benefitTypeError}
                  </NotificationCard>
                )}
              </ModalBody>
              <ModalFooter>
                <ButtonLayout align="right">
                  <Button text onClick={onRequestClose}>
                    <FormattedMessage {...commonMessages.cancel} />
                  </Button>
                  <Button
                    disabled={!isValid}
                    loading={isSubmitting}
                    text
                    type="submit"
                  >
                    <FormattedMessage {...commonMessages.continue} />
                  </Button>
                </ButtonLayout>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

const getCreateBenefitLink = (
  location: RouteComponentProps['location'],
): RouteComponentProps['location'] => ({
  ...location,
  search: qs.stringify({ 'create-benefit': true }),
});

export const CreateBenefitAssistChip: React.FC = () => {
  const location = useLocation();
  const isReadonly = useProposalReadonly();

  return (
    <AssistChip
      text={<FormattedMessage {...smeBenefitsMessages.createBenefit} />}
      leadingIcon={add}
      to={getCreateBenefitLink(location)}
      disabled={isReadonly}
      collapseMarginBottom
    />
  );
};

import { ApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from '@frontend/formik';
import {
  FlexUpdateBenefitInput,
  updateBenefitMutation,
  updateBenefitMutationVariables,
} from 'app/apollo/graphql/types';
import { formSubmitMessages } from 'app/messages/form';
import { useIntl } from 'components/formats';
import format from 'date-fns/format';
import { UPDATE_BENEFIT_MUTATION } from 'features/companies/company/benefits/edit/graphql/mutations';
import { useNotification } from 'features/notifications';

import { FormValues } from '..';

interface Props {
  discountId: string;
}

interface Submit {
  submit: (values: FormValues, helpers: FormikHelpers<FormValues>) => void;
  submissionError?: ApolloError;
}

export const useSubmit = ({ discountId }: Props): Submit => {
  const { send } = useNotification();
  const { formatMessage } = useIntl();

  const [updateBenefit, { error: submissionError }] = useMutation<
    updateBenefitMutation,
    updateBenefitMutationVariables
  >(UPDATE_BENEFIT_MUTATION, {
    errorPolicy: 'all',
    awaitRefetchQueries: true,
  });

  const submit = async (values: FormValues) => {
    const { benefitPackages, orgUnits, visible, from, to } = values;

    const benefitPackageIds = Object.keys(benefitPackages);
    const orgUnitIds = Object.keys(orgUnits);
    const configEffectiveDate = format(new Date(), 'yyyy-MM-dd');

    const input: FlexUpdateBenefitInput = {
      id: discountId,
      entitlementRules: {
        benefitPackageIds,
        accountingCostIds: [] /* XXX: TODO: What is this? */,
        ouIds: orgUnitIds,
        from: from || undefined,
        to: to || undefined,
      },
      configEffectiveDate,
      hidden: !visible,
    };
    try {
      await updateBenefit({ variables: { input } });

      send({
        type: 'success',
        message: formatMessage(formSubmitMessages.createOfferSubmitSucess),
      });
    } catch {
      // Do nothing
    }
  };
  return { submit, submissionError };
};
